.outer-location {
  font-size: 26px;
  background: #fff;
  border-radius: 28px;
  position: relative;
  top: 40px;
  right: 41px;
}
.wh-public-icons-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
  color: white;
}
.wh-private-icons-container {
  display: flex;
  justify-content: flex-end;
}
.wh-dd-btn-cntr {
  margin-top: 16px;
  cursor: pointer;
}
.wh-dm-user-info {
  border-bottom: 1px solid #364d49;
  padding: 10px;
  margin-bottom: 10px;
}
.badge-circle-header {
  position: absolute;
  top: -33px;
  width: 1.6rem;
  border-radius: 50%;
  color: #fff;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.6rem;
  font-family: "Open Sans", sans-serif;
  text-align: center;
}
.location {
  width: 22%;
  margin-bottom: 8px;
}
::placeholder {
  font-size: 1em;
  color: mintcream;

  padding: 7px;
}

.input-style {
  width: 317px;
  height: 43px;
  background: #ffffff;
  border: 1px solid #99a2aa;
  border-radius: 5px;
  display: block;
  /* font-size: 17px; */
  font-style: "normal";
  font-family: "Times New Roman";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8b8fa1;
  padding: 13px 44.14px 13px 20px;
}

.search-icon {
  position: relative;
  top: 38px;
  right: -12px;
  font-size: 21px;
  color: #999999;
}
.icons-container {
  display: flex;
  padding: 3rem 0;
  color: white;
  justify-content: end;
  position: relative;
  text-decoration: underline;
  font-family: "playfair display";
}
.header-login-btn:hover {
  color: #fff !important;
}
.search-outer {
  left: 20%;
  position: relative;
  /* width: 132px; */
  top: 20px;
}
.user-name {
  font-style: normal;
  font-weight: 500;
  margin-top: 10px;
  font-size: 20px;
  font-family: "Times New Roman";
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: white;
  position: relative;
  top: -55px;
}
.shoppping-name {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: white;
  position: relative;
  top: -20px;
  left: -25px;
  font-family: "Times New Roman";
  width: 0%;
  text-transform: capitalize;
  /* font-size: 22px; */
}
.image-location-outer {
  position: relative;
  top: 41px;
  right: 46px;
}

.header-logo {
  cursor: pointer;
  /* padding-top: 2rem; */
  margin-top: 20px;
  /* width: 100px; */
  height: 48px;
}
.header-name {
  cursor: pointer;
  padding-top: 5rem;
  margin-top: -90px;
  min-width: fit-content;
  height: 70px;
  margin-right: 5px;
  margin-left: 70px;
  /* width: 100px; */
  /* height: 20px; */
}
.search {
  font-size: 27px;
  display: none;
}

.header-middle {
  padding: 0.7rem !important;
}
.span-background {
  border-radius: 21px;
  /* background-color: #fff; */
  width: 38px;
  text-align: center;
  font-size: 22px;
  height: 39px;
  cursor: pointer;
  position: relative;
  top: -12px;
  right: -22px;
}
.custom-search {
  height: 25px;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #364d49;
  height: 44px;
}
.btn-name-search {
  position: relative;
  top: -14px;
  left: -33px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.5;
  color: #ffffff;
  font-weight: 500;
  line-height: 32px;
  font-family: "Times New Roman";
  text-transform: capitalize;
}
.dropdown-icon {
  font-size: 20px;
  margin-top: 10px;
}

/* .Menu-icon {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px 11px;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  background: #FFF;
  
} */

.mobile-menu-icon {
  border-radius: 21px;
  background-color: #fff;
  color: #2d5c48;
  width: 37px;
  left: -3%;
  text-align: center;
  font-size: 23px;
  padding-bottom: 5px;
  height: 34px;
}

.form-extra-margin {
  margin-bottom: 0px !important;
  margin-top: -30px;
}
.shopping-cart {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 13px 12px 14px 11px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #FFF;
  background: #FFF;
}
.badge-circle {
  position: absolute;
  top: 28px;
  right: 30px;
  width: 1.6rem;
  border-radius: 50%;
  color: #fff;
  background: #d60000;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.6rem;

  text-align: center;
}
.outer-logo {
  width: 100%;

  margin-top: -13px;
}
.web-header {
  display: block;
  background-color: #2f4e49;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 96px;
  z-index: 100;
}


.mobile-header {
  max-width: auto;
  height: auto;
  background-color: #2f4e49;
}
.main-mobile-header {
  display: flex;
  justify-content: space-around;
  padding: 2rem;
}
.mobile-header-search {
  padding-bottom: 1px;
  padding: 10px;
}
.shopping-cart svg {
  overflow: hidden;
  vertical-align: -6px !important;
}
.input-mobile-header {
  max-width: 700px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  display: block;
  padding-left: 10%;
  font-size: 16px;
}
.body {
  font-family: "Playfair Display";
}
.install-button-mobile-header {
  border: 1px solid #2d5c48;
  color: #364d49;
  border-radius: 4px;
  font-size: 18px;
  padding: 5px 15px;
  background: transparent;
}
.install-para-mbl-hdr {
  color: #2d5c48;
  font-size: 18px;
}
@media (min-width: 1024px) {
  .mobile-header {
    display: none;
  }
  .web-header {
    display: block;
  }
  .mobile-app-install-banner {
    display: none;
  }
  .ddm-main.show {
    left: -150px !important;
  }
}

@media (max-width: 600px) {
  .mobile-header {
    display: block;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 10;
  }
  .web-header {
    display: none;
  }
  .input-style {
    width: 265px;
  }
  .ddm-main.show {
    top: 12px !important;
    left: -55px !important;
  }
  .ddm-lang.show {
    top: 12px !important;
    left: -57px !important;
  }
}
@media only screen and (min-width: 768px) {
  .mobile-header-search {
    margin-left: 8rem;
    margin-right: 8rem;
  }
}
@media only screen and (min-width: 820px) {
  .mobile-header-search {
    margin-left: 8rem;
    margin-right: 8rem;
  }
}
@media only screen and (min-width: 540px) {
  .mobile-header-search {
    margin-left: 5rem;
    margin-right: 5rem;
  }
}
.signupname {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: white;
  position: relative;
  top: -1px;
  left: 17px;
  font-family: "Times New Roman";
  width: 0%;
  text-transform: capitalize;
}
.dropdown-content {
  position: relative;
  /* z-index: 9999; */
}
.dropdown-content-menu {
  position: relative;
}
.btn-secondary {
  color: #fff;
  background-color: #364d49;
  font-family: "Playfair Display";
  text-transform: capitalize;
  letter-spacing: 0.5px;
  font-size: 18px;
  font-weight: 400;
  border-color: #364d49;
}
.btn-secondary:hover {
  background-color: #364d49;
  border-color: #364d49;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #364d49;
  border-color: #364d49;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.ddm-main.show {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: -150px;
  width: 250px;
  padding: 10px;
  transform: translate3d(42px, 89px, 0px);
}
.ddm-lang {
  width: 150px;
  left: -70px;
  padding: 10px;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.75rem 0rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 18px;
  text-transform: capitalize;
  font-family: "Playfair Display";
}
.signup{
  color: #FFF;
  font-family: Glacial Indifference;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 117px;
  height: 44px;
  padding: 10px 33px;
}

.login{
  display: flex;
  padding: 10px 33px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #FFF;
  width: 117px;
  height: 44px;
  color: var(--Primary-color, #364D49);
  font-family: Glacial Indifference;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.header-btns{
  display: flex;
  align-items: center;
  gap: 0px;
}
.header-qty{
  color: #FFF;
  font-family: Glacial Indifference;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom_cart_icon{
  font-size: 20px;
  color: white;
  font-weight: 800;
}

.align-logo{
  text-align: -webkit-center;
  margin-top: 6px;
}

.cart_quantity{
  position: absolute;
  color: white;
  background-color: #222121;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: -54%;
    right: 27%;
    font-family: Glacial Indifference;
}


.cart_wrapper{
  transition: transform 0.3s ease;
}

.cart_wrapper.animate {
  transform: scale(1.2);
}

.margin-header-btns{
  margin-left: 35px;
}



.sidebar_header_container{
  position: absolute;
  left: 0%;
  z-index: 999999999999;
}

.menu-btn-container{
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-btn-container i {
  color: #364d49;
  font-size: 40px;
}

@media only screen and (min-width:500px)
{
  .cart_quantity{
    right: 44% !important;
  }
}

@media only screen and (min-width:500px) and (max-width:576px){
  .header_logo_custom{
    margin-left: 50px;
  }
}

@media only screen and (max-width:300px)
{
  .custom_cart_icon{
font-size: 14px !important;
  }

  .cart_quantity{
    width: 14px;
    height: 14px;
    font-size: 10px;
  }
}