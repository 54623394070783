.mode-button {
    cursor: pointer;
    font-size: 14px;
    background: none;
    border-radius: 4px;
    padding: 5px 10px;
    border: 1px solid #364d49;
    color: var(--Neutral-color, #212524);
    font-family: Glacial Indifference;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.mode-button:disabled {
    font-weight: bold;
    background: #364d49;
    color: #fff;
    font-family: Glacial Indifference;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.md-language-bar {
    display: flex;
    text-align: left;
    align-items: center;
}
.lb-p {
    font-size: 14px;
    color: #364d49 !important;
}

.md-container {
    max-width: 149px;
    text-align: right;
    position: relative;
    top: -5px;
}

@media only screen and (max-width:600px){
    .mode-button:disabled{
        font-size: 14px;
      }

      .mode-button{
        font-size: 14px;
      }
}

@media only screen and (min-width:502px) and (max-width:600px)
{
    .md-container {
        right: 5%;
        } 
}

@media only screen and (min-width:385px) and (max-width:500px)
{
    .md-container {
right: 80%;
}
}


@media only screen and (min-width:385px) and (max-width:405px)
{
.md-container {
right: 97%;
}
} 


@media only screen and (min-width:320px) and (max-width:380px)
{
    .md-container {
right: 110%;
}
}

@media only screen and (max-width:300px)
{
    .md-container{
        right: 250% !important;
    }

    .mode-button{
        font-size: 10px !important;
    }
}