.input {
  border-radius: 23px !important;
  border-width: 0 0 2px;
  border-color: blue;
}

.forget-pass {
  transition: all 0.3s;
  text-decoration: underline;
  color: var(--Primary-color, #364D49);
  font-family: Playfair Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-decoration-line: underline;
}

.forget-pass:hover
{
  color: #364D49;
}
.login-screen-parent-div {
  width: 40%;
  background: #fff;
  margin: auto;
}

.kep-login-facebook {
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  color: black;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  background-color: white;
  border: none;
  border-radius: 24px;
}

.kep-login-facebook.metro {
  width: 100% !important;
  height: 58px;
  border-radius: 5px !important;
  display: flex;

  text-transform: capitalize;
  background-color: #f5f5f5;
  color: black;
  border: none;
  font-weight: 700;
  /* padding: 10px 10px 10px 0px; */
}
.kep-login-facebook {
    font-family: unset !important;
    font-weight: 500 !important;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: calc(.27548vw + 12.71074px);
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .3s,border-color .3s;
    background-color: #4c69ba;
    border: calc(0.06887vw + 0.67769px) solid #4c69ba;
    padding: calc(0.34435vw + 13.38843px) calc(0.34435vw + 18.38843px);
}

#roundb {
  background-color: #345C59;
  width: 100%;
  border: none;
  border-radius: 700px;
  height: 5rem;
}

.p {
  display: flex !important;
  justify-content: center !important;
  color: #666666;
}

.hideicon {
  padding-left: 83% !important;
  margin-top: -0.2%;
  position: absolute !important;
  font-size: 24px !important;
}

h4 {
  display: flex;
  flex-direction: row;
  font-family: "Times New Roman";
}

.pp:before {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #e6e6e6;
  /* margin: auto; */
  margin-left: 66px;
  margin-bottom: 9px;
  margin-right: 11px;
}

.pp:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #e6e6e6;
  /* margin: auto; */
  margin-right: 63px;
  margin-bottom: 9px;
  margin-left: 11px;
}

.googlefbbtn {
  margin-left: 11px;
  width: 134px;
  border-radius: 24px;
  border: none;
  height: 57px !important;
}

/* .text-danger{
  color: red;
} */
.privacy {
  justify-content: center;
  color: red;
  display: flex;
  font-weight: 500;
  margin-top: -23px;
}

.sl-hds {
  font-size: 24px;
  text-transform: uppercase;
  color: #000;
  margin: 0 0 15px;
  font-family: "Trade Gothic LT Std Bold" !important;
  letter-spacing: normal;
}

.cutom-top-margin{
  padding: 7% 7% !important;
  
}

@media only screen and (min-width: 768px) {
  h2 {
    font-size: 24px;
    letter-spacing: -1px;
  }
}

.myButton {
  width: 100%;
}

.myButton:hover {
  background: linear-gradient(to bottom, #e4685d 5%, #fc8d83 100%);
  background-color: #e4685d;
}

.myButton:active {
  position: relative;
  top: 1px;
}

.lgor {
  font-family: "open_sansregular", Arial, Helvetica, sans-serif !important;
  text-align: center;
  font-size: 15px;
  margin: 0 0 20px;
  border-bottom: 1px dotted #c7c7c7;
  width: 80%;
  display: table;
  font-style: normal;
}

.kep-login-facebook.metro {
  /* width: 100% !important; */
  height: 58px;
  border-radius: 5px !important;
  font-size: 14px;
  text-transform: capitalize;
  background-color: #efefef !important;
  color: black;
  border: none;
  /* padding: 10px 10px 10px 0px; */
}



.login-btn {
  display: flex;
  height: 54px;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Primary-color, #364D49);
  background: var(--Primary-color, #364D49);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.lgn-signup-btn-1{
  color: var(--Primary-color, #364D49);
    font-family: Glacial Indifference;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    padding: 6px 12px;
    align-items: center;
    gap: 10px;
    line-height: 140%;
    text-transform: capitalize;
    border-radius: 8px;
    background: var(--primary-primary-50, #EBEDED);
}

#roundb {
  border-radius: 10px;
}

.hellogoogle {
  width: 100%;
  height: 56px;
  color: black !important;
  border-radius: 5px !important;
  background-color: #efefef !important;
  align-items: center !important;
  border: none;
  padding: 9px !important;
  font-size: 14px !important;
  /* margin-left: 28px; */
  font-weight: 700 !important;
  font-family: unset !important;
}

.hellogoogle div {
  background-color:transparent !important;
}

button.hellogoogle{
  /* box-shadow: unset !important; */
  font-weight: 700 !important;
}

element.style {
  text-align: center;
}

.cart-button {
  background-color: #c41000 !important;
  color: #fff !important;
  border-color: #fff !important;
}

.react-tel-input .form-control {
  border: 1px solid gray !important;
  border-radius: 5px !important;
  width: 100% !important;
}

.react-tel-input .flag-dropdown {
  border: 1px solid gray !important;
  border-radius: 10px 0 0 10px !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus .react-tel-input {
  background-color: unset !important;
}

.input-2 {
  margin-bottom: 1rem;
}

.PalaceCafe-text {
  color: orange;
  margin-left: 10px;
}

.FS-15 {
  font-size: 15px;
}

.text-red {
  color: red;
}

.text-green {
  color: green;
}

.select-style {
  height: 4rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
  font-size: 15px;
}

.tr-style {
  border-color: #ddd;
  background-color: #f5f5f5;
}

.outer-facebook-div {
  /* display: flex; */
  justify-content: center;
}

@media only screen and (min-width: 320px) and (max-width: 524px) {

  .custom-top-margin{
    margin: 5% 31px !important;
  }

  .hideicon {
    padding-left: 70% !important;
    position: absolute !important;
    margin-top: -0.2%;
    font-size: 20px !important;
  }
  .login-screen-parent-div {
    margin-top: 80px;
    width: 100%;
    background: #fff;
  }

  .p {
    position: relative !important;
    margin-top: -25px !important;
    display: flex !important;
    justify-content: center !important;
    color: #666666;
  }

  .googlefbbtn {
    margin-left: 5px;
    width: 114px;
    border-radius: 24px;
    border: none;
    height: 57px !important;
  }
}

@media only screen and (min-width: 520px) and (max-width: 766px) {
  .hideicon {
    padding-left: 81% !important;
    position: absolute !important;
    margin-top: -1.2%;
    font-size: 24px !important;
  }

  .p {
    position: relative !important;
    margin-top: -25px !important;
    display: flex !important;
    justify-content: center !important;
    color: #666666;
  }
}

@media only screen and (min-width: 766px) and (max-width: 992px) {
  .hideicon {
    padding-left: 83% !important;
    position: absolute !important;
    font-size: 24px !important;
  }

  .p {
    position: relative !important;
    margin-top: -25px !important;
    display: flex !important;
    justify-content: center !important;
    color: #666666;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1194px) {
  .hideicon {
    padding-left: 78% !important;

    position: absolute !important;
    font-size: 24px !important;
  }
}

/* Default width */
/* .img-fluid {
  width: 490px;
} */

@media screen and (max-width: 991px){
  .img-fluid{
    width: 680px !important;
  }
}

@media screen and (max-width: 930px){
  .img-fluid{
    width: 650px !important;
  }
} 

@media screen and (max-width: 850px){
  .img-fluid{
    width: 600px !important;
  }
} 

@media screen and (max-width: 800px){
  .img-fluid{
    width: 550px !important;
  }
} 

@media screen and (max-width: 750px){
  .img-fluid{
    width: 500px !important;
  }
} 
@media screen and (max-width: 650px){
  .img-fluid{
    width: 475px !important;
  }

  .login-mobile{
    margin-top: 0px !important;
  }

  .margin-login-side{
    margin-left: -11px !important;
  }

  .cutom-top-margin{
padding: 20% 7% !important;
  }
} 

@media only screen  and (min-width:766px) and (max-width:991px)
{
  .padding-custom-tab{
    padding: 0px 9%;
  }
}
/* 540px wide on screens with width between 540px and 589px */
/* @media screen and (min-width: 540px) and (max-width: 589px) {
  .img-fluid {
    width: 411px !important;
  }
} */

/* 590px wide on screens with width between 590px and 639px */
/* @media screen and (min-width: 590px) and (max-width: 639px) {
  .img-fluid {
    width: 590px !important;
  }
} */

/* Continue adding media queries for each 50-pixel step */

.heading {
  color: var(--Neutral-color, #212524);
  font-family:'Playfair Display', serif;;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.login-heading{
  color: var(--Primary-color, #364D49);
  font-family: 'Playfair Display', serif !important;
  font-size: 23px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 32px; /* 133.333% */
  
}

.text-center{
  color: var(--Neutral-color, #212524);
  font-family: Playfair Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.link-position{
  position: relative;
  top: -5px;
}

.row-position{
  margin-top: 20px !important; 
}