.product_image {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  width:100%;
  height: 221px;
  object-fit: cover;
  position: center;
}

.product_name {
  color: #364d49;
  font-family: "Playfair Display";
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
}

.product_price {
  color: #364d49;
  font-family: Glacial Indifference Bold !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.modal_content {
  display: flex;
  flex-direction: column;
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
    /* Hide scrollbars for Chrome, Safari, and Edge */
    -webkit-scrollbar-width: 0px;
    -moz-scrollbar-width: 0px;
  
    /* Hide scrollbars for IE, Edge, and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.prodcut_short_desc {
  color: #272323;
  font-family: "Glacial Indifference";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.algenre_items {
  display: flex;
  flex-direction: column;
}

.algenre_heading {
  color: #364d49;
  font-family: "Playfair Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.subItem_container{
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
}
.subItem_wrapper{
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.icon_filled{
    font-size: 20px;
    color: #364D49;
  border-radius: 50%;
}

.subitem_heading{
    color: #272323;
font-family: "Glacial Indifference";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.extra_heading{
    color: #364D49;
font-family: "Playfair Display";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}


.extra_container{
    display: flex;
    flex-direction: column;
}
.extra_item_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.checkbox_container{
    display: flex;
    align-items: center;
gap: 8px;
}

.checkbox_container .icon_empty{
    color: #aaaaaa;
    font-size: 25px;
    cursor: pointer;
}

.extra_item_heading{
    color: #364D49;
font-family: "Glacial Indifference";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.extra_item_price{
    color: #364D49;
    font-family: Glacial Indifference Bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.cart_wrapper{
    border-radius: 0px 0px 20px 20px;
background: #FFF;
box-shadow: 0px -6px 18px 0px rgba(176, 176, 176, 0.25);
display: flex;
flex-direction: row;
justify-content: space-between;
gap: 10px;
padding: 10px 8px;
}

.button_wrapper{
    display: flex;
    flex-direction: row;
    gap: 18px;
    align-items: center;
}

.increment{
    width: 35px;
    height: 35px;
}

.cart_title{
    color: #2C2121;
font-family: "Glacial Indifference";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.button_cart{
display: flex;
flex-direction: row;
width: 220px;
padding: 16px 40px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 14px;
background: #364D49;
color: #FFF;
font-family: "Glacial Indifference";
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
white-space: nowrap;
border: none;
}

.closeIcon{
    width: 40px;
height: 40px;
background-color: #d3ddd9;
border-radius: 50%;
position: absolute;
display: flex;
justify-content: center;
align-items: center;
right: 3%;
top: 0%;
}

.closeIcon .icon_close{
    color: #364D49;
    font-size: 25px;
}


@media only screen and (min-width:766px) and (max-width:991px){
    .closeIcon{
        right: 3%;
        top: 1%;
    }
}


@media only screen and (max-width:600px){
    .closeIcon{
        right: 2.5%;
        top: 2%;
    }

    .algenre_heading{
        font-size: 18px;
    }
    .extra_heading{
        font-size: 18px;
    }

    .increment{
        width: 30px;
        height: 30px;
    }

    .button_cart {
        width: 195px;
        padding: 13px 40px;
    }

    .product_image{
        border-radius: none;
    }
}