.main-outer-category {
  background-color: #f7f7f7;
  margin-top: -27px;
}

.sticky-subheader {
  display: none;
  align-items: center;
  justify-content: space-evenly;
  overflow-y: hidden;
  /* overflow-x: auto; */
  background: #f7f7f7;
  padding-left: 10%;
  padding-right: 10%;
  color: #000000;
  width: 90%;
  height: 50px;
  position: fixed;
  top: 0;
  z-index: 2;
}

.outer-category {
  width: 91%;
  height: 100px;
  overflow-x:hidden;
  scroll-behavior: smooth;
  /* justify-content: center; */
  top: -35px;
  position: relative;
  z-index: 1;
  left: 50px;
}

.inner-category {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  /* position: absolute; */
  width: 100%;
  height: 56px;
}

.single-category {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  height: 56px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.inner-single-category {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0px;
  gap: 16px;
  /* background-color: black; */
  /* height: 45px; */
  width: auto;
  /* background: #FED301;
    border: 1px solid #FED301; */
  border-radius: 100px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-size: medium;
  margin: 4px;
  margin-left: 400;
  font-family: "Playfair Display";
  /* margin-bottom: 200px; */
}

.inner-single-category :hover a {
  background-color: #fed301;
}

.active-item {
  background-color: #fed301 !important;
}

/* .inner-single-category :hover{
      background-color: #FED301 ;
    } */
.img-inner-category {
  width: 48px;
  height: 48px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.category-product-name {
  width: auto;
  height: 24px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 37px;
  display: flex;
  color: #fff;
}

.bg {
  background-color: #2d5c48;
}

.bg-white {
  background-color: white;
}

.inner-category {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  height: 56px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.category-left-next {
  left: -webkit-calc((93.3vw - 1160px)/2);
}

.category-left-arrow {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0px;
  left: 20px;
  background: #fff !important;
  border-radius: 50%;
  position: relative;
  top: 14px;
  transform: translateY(-50%);
  height: 34px;
  /* z-index: 2; */
}

.category-left-arrow .fa {
  color: #2d5c48;
  font-size: 30px;
}

/* .category-right-next{
    
        right: -webkit-calc((91vw - 1160px)/2);
      
      } */

.category-right-arrow .fa {
  color: white;
  font-size: 30px;
}

.category-right-arrow {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0px;
  background: #fff;
  font-weight: 500;
  position: relative;
  transform: translateY(-50%);
  z-index: 2;
  opacity: 1;
  right: -98%;
  /* left: 17px; */
  top: -120px;
}

.sticky-subheader-outer {
    width: 100%;
    height: 63px;
    background-color: #2f4e49;
    color: white;
    position: sticky;
    position: relative;
    top: 80px;
    left: 0;
    border-radius: 6px;
    z-index: 1;

}
.sticky-subheader-outer-over{
  width: 100%;
  height: 63px;
  background-color: #2f4e49;
  color: white;
  position: sticky;
  position: relative;
  top: 137px;
  left: 0;
  border-radius: 6px;
  z-index: 1;
}




.main-outer-category {
  overflow: auto;
  height: 100px;
  position: relative;
}


.CateScroll {
  position: fixed;
  top: 0;
  left: 0;
  /* background-color: #F7F7F7; */
  width: 100%;
  height: fit-content;
  z-index: 5;
  margin-top: -24px;
  margin-bottom: -36px;
  /* padding-top:5px */
}

section {
  padding: 0px !important;
}


@media only screen and (min-width: 320px) and (max-width: 576px) {

  .sticky-subheader-outer-over{
    top: 71px;
  }

   .sticky-subheader-outer{
      left: 0%;
      right: 0%;
      top: 25px;
    }

  .outer-category {
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 10px;
    top: -6px;
    width: 100%;
    left: 0px;
    height: 46px;

       /* Custom scrollbar styling for WebKit browsers */
       scrollbar-width: thin;  /* For Firefox */
       scrollbar-color: #ffffff #f0f0f0;  /* For Firefox */
     
       /* For WebKit browsers (Chrome, Safari) */
       &::-webkit-scrollbar {
         width: 0px;
         display: none ;
       }
     
       &::-webkit-scrollbar-thumb {
         background-color: #fffefe;
         display: none;
       }
     
       &::-webkit-scrollbar-track {
         background-color: #f0f0f0;
         display: none;
       }
  }



  .category-right-arrow {
    top: -76px;
    right: -94%;
  }
  .outer-category{
    top: -40px;
    width: 85%;
    left: 25px;
  }

  .category-left-arrow {
    top: 3px;
    left: 10px;
    z-index: 89898;
   
  }
  .category-left-arrow .fa {
    font-size: 18px;
  }

  .category-right-arrow .fa{
    font-size: 18px;
  }

  .single-category
  {
    padding: 0px 12px 5px 5px;
  }
  .sticky-subheader-outer {
    border-radius: 0px !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 778px) {
  .outer-category {
    overflow-x: auto;
    margin-top: 1px;
    margin-left: -14px;
    margin-bottom: 44px;
       /* Custom scrollbar styling for WebKit browsers */
       scrollbar-width: thin;  /* For Firefox */
       scrollbar-color: #ffffff #f0f0f0;  /* For Firefox */
     
       /* For WebKit browsers (Chrome, Safari) */
       &::-webkit-scrollbar {
         width: 0px;
         display: none ;
       }
     
       &::-webkit-scrollbar-thumb {
         background-color: #fffefe;
         display: none;
       }
     
       &::-webkit-scrollbar-track {
         background-color: #f0f0f0;
         display: none;
       }
  }

   .sticky-subheader-outer{
      left: 0%;
      right: 0%;
      top: 68px;
    }

  .category-right-arrow  {
   top: -164px;
   right: -97%;
  }

  .category-left-arrow {
   left: 4px;
  }
}

@media only screen and (min-width: 780px) and (max-width: 982px) {
  .outer-category {
    overflow-x: auto;
    margin-top: 1px;
    margin-bottom: 44px;
    margin-left: -14px;
  
    /* Custom scrollbar styling for WebKit browsers */
    scrollbar-width: thin;  /* For Firefox */
    scrollbar-color: #ffffff #f0f0f0;  /* For Firefox */
  
    /* For WebKit browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
      width: 0px;
      display: none ;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #fffefe;
      display: none;
    }
  
    &::-webkit-scrollbar-track {
      background-color: #f0f0f0;
      display: none;
    }
  }

  .category-left-arrow {
    left: 4px;
  }

  .category-right-arrow{
  top: -164px;
right: -97%;
  }
  

}


@media only screen and (min-width:766px) and (max-width:991px){
  .sticky-subheader-outer{
    width: 93%;
    left:28px;
    top: 78px;
  }
  .sticky-subheader-outer-over{
    width: 95%;
    left:21px
  }
}


@media  (min-width: 1024px) and (max-width: 1219px) {
  .outer-feature {
    overflow-x: auto;
  }
  
  .category-left-arrow {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0px;
    left: 14px;
    background: #fff !important;
    border-radius: 50%;
    position: relative;
    top: 14px;
    transform: translateY(-50%);
    z-index: 2;
}
.category-right-arrow {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0px;
  background: #fff;
  font-weight: 500;
  position: relative;
  transform: translateY(-50%);
  z-index: 2;
  opacity: 1;
  /* right: 114%; */
  left: 97.5%;
  top: -119px;
}
.main-outer-category {
  background-color: #f7f7f7;
  margin-top: -27px;
}
.inner-category {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  height: 60px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  /* margin-top: 9px; */
}

.custom-mobile {
  /* background-color: beige; */
}
}
@media only screen and (min-width: 320px) and (max-width: 576px) {
  .custom-mobile {
    margin-left: 0px;
  }
  
  nav{
    max-height: 90px;
    top: 15px;
  }

  .sticky-subheader-outer-over{
border-radius: 0px;
  }
}


@media only screen and (max-width:300px)
{

  .sticky-subheader-outer-over {
    top: 89px;
  }

  .category-left-arrow{
   left: 5px;
  }

  .category-left-arrow .fa {
    font-size: 15px;
  }
  .category-right-arrow{
   right: -95%;
  }

  .category-right-arrow .fa {
    font-size: 15px;
  }

  .outer-category{
    overflow-x: auto;
    top: -54px;
    left: 30px;
   width: 80%;
    /* Custom scrollbar styling for WebKit browsers */
    scrollbar-width: thin;  /* For Firefox */
    scrollbar-color: #ffffff #f0f0f0;  /* For Firefox */
  
    /* For WebKit browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
      width: 0px;
      display: none ;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #fffefe;
      display: none;
    }
  
    &::-webkit-scrollbar-track {
      background-color: #f0f0f0;
      display: none;
    }
  }
}