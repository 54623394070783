.modalSetting-productModal {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 40%;
  display: flex;
  background-color: white;
  box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 13px 0px,
    rgba(0, 0, 0, 0.08) 0px 3px 6px 0px;
  flex-direction: column;
  border-radius: 4pt;

  /* height: 100vh; */
  /* max-height: calc(100vh - 25px); */
  position: relative;
  padding: 0px !important;
  overflow-y: auto; /* Enable vertical scrolling */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE 10+ */
  overflow-x: hidden;
  /* border-radius: 8px;
  width: 35%;
  height: 80vh;
  position: relative;
  overflow: hidden; */
}
/* .modalSetting::-webkit-scrollbar {
  display: none; 
} */
.modal-content-container {
  max-height: -webkit-calc(100vh - 100px);
  max-height: calc(100vh - 100px);
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  width: 100%;
  -webkit-overflow-scrolling: touch; /* Use 'touch' for smooth scrolling in Safari */
  scroll-behavior: smooth !important;

  /* Hide scrollbars for Chrome, Safari, and Edge */
  scrollbar-width: thin; /* Firefox */
  -webkit-scrollbar-width: thin; /* Chrome, Safari, and Edge */

  /* Hide scrollbars for IE, Edge, and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Add Safari specific styling for scrollbar */
.modal-content-container::-webkit-scrollbar {
  width: 5px;
}

.modal-content-container::-webkit-scrollbar-thumb {
  background-color: #888; /* or any color you prefer */
}

.react-responsive-modal-root {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: fixed;
  z-index: 1051;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.5);
  overflow: auto;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-overflow-scrolling: initial !important; 
  scroll-behavior: smooth !important;
}

.react-responsive-modal-container {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: fixed;
  z-index: 1051;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.5);
  overflow: auto;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.modal-body {
  padding: 2.5px !important;
  /* max-height: 91vh; */
  /* overflow: auto; */
  /* overflow: auto !important; */
  -webkit-overflow-scrolling: touch !important;
}
.modal-content {
  border-radius: 10px;
}
.cross-svg {
  width: 65%;
  position: relative;
  bottom: 26px;
}
.productModal-image {
  width: 100%;
  height: 314px;
  min-height: 314px;
  /* You can adjust the value as needed for the maximum height */
  /* margin: auto; */
  object-fit: cover;
}
body.modal-open {
  /* height: 100vh;
  overflow-y: hidden; */
  position: fixed;
    top:    0;
    right:  0;
    bottom: 0;
    left:   0;
}
.modal {
  will-change: display;
}
.modal-dialog {
  will-change: transform;
}


.mdaalSetting .react-responsive-modal-closeButton {
  color: #fff;
  position: absolute;
  top: 14px;
  right: 45px !important;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
}
.productModal-detail {
  padding-left: 30px;
}

.productModal-name-font{
font-family: 'Playfair Display' !important;
}

.productModal-name {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  letter-spacing: -0.02em;
  margin-left: 23px;
  color: #2d5c48;
  margin-top: 30px !important;
  margin-bottom: 13px;
  position: relative;
  left: -35px;
font-family: Glacial Indifference;
}
.product-modal-price {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #2d5c48;
  margin-left: 3rem;
}

.productModal-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: rgb(13, 37, 49);
  margin-left: 14px;
  margin-right: 3rem;
  position: relative;
  left: -25px;
font-family: Glacial Indifference;
  margin-bottom: 0px;
}


.productModal-single-item {
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  /* line-height: 2px; */
  display: flex;
  align-items: center;
  /* color: #666666; */
  margin-left: 2.5rem;
  margin-top: 6.5%;
  font-family: Glacial Indifference;
  white-space: nowrap;
}
.productModal-allerges-list {
  display: flex;
  position: relative;
  left: -41px;
  margin-left: 3rem;
  margin-bottom: 5px !important;
  gap: 10px;
  flex-wrap: wrap;
}

.productModal-allerges-list > li {
  list-style: disc;
  margin-left: 0px;
  margin-right: 14px;
  font-family: Glacial Indifference;
  color: rgb(13, 37, 49);
}
.productModal-more {
  /* background-color: #f7f7f7; */
  /* border-radius: 24px; */
  /* margin-left: 20px; */
  position: relative;
  /* left: -35px; */
  padding-left: 10px;
  width: 100%;
}
.product-modal-more-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1a1a1a;
  margin-bottom: 0px;
}

.productModal-body1 {
  /* margin-top: 20px; */
  padding: 0 30px 10px;
  min-height: 99vh;
    height: auto;
    overflow: auto;
}

.disable-button{
  opacity: 0.7 !important;
  pointer-events: none !important;
  
}

.radio-modal:checked::before {
  /* background-color: green;
  border-color: green; */
  background: #2f4e49 !important;
}

.productModal-index {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #0f0f0f;
    right: -70%;
    position: absolute;
    color: #212524;
    font-family: Glacial Indifference;
}
.product-modal-special {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1a1a1a;
  margin: 0px;
  margin-left: 1rem;
}

.form-text {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 16px;
}

.productModal-cart-product-quantity {
  /* background-color: #2d5c48; */
  /* height: 20px; */
  width: 20px;
  /* border-radius: 100px; */
  /* padding-top: 2.5px; */
  /* padding-left: 6px; */
  color: #2d5c48 !important;
  position: relative;
  left: 18px;

  /* margin-top: 2px; */
}
.productModal-cart-product-choice-quantity {
  width: 20px;
  color: #2d5c48 !important;
  position: relative;
  left: 7px;

  /* margin-top: 2px; */
}
.product-ingrediant {
  margin-left: 27px;
}
/* .cart-outer-quantity{

    padding: 8px 16px;
    grid-gap: 16px;
    grid-gap: 16px;
    gap: 16px;
    width: 111px;
    height: 41px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 100px;

} */

.productModal-cart-product-icons {
  display: flex;
  float: right;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background: #ffffff;
  width: 65%;
  height: 75%;
  padding: 13px 16px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.productModal-cart-product-icons-add-to-cart {
  display: flex;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background: #ffffff;
  width: 100%;
  height: 75%;
  padding: 6px 7px;
  justify-content: space-between;
  align-items: center;
}
.productModal-cart-outer-quantitys {
  display: flex;
  flex-direction: row;
  /* padding: 16px; */
  border-style: none;
  height: fit-content;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 6px;
  background: rgb(243, 244, 239);
  position: relative;
  top: 5px;
  /* padding: 6px 16px;
  grid-gap: 16px;
  width: 90%;
  height: 39px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 10px;
  display: inline-block;
  position: relative;
  left: 25px; */
}
.crt-btn {
  display: flex;
  position: sticky;
  bottom: 0px;
  background-color: #f7f7f7;
}
.react-responsive-modal-overlay {
  position: absolute;
}
.productModal-cart-btn {
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  border-style: none;
   -webkit-box-pack: justify;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 6px;
  width: 103%;
  background: #2f4e49;
  cursor: pointer;
  font-family: Glacial Indifference;
  gap: 5px;
  /* display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 8px 20px;
  gap: 8px;
  border: none;
cursor: pointer;
  height: 40px;
  background: #2d5c48;
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 25px;
  color: #1a1a1a; */
}

.font_weight_custom{
  font-weight: 600;
}

.product-ingrediant-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.productModal-cart-outer-div {
  margin-top: 5px;
  width: 90%;
  background: #cccccc;
}

.product-modal-over{
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 5px 0px gray inset;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
input[type="radio"]:checked:before {
  background: black;
}
input[type="checkbox"] {
  /* margin: 0px;
  display: flex;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(139, 143, 161);
  box-sizing: border-box;
  border-radius: 4px;
  width: 24px;
  min-width: 24px;
  color: white;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 24px;
  margin-left: -20px;
  outline: none; */
  /* -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border: 1px solid darkgray;
  outline: none;
  padding-left: 2px;
  font-weight: bolder;
  box-shadow: 0 0 5px 0px grey inset;
  margin-top: 4px; */
}
input[type="checkbox"]:before {
  display: block;
  width: 60%;
}
input[type="checkbox"]:checked:after {
  /* content: "\2713";
  margin: 0px;
  display: flex;
  background: rgb(29, 78, 71);
  border: 1px solid rgb(29, 78, 71);
  box-sizing: border-box;
  border-radius: 4px;
  width: 24px;
  min-width: 24px;
  color: white;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 24px; */
}
@media (min-width: 320px) and (max-width: 1920px) {
  .modalSetting-productModal {
    width: 35%;
    /* height:95vh; */
  }
}
@media (min-width: 320px) and (max-width: 1366px) {
  .modalSetting-productModal {
    width: 45%;
    /* height:95vh; */
  }
}
@media screen and (min-width: 768px) {
  .modalSetting-productModal {
    width: 550px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .modalSetting-productModal {
    width: 95%;
    max-height: 88vh;
  }
  .modal-cart-btn {
    margin-left: 10px !important;
    font-size: 16px;
  }
  /* .cart-outer-quantitys {
    width: 50%;
  } */
  .modal-cart-outer-div {
    width: 94%;
  }
  .product-modal-body {
    margin-top: 20px;
    padding: 0px 10px 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .modal-cart-btn {
    /* margin-left: 10px !important; */
    font-size: 16px;
  }
  .modal-cart-outer-div {
    width: 90%;
  }
}
@media (min-width: 768px) and (max-width: 912px) {
  .cart-outer-quantitys {
    width: 100%;
  }
  .modal-cart-outer-div {
    width: 100%;
  }
  .modal-cart-btn {
    /* margin-left: 10px !important; */
    font-size: 16px;
  }
  .modalSetting-productModal {
    width: 95%;
    height: 95vh;
  }
}

@media (min-width: 320px) and (max-width: 500px) {

  .modal_btns{
height: 27px !important;
  }

  .productModal-cart-outer-quantitys {
    width: 140%;
    left: -8px;
  }
  .productModal-cart-outer-div {
    float: right;
    width: 100%;
  }
  .productModal-cart-product-icons {
    width: 100%;
    height: 65%;
  }
  .shopping-cart-icon {
    display: none !important;
  }
  .product-modal-more {
    padding: 30px !important;
  }
  .modal-cart-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: unset !important;
    grid-gap: 8px;
    gap: 8px;
    border: none;
    cursor: pointer;
    height: 40px;
    background: #2f4e49;
    border-radius: 100px;
    flex: none;
    order: 0;
    flex-grow: 1;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    /* margin-left: 25px; */
    color: #1a1a1a;
  }
}
.react-responsive-modal-closeButton {
  position: absolute;
  top: 14px;
  right: 45px !important;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
}
.colored-toast.swal2-icon-success {
  background-color: #a5dc86 !important;
}
.combo-details {
  /* height: 35px; */
  position: relative;
  left: -12px;
}


.orange-disk {
  width: 18px;
  height: 18px;
  background-color: #364D49;
  /* Adjust the color as needed */
  border-radius: 50%;
  /* Makes it a circle */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
}

.check-icon {
  color: white;
  /* Color of the check icon */
}

@media only screen and (max-width:600px)
{

  .productModal-more{
    height: 230px;
  }

  .margin-mobile{
    margin-left: 10px !important;
  }

  .productModal-cart-product-icons-add-to-cart{
    padding: 10px 7px !important;
  }

  .modal-bottom-buttons{
    margin-left: 10px !important;
    position: static;
  }

 
}


@media only screen and (max-width:300px)
{
  .modalSetting-productModal {
    width: 100%;
  }

  .productModal-name{
    font-size: 14px !important;
  }
  .productModal-cart-outer-quantitys{
    width: 181%;
    left: -21%;
  }

  .font_weight_custom{
    font-size: 10px;
    white-space: nowrap;
  }

  .modal_btns{
    height: 25px !important;
  }

  .productModal-cart-product-icons-add-to-cart{
    padding: 11px 5px !important;
  }
  
}


.closeIcon{
  width: 40px;
height: 40px;
background-color: #d3ddd9;
border-radius: 50%;
position: absolute;
display: flex;
justify-content: center;
align-items: center;
right: 2%;
top: 1%;
z-index: 999999999;
}

.closeIcon .icon_close{
  color: #364D49;
  font-size: 25px;
}