.main-order {
  height: 249px;
  width: 589px;
  margin-top: 3%;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  background: #fff;
  box-shadow: 0px 6px 18px 0px rgba(190, 189, 189, 0.25);
  padding-left: 0px;
  padding-top: 4px;
  padding-bottom: 10px;
}

.order_card_warpper{
  height: 50vh;
  overflow-y: auto;
}

.order_card_warpper::-webkit-scrollbar {
  width: 12px; 
}

.order_card_warpper::-webkit-scrollbar-thumb {
  background-color: #364D49; 
  border-radius: 6px; 
}

.order_card_warpper::-webkit-scrollbar-track {
  background-color: #ffffff; 
}


.main-section {
  border-radius: 10px;
  border: 1px solid #a5a1a1;
  width: 996px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
padding-top: 2%;
padding-bottom: 70px;

}

.newOrder {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heading-tp {
  color: var(--Primary-color, #364d49);
  font-family: Playfair Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center !important;
}

.right-text {
  color: var(--grey-grey-300, #6a6d6c);
  font-family: Playfair Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.left-text {
  color: var(--Neutral-Color, #33363b);
  font-family: Glacial Indifference;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.section_padding {
  padding: 10px 15px;
}

.header-section {
  padding: 10px 15px;
}

.detail-btn {
  display: flex;
  width: 250px;
  height: 35px;
  padding: 12px 125px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #364d49;
  color: #33363b;
  white-space: nowrap;
  font-family: Glacial Indifference;
}

.order-btn {
  display: flex;
  width: 250px;
  height: 35px;
  padding: 12px 125px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #364d49;
  color: #fff;
  white-space: nowrap;
   font-family: Glacial Indifference;
   border: none;
}

.order-btn:hover{
    color: white;
    background-color: #364d49;
}

.scroll-class {
  overflow-y: auto;
}

.modal-body {
  max-height: 689px;
  overflow-y: auto;
}

.custom-modal-body {
  max-height: 550px; /* Adjust as needed */
  overflow-y: auto;
  scrollbar-width: none;
  padding: 0px 16px;
}
.custom-modal-body::-webkit-scrollbar {
  display: none; /* Hide the scrollbar in WebKit browsers (Chrome, Safari, etc.) */
}

.hr-class {
  padding: 4px 0px !important;
  margin-top: 10px;
}



.text-left {
  color: var(--grey-grey-300, #6a6d6c);
  font-family: Playfair Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-right {
  color: var(--Neutral-color, #212524);
  font-family: Glacial Indifference;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px;
}

.close-s {
  top: 0.5% !important;
  left: 89%;
  z-index: 999;
  cursor: pointer;
}

.text-place-right {
  gap: 40px;
}

.special-text {
  color: #1e950b !important;
}

.price {
  font-weight: 700 !important;
}

.text-items-left {
  color: var(--grey-grey-200, #989b9a);
  font-family: Glacial Indifference;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 4%;
}
.text-items-right {
  color: var(--grey-grey-200, #989b9a);
  font-family: Glacial Indifference;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sec_title {
  color: var(--Primary-color, #364d49);
  font-family: Playfair Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.section_custom {
  padding: 8px 0px;
}

.back-btn {
  align-self: self-start;
  margin-left: 8%;
margin-top: 7%;
}

.detial-modal-padding{
  padding: 0px 16px !important;
}


.close-modal-detail{
  background-color: #364D49;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 2%;
  border-radius: 50%;
  color: white;
  font-size: 25px;
  right: 2%;
}

.instuction_checkout{
  color: var(--Neutral-Color, #33363b);
  font-family: Glacial Indifference;
  font-size: 18px;
  font-style: no1rmal;
  font-weight: 400;
  line-height: normal;
}

.empty_title{
  color: #364D49;
text-align: center;
font-family: "Playfair Display";
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
width: 70%;
}

.empty_button{
  display: flex;
width: 285px;
padding: 16px 60px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 14px;
background: var(--Primary-color, #2F4E48);
color: #FFF;
font-family:Glacial Indifference;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.empty_button:hover{
  color: #FFF;
}

@media only screen and (min-width:766px) and (max-width:991px){
  .main-section{
    width: 657px;
  }

  .order_card_warpper{
    height: 60vh;
    overflow-y: scroll;
  }
}


@media only screen and (max-width: 600px) {
  .custom-modal-body {
    max-height: 420px;
    padding: 0px 6px;
  }

  .detial-modal-padding{
    padding: 0px 4px !important;
  }

  .newOrder {
    justify-content: start;
  }

  .back-btn {
    margin-left: 0%;
    margin-top: 12%;
  }

  .heading-tp {
    text-align: start !important;
    font-size: 20px;
    padding-left: 5px;
  }

  .main-order {
    width: 358px;
    height: 257px;
  }

  .section_padding {
    padding: 15px 15px;
  }

  .detail-btn {
    display: flex;
    width: 156px;
    height: 35px;
    padding: 12px 80px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
  }

  .order-btn {
    display: flex;
    width: 156px;
    height: 35px;
    padding: 12px 80px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
  }

  .right-text {
    font-size: 14px;
  }
  .left-text {
    font-size: 14px;
  }

  .mobile-order {
    justify-content: space-between;
  }

  .text-right {
    font-size: 14px;
    white-space: nowrap;
  }

  .text-left {
    font-size: 14px;
    white-space: nowrap;
  }

  .react-responsive-modal-modal {
    padding: 10px 16px;
  }
  .react-responsive-modal-closeButton {
    top: 3px;
    right: 38px !important;
  }

  .close-s {
    top: 0.5% !important;
  }

  .main-section {
  border-radius: 10px;
  border: 0px solid #a5a1a1 !important;
  width: 365px;
  padding-top: 0%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
  flex-shrink: 0;

}

.order_card_warpper{
  height: 70vh;
  overflow-y: scroll;
}

.instuction_checkout{
  color: var(--Neutral-Color, #33363b);
  font-family: Glacial Indifference;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.empty_title{
font-size: 16px;
}

.empty_button{
  font-size: 16px;
  padding: 12px 40px;
}

}


@media only screen and (min-width:385px) and (max-width:410px)
{
  .main-section {
    border-radius: 10px;
    border: 0px solid #a5a1a1 !important;
    width: 365px;
    height: 75vh;
    padding-top: 0%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;
    flex-shrink: 0;
   
  }

  .order_card_warpper{
    height: 60vh;
    overflow-y: scroll;
  }
  .instuction_checkout{
    color: var(--Neutral-Color, #33363b);
    font-family: Glacial Indifference;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}





@media only screen and (max-width:380px){
  .main-section {
    border-radius: 10px;
    border: 0px solid #a5a1a1 !important;
    width: 365px;
    padding-top: 10%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;
    flex-shrink: 0;
    margin-left: 10px;
  }
  .instuction_checkout{
    color: var(--Neutral-Color, #33363b);
    font-family: Glacial Indifference;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .order_card_warpper{
    height: 60vh;
    overflow-y: scroll;
  }

  .heading-tp{
    padding-left: 15px;
  }

  .main-order{
    width: 91vw;
    margin-left: 5px;
  }

  .detail-btn{
    width: 115px;
    padding: 10px 62px;
  }

  .order-btn{
    width: 115px;
    padding: 10px 62px;
  }
  
}


@media only screen and (max-width:300px)
{
  .main-section{
    width: 293px;
  }

  .order-btn {
    width: 72px;
    padding: 10px 48px;
}

.detail-btn {
  width: 72px;
  padding: 10px 48px;
}
.css-k8k6pr{
  padding-left: 13px !important;
  padding-right: 22px !important;
}

.css-1llsor2{
  width: 309px !important;
}
}