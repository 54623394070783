
.container-sidebar{
z-index: 999999999;
}

.icon-container{
    background-color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: relative; */
}




.sideBar-container{
    background-color: white;
    width: 322px;
    height: 100vh;
    /* position: absolute;
    left: -340%;
    top: -60%; */
    z-index: 9999999999;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 16px 24px;
}

.top_bar{
    display: flex;
    justify-content: space-between;
    padding-top: 53px;
    padding-left: 20px;
    padding-right: 10px;
    padding-bottom: 15px;
}

.signIn-btn{

background-color: #364D49;
border: none;
padding: 8px 24px ;
border-radius: 10px;
color: white;
font-family: Glacial Indifference;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-left: 7px;
}

.signIn-btn:hover{
    color: #FFF;
}

.sign-btn{
    color: #364D49;
    font-family: Glacial Indifference;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background-color: transparent;
}

.sign-btn:hover{
    color: #364D49;
}

.close-sidebar{
    color: var(--Neutral-color, #212524);
    font-family: Glacial Indifference;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background-color: transparent;
    cursor: pointer;
}


.content_wrapper_link{
    padding: 10px 16px;
}

.content_left_text{
    color: var(--neutral-neutral-200, #A2A3A5);
    font-family: Glacial Indifference;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.content_text_right:hover{
    color: var(--neutral-neutral-200, #A2A3A5) !important;
}

.content_text_right{
    color: var(--neutral-neutral-200, #A2A3A5);
font-family: Glacial Indifference;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
}

.content-left-active{
    color: var(--Neutral-color, #212524);
font-family: Glacial Indifference;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.content_right_active{
    color: var(--Primary-color, #364D49);
    font-family: Glacial Indifference;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
}

.content-right-disable{
    color: var(--Primary-color, white);
    font-family: Glacial Indifference;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    cursor: not-allowed;
    pointer-events: none;
}

.content-right-disable:hover{
    color: white;
}

.content_right_active:hover{
    color: var(--Primary-color, #364D49) !important;
}

.logout_text{
    color: #000;
cursor: pointer;
font-family: Glacial Indifference;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.logout_icon{
    color: #364D49;
    font-weight: 800;
    font-size: 20px;
}

.app-header{
    color: #000;

    font-family: Glacial Indifference Bold;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;  
}

.footer_sidebar{
    position: absolute;
    bottom: 0%;
    padding: 0px 15px;
}
.footer_sidebar span{
    color: #000;

font-family: Glacial Indifference;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.special-sidebar-image{
    width: 120px !important;
}



.disabled{
    pointer-events: none;
    cursor: not-allowed;
}
.profile_container{
    background-color: #364D49;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile_icons{
    color: white !important;
}

.user_name{
    color: var(--Primary-color, #364D49);
    font-family: Glacial Indifference;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.user_email{
    color: var(--grey-grey-200, #989B9A);
    font-family: Glacial Indifference;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.close-btn-modal{
    position: absolute;
    right: 18px;
    top: 5%;
    background-color: #364D49;
    color: white;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

.close-btn-modal-open-hours{
    position: absolute;
    right: 18px;
    top: 2%;
    background-color: #364D49;
    color: white;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}



.hour-title{
    color: #364D49;
    padding-top:10px;
    padding-bottom: 0px;
font-family: Playfair Display;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.custom-hours-padding{
    padding: 10px 0px;
}

.hr-line-modal{
    padding-bottom: 5px;
    margin-top: -10px;
    color: #c7c7c7;
    border-top: 1px solid rgb(202, 202, 202);
    width: 500px;
    margin-left: -13.5%;
}

.hr-line-modal-contact{
    padding-bottom: 5px;
    margin-top: -10px;
    color: #222121;
    border-top: 1px solid rgb(202, 202, 202);
    width: 560px;
    margin-left: -7%;
}

.hr-line-modal-contact-down{
    margin-top: 30px;
    color: #222121;
    border-top: 1px solid rgb(97, 96, 96);
    width: 504px;
    margin-bottom: 10px;
}

.cookeies-button{
    display: flex;
height: 36px;
padding: 8px 16px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 4px;
flex: 1 0 0;
border-radius: 10px;
background: #364D49;
color: #FFF;
cursor: pointer;
box-shadow: none;
border: none;
font-family: Glacial Indifference;
font-size: 14px;
font-style: normal;
font-weight: 700;
letter-spacing: 1px;
line-height: 24px;
}

.cookeies-text{
    color: #605A5A;

font-family: Glacial Indifference;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}

.button_gap{
    gap: 4px;
    margin-left: -19px;
}

.buttonlogoutgap{
    margin-left: -8px;
    gap: 20px;
}

.lang-reset{
    display: flex;
    width: 150px;
    height: 44px;
    padding: 10px 80px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 14px;
border: 1px solid var(--Neutral-color, #212524);
color: var(--Neutral-color, #212524);
font-family: Glacial Indifference;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
background-color: white;
}


.lang-save{
    display: flex;
    width: 150px;
    height: 44px;
    padding: 10px 80px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 14px;
background: var(--primary-primary-500, #364D49);
color: #FFF;
font-family: Glacial Indifference;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
border: none;
}

.lang-text{
color: var(--Neutral-color, #212524);
font-family: Playfair Display;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.radio-lang{
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid black; /* Black border */
    background-color: white; /* White background */
    margin-right: 8px;
    vertical-align: middle;
    cursor: pointer;
}

.lang-subheading{
    color: var(--Neutral-color, #212524);
font-family: Playfair Display;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left: -17px;
}

.lang-heading{
    margin-left: -17px;
    color: var(--Neutral-color, #212524);
font-family: Playfair Display;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.logout-text{
    color: var(--Neutral-color, #212524);
text-align: center;
font-family: Playfair Display;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 140%;
}

.lang-reset-specail{
    display: flex;
width: 200px;
padding: 16px 90px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 14px;
border: 1px solid var(--Neutral-color, #212524);
color: var(--Neutral-color, #212524);
font-family: Glacial Indifference;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
height: 50px;
background-color: white;
}


.lang-save-special{
    height: 50px;
    display: flex;
width: 200px;
padding: 16px 90px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 14px;
background: var(--primary-primary-500, #364D49);
color: #FFF;
font-family: Glacial Indifference;
font-size: 18px;
font-style: normal;
font-weight: 700;
border: none;
line-height: normal;
}



@media only screen and (min-width:766px) and (max-width:991px)
{
    .sideBar-container{
        left: 0%;
    }

    .container-sidebar{

        /* margin-top: 0px !important */
        }
}

.hours-text-active-day{
    color: var(--Neutral-color, #212524);
font-family: Playfair Display;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.hours-text-active-hour{
    color: var(--Neutral-color, #212524);
    font-family: Glacial Indifference;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.hour-text-days{
    color: #000;
    font-family: Playfair Display;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.modal_scroll{
    overflow-y: scroll;
}

.hours-text-time{
    color: #000;

    font-family: Glacial Indifference;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media only screen and (max-width:600px)
{

    .lang-save-special{
        width: 130px;
    padding: 16px 63px;
    }

    .lang-reset-specail{
        width: 130px;
    padding: 16px 63px;
    }

    .sideBar-container{
        left: 0%;
        background-color: white;
        width:100vw;
        height: 100vh;
        position: absolute;
        z-index: 99999;
        top: 0%;
        right: 0%;
    }

    .content_wrapper_link{
        margin-bottom: -5px;
    }

    .footer_sidebar{
bottom: 1%;
    }

    .buttonlogoutgap{
        margin-left: -20px;
        gap: 8px;
    }

    .hr-line-modal{
        width: 350px;
        margin-left: -21%;
    }

    .hr-line-modal-contact{
        width: 357px;
        margin-left: -11%;
    }
    .hr-line-modal-contact-down{
        width: 300px;
    }
    .hour-title{
        font-size: 20px;
    }

    .special-sidebar-image{
        width: 100px !important;
    }

    .address_mobile{
        width: 55%;
    }
}


@media only screen and (max-width:300px)
{
    .hours-text-active-hour{
        font-size: 16px;
    }

    .hours-text-active-day{
        font-size: 16px;
    }

    .hour-text-days{
        font-size: 14px;
    }

    .hours-text-time{
        font-size: 14px;
    }

    .css-k8k6pr{
        width: 280px !important;
    }

    .css-s2gilp{
        width: 280px !important;
    }

    .ml-custom{
        margin-left: -4px !important;
    }
}