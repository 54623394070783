@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Playfair Display', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-wrapper {
display: flex;
  flex-direction: column;
  min-height: 100vh;
}
@font-face {
  font-family: "Glacial Indifference";
  src: local(" Glacial Indifference"),
    url(/static/media/GlacialIndifference-Regular.884dc679.otf) format('woff'),
      url(/static/media/GlacialIndifference-Regular.ec264fec.woff2) format('woff2');
      font-weight: normal;
      font-style: normal;
}

@font-face {
  font-family: "Glacial Indifference Bold";
  src: local("Glacial Indifference Bold"),
    url(/static/media/GlacialIndifference-Bold.e742f534.otf) format('woff'),
    url(/static/media/GlacialIndifference-Bold.d06d71f3.woff2) format('woff2');
    font-weight: bold;
    font-style: normal;
}

body{
  height: 100vh;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#round {
    border-radius: 5px;
    border-color: gray;
    height: 10px;
    font-family: 'playfair display' !important;
  }
  #round1 {
    border-radius: 10px;
    border-color: gray;
    height: 42px;
    width: 430px;
  }
  #round2 {
    border-radius: 10px;
    border-color: gray;
    height: 150px;
    width: 100%;
    resize: none;
  }
  
  textarea {
    padding: 6px 8px;
    line-height: 1.5;
    resize: none;
  }
  #txt {
    color: rgb(100, 99, 99);
    font-family: 'playfair display' !important;
  }
  
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media (max-width: 600px) {
  .mobile-contact-div {
    margin-top: 90px !important;
  }
}

.outer-location {
  font-size: 26px;
  background: #fff;
  border-radius: 28px;
  position: relative;
  top: 40px;
  right: 41px;
}
.wh-public-icons-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
  color: white;
}
.wh-private-icons-container {
  display: flex;
  justify-content: flex-end;
}
.wh-dd-btn-cntr {
  margin-top: 16px;
  cursor: pointer;
}
.wh-dm-user-info {
  border-bottom: 1px solid #364d49;
  padding: 10px;
  margin-bottom: 10px;
}
.badge-circle-header {
  position: absolute;
  top: -33px;
  width: 1.6rem;
  border-radius: 50%;
  color: #fff;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.6rem;
  font-family: "Open Sans", sans-serif;
  text-align: center;
}
.location {
  width: 22%;
  margin-bottom: 8px;
}
::placeholder {
  font-size: 1em;
  color: mintcream;

  padding: 7px;
}

.input-style {
  width: 317px;
  height: 43px;
  background: #ffffff;
  border: 1px solid #99a2aa;
  border-radius: 5px;
  display: block;
  /* font-size: 17px; */
  font-style: "normal";
  font-family: "Times New Roman";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8b8fa1;
  padding: 13px 44.14px 13px 20px;
}

.search-icon {
  position: relative;
  top: 38px;
  right: -12px;
  font-size: 21px;
  color: #999999;
}
.icons-container {
  display: flex;
  padding: 3rem 0;
  color: white;
  justify-content: end;
  position: relative;
  text-decoration: underline;
  font-family: "playfair display";
}
.header-login-btn:hover {
  color: #fff !important;
}
.search-outer {
  left: 20%;
  position: relative;
  /* width: 132px; */
  top: 20px;
}
.user-name {
  font-style: normal;
  font-weight: 500;
  margin-top: 10px;
  font-size: 20px;
  font-family: "Times New Roman";
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: white;
  position: relative;
  top: -55px;
}
.shoppping-name {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: white;
  position: relative;
  top: -20px;
  left: -25px;
  font-family: "Times New Roman";
  width: 0%;
  text-transform: capitalize;
  /* font-size: 22px; */
}
.image-location-outer {
  position: relative;
  top: 41px;
  right: 46px;
}

.header-logo {
  cursor: pointer;
  /* padding-top: 2rem; */
  margin-top: 20px;
  /* width: 100px; */
  height: 48px;
}
.header-name {
  cursor: pointer;
  padding-top: 5rem;
  margin-top: -90px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  height: 70px;
  margin-right: 5px;
  margin-left: 70px;
  /* width: 100px; */
  /* height: 20px; */
}
.search {
  font-size: 27px;
  display: none;
}

.header-middle {
  padding: 0.7rem !important;
}
.span-background {
  border-radius: 21px;
  /* background-color: #fff; */
  width: 38px;
  text-align: center;
  font-size: 22px;
  height: 39px;
  cursor: pointer;
  position: relative;
  top: -12px;
  right: -22px;
}
.custom-search {
  height: 25px;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #364d49;
  height: 44px;
}
.btn-name-search {
  position: relative;
  top: -14px;
  left: -33px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.5;
  color: #ffffff;
  font-weight: 500;
  line-height: 32px;
  font-family: "Times New Roman";
  text-transform: capitalize;
}
.dropdown-icon {
  font-size: 20px;
  margin-top: 10px;
}

/* .Menu-icon {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px 11px;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  background: #FFF;
  
} */

.mobile-menu-icon {
  border-radius: 21px;
  background-color: #fff;
  color: #2d5c48;
  width: 37px;
  left: -3%;
  text-align: center;
  font-size: 23px;
  padding-bottom: 5px;
  height: 34px;
}

.form-extra-margin {
  margin-bottom: 0px !important;
  margin-top: -30px;
}
.shopping-cart {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 13px 12px 14px 11px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #FFF;
  background: #FFF;
}
.badge-circle {
  position: absolute;
  top: 28px;
  right: 30px;
  width: 1.6rem;
  border-radius: 50%;
  color: #fff;
  background: #d60000;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.6rem;

  text-align: center;
}
.outer-logo {
  width: 100%;

  margin-top: -13px;
}
.web-header {
  display: block;
  background-color: #2f4e49;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 96px;
  z-index: 100;
}


.mobile-header {
  max-width: auto;
  height: auto;
  background-color: #2f4e49;
}
.main-mobile-header {
  display: flex;
  justify-content: space-around;
  padding: 2rem;
}
.mobile-header-search {
  padding-bottom: 1px;
  padding: 10px;
}
.shopping-cart svg {
  overflow: hidden;
  vertical-align: -6px !important;
}
.input-mobile-header {
  max-width: 700px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  display: block;
  padding-left: 10%;
  font-size: 16px;
}
.body {
  font-family: "Playfair Display";
}
.install-button-mobile-header {
  border: 1px solid #2d5c48;
  color: #364d49;
  border-radius: 4px;
  font-size: 18px;
  padding: 5px 15px;
  background: transparent;
}
.install-para-mbl-hdr {
  color: #2d5c48;
  font-size: 18px;
}
@media (min-width: 1024px) {
  .mobile-header {
    display: none;
  }
  .web-header {
    display: block;
  }
  .mobile-app-install-banner {
    display: none;
  }
  .ddm-main.show {
    left: -150px !important;
  }
}

@media (max-width: 600px) {
  .mobile-header {
    display: block;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 10;
  }
  .web-header {
    display: none;
  }
  .input-style {
    width: 265px;
  }
  .ddm-main.show {
    top: 12px !important;
    left: -55px !important;
  }
  .ddm-lang.show {
    top: 12px !important;
    left: -57px !important;
  }
}
@media only screen and (min-width: 768px) {
  .mobile-header-search {
    margin-left: 8rem;
    margin-right: 8rem;
  }
}
@media only screen and (min-width: 820px) {
  .mobile-header-search {
    margin-left: 8rem;
    margin-right: 8rem;
  }
}
@media only screen and (min-width: 540px) {
  .mobile-header-search {
    margin-left: 5rem;
    margin-right: 5rem;
  }
}
.signupname {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: white;
  position: relative;
  top: -1px;
  left: 17px;
  font-family: "Times New Roman";
  width: 0%;
  text-transform: capitalize;
}
.dropdown-content {
  position: relative;
  /* z-index: 9999; */
}
.dropdown-content-menu {
  position: relative;
}
.btn-secondary {
  color: #fff;
  background-color: #364d49;
  font-family: "Playfair Display";
  text-transform: capitalize;
  letter-spacing: 0.5px;
  font-size: 18px;
  font-weight: 400;
  border-color: #364d49;
}
.btn-secondary:hover {
  background-color: #364d49;
  border-color: #364d49;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #364d49;
  border-color: #364d49;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.ddm-main.show {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: -150px;
  width: 250px;
  padding: 10px;
  transform: translate3d(42px, 89px, 0px);
}
.ddm-lang {
  width: 150px;
  left: -70px;
  padding: 10px;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.75rem 0rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 18px;
  text-transform: capitalize;
  font-family: "Playfair Display";
}
.signup{
  color: #FFF;
  font-family: Glacial Indifference;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 117px;
  height: 44px;
  padding: 10px 33px;
}

.login{
  display: flex;
  padding: 10px 33px;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 10px;
  background: #FFF;
  width: 117px;
  height: 44px;
  color: var(--Primary-color, #364D49);
  font-family: Glacial Indifference;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.header-btns{
  display: flex;
  align-items: center;
  grid-gap: 0px;
  gap: 0px;
}
.header-qty{
  color: #FFF;
  font-family: Glacial Indifference;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom_cart_icon{
  font-size: 20px;
  color: white;
  font-weight: 800;
}

.align-logo{
  text-align: -webkit-center;
  margin-top: 6px;
}

.cart_quantity{
  position: absolute;
  color: white;
  background-color: #222121;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: -54%;
    right: 27%;
    font-family: Glacial Indifference;
}


.cart_wrapper{
  transition: transform 0.3s ease;
}

.cart_wrapper.animate {
  transform: scale(1.2);
}

.margin-header-btns{
  margin-left: 35px;
}



.sidebar_header_container{
  position: absolute;
  left: 0%;
  z-index: 999999999999;
}

.menu-btn-container{
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-btn-container i {
  color: #364d49;
  font-size: 40px;
}

@media only screen and (min-width:500px)
{
  .cart_quantity{
    right: 44% !important;
  }
}

@media only screen and (min-width:500px) and (max-width:576px){
  .header_logo_custom{
    margin-left: 50px;
  }
}

@media only screen and (max-width:300px)
{
  .custom_cart_icon{
font-size: 14px !important;
  }

  .cart_quantity{
    width: 14px;
    height: 14px;
    font-size: 10px;
  }
}

.modalSettingBanner {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 32%;
  display: flex;
  background-color: white;
  box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 13px 0px, rgba(0, 0, 0, 0.08) 0px 3px 6px 0px;
  flex-direction: column;
  border-radius: 4pt;
  height: 30vh;
  position: relative;
  padding: 0px !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;

}

.react-responsive-modal-root{
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: fixed;
  z-index: 1051;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.5);
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-responsive-modal-container{
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: fixed;
  z-index: 1051;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.5);
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-body {
  padding: 2.5px !important;
  max-height: 91vh;
  /* overflow: auto; */
}
.modal-content {
  border-radius: 10px;
}
.cross-svg {
  width: 65%;
  position: relative;
  bottom: 26px;
}
body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}
.mdaalSetting .react-responsive-modal-closeButton {
  color: #fff;
  position: absolute;
    top: 14px;
    right: 45px !important;
    border: none;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    display: flex;
}
.product-modal-detail{
  padding-left: 30px;
}
.product-modal-name {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  margin-left: 3rem;
  color: #2d5c48;
  margin-top: 12px !important;
  position: relative;
  left: -35px;
  font-family: "Playfair Display";
}
.product-modal-price {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #2d5c48;
  margin-left: 3rem;
}

.product-modal-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: rgb(13, 37, 49);
  margin-left: 3rem;
  margin-right: 3rem;
  position: relative;
  left: -25px;
  font-family: "Playfair Display";
}
.modal-single-item {
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  /* line-height: 2px; */
  display: flex;
  align-items: center;
  /* color: #666666; */
  margin-left: 1.5rem;
  font-family: "Playfair Display";
}
.allerges-list
{
  display: flex;
  position: relative;
  left: -35px;
}

.product-modal-more {
  /* background-color: #f7f7f7; */
  /* border-radius: 24px; */
  /* margin-left: 20px; */
  position: relative;
  /* left: -35px; */
  padding-left: 10px;
}
.product-modal-more-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1a1a1a;
  margin-bottom: 0px;
}

.product-modal-body {
  /* margin-top: 20px; */
  padding: 0 30px 10px;
}
.product-modal-modal-index {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #666666;
  margin-left: 1.5rem;
}
.product-modal-special {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1a1a1a;
  margin: 0px;
  margin-left: 1rem;
}

.form-text {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 16px;
}

.cart-product-quantity {
  /* background-color: #2d5c48; */
  /* height: 20px; */
  width: 20px;
  /* border-radius: 100px; */
  /* padding-top: 2.5px; */
  /* padding-left: 6px; */
  color: #2d5c48 !important;
  position: relative;
  left: 18px;

  /* margin-top: 2px; */
}
.product-ingrediant {
  margin-left: 27px;
}
/* .cart-outer-quantity{

    padding: 8px 16px;
    grid-gap: 16px;
    grid-gap: 16px;
    gap: 16px;
    width: 111px;
    height: 41px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 100px;
 
} */

.cart-product-icons {
  display: flex;
  /* background-color: #fff; */
  /* width: 100%; */
  justify-content: space-between;
}
.multi-mod-quantity{
  padding: 2px 16px;
  grid-gap: 16px;
  width: 103px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 100px;
  display: inline-block;
  position: relative;
}
.cart-outer-quantitys {
  display: flex;
    flex-direction: row;
    padding: 16px;
    border-style: none;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    width: 120px;
    background: rgb(243, 244, 239);
    position: relative;
    top: 5px;
  /* padding: 6px 16px;
  grid-gap: 16px;
  width: 90%;
  height: 39px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 10px;
  display: inline-block;
  position: relative;
  left: 25px; */
}
.crt-btn {
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  background-color: #f7f7f7;
}
.react-responsive-modal-overlay
{
  position: absolute;
}
.modal-cart-btn {
  display: flex;
    flex-direction: row;
    padding: 16px 24px;
    border-style: none;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    width: 100%;
    background: #2f4e49;
    cursor: pointer;
  /* display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 8px 20px;
  gap: 8px;
  border: none;
cursor: pointer;
  height: 40px;
  background: #2d5c48;
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 25px;
  color: #1a1a1a; */
}

.product-ingrediant-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.modal-cart-outer-div {
  margin-top: 5px;
  width: 90%;
}
input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 5px 0px gray inset;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
input[type="radio"]:checked:before {
  background: black;
}
input[type="checkbox"] {
  /* margin: 0px;
  display: flex;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(139, 143, 161);
  box-sizing: border-box;
  border-radius: 4px;

  color: white;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 24px;
  margin-left: -20px;
  outline: none; */
  /* -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border: 1px solid darkgray;
  outline: none;
  padding-left: 2px;
  font-weight: bolder;
  box-shadow: 0 0 5px 0px grey inset;
  margin-top: 4px; */

  width: 24px;
  min-width: 24px;
  height: 24px;
  /* margin-bottom: 15px !important; */
}
input[type="checkbox"]:before {
  display: block;
  width: 60%;
}
input[type="checkbox"]:checked:after {
  content: "\2713";
  margin: 0px;
  display: flex;
  background: rgb(29, 78, 71);
  border: 1px solid rgb(29, 78, 71);
  box-sizing: border-box;
  border-radius: 4px;
  width: 24px;
  min-width: 24px;
  color: white;
  justify-content: center;
  align-items: center;
  height: 24px;
}
@media (min-width: 320px) and (max-width: 1920px) {
  .modalSetting {
    width: 35%;
    /* height:95vh; */
  }
}
@media (min-width: 320px) and (max-width: 1366px) {
  .modalSetting {
    width: 45%;
    /* height:95vh; */
  }
}
@media screen and (min-width: 768px){
  .modalSetting {
    width: 550px;
  }
  
}

    

@media (min-width: 320px) and (max-width: 767px) {
  .modalSetting {
    width: 95%;
    height:95vh;
  }
  .modal-cart-btn {
    margin-left: 10px !important;
    font-size: 16px;
  }
  /* .cart-outer-quantitys {
    width: 50%;
  } */
  .modal-cart-outer-div {
    width: 94%;
  }
  .product-modal-body {
    margin-top: 20px;
    padding: 0px 10px 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .modal-cart-btn {
    /* margin-left: 10px !important; */
    font-size: 16px;
  }
  .modal-cart-outer-div {
    width: 90%;
  }
}
@media (min-width: 768px) and (max-width: 912px) {
  .cart-outer-quantitys {
    width: 100%;
  }
  .modal-cart-outer-div {
    width: 100%;
  }
  .modal-cart-btn {
    /* margin-left: 10px !important; */
    font-size: 16px;
    
  }
  .modalSetting {
    width: 95%;
    height:95vh;
  }
}

@media(min-width:320px) and (max-width:500px)
{
  .shopping-cart-icon{
    display: none !important;
  }
  .modalSettingBanner {
    width: 100%;
  }
  .modal-cart-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: unset !important;
    grid-gap: 8px;
    grid-gap: 8px;
    gap: 8px;
    border: none;
    cursor: pointer;
    height: 40px;
    background: #2f4e49;
    border-radius: 100px;
    flex: none;
    order: 0;
    flex-grow: 1;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    /* margin-left: 25px; */
    color: #1a1a1a;
}
}
.react-responsive-modal-closeButton {
  
  position: absolute;
    top: 14px;
    right: 45px !important;
    border: none;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    display: flex;
}
.colored-toast.swal2-icon-success {
  background-color: #a5dc86 !important;
}
.cart {
  position: absolute;
  width: 27px;
  height: 41px;
  left: 1268px;
  top: 27px;
}

.disabled-btn-checkout{
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.7;
}

.time-cart-close{
  font-family:Glacial Indifference ;
}

.cart-summary {
  border-radius: 5px !important;
}
.myCart-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  color: #1a1a1a;
  position: relative;
  bottom: 22px;
}

.cart-product-name {
  color: var(--Primary-color, #364D49);
  font-family: Playfair Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  position: relative;
  left: 5px;
  height:25px;
  width: 180px;

}
.ct-items-div{
    font-family:'Playfair Display';
    overflow: auto;
    padding: 13px;
    height: calc(100vh - 190px);
    -webkit-overflow-scrolling: touch !important;
}
.cart-product-price {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  margin-right: 47px;
  color: #4d4d4d;
}

.cart-icons {
  display: flex;
  width: 88px;
  height: 31px;
  align-items: flex-start;
  position: relative;
  top: 10px;
  left: 5px;
}

.cart-product-main {
  display: flex;
  justify-content: space-between;
}

.cart-outer-quantity {
  position: relative;
  left: -10px;
  /* top: -1px; */
  padding: 2px;
  grid-gap: 16px;
  grid-gap: 16px;
  /* gap: 16px; */
  width: 111px !important;
  height: 31px;
  background: #ffffff;
  /* border: 1px solid #cccccc; */
  border-radius: 8px;
}
.cart-checkout {
  color: #FFF;
font-family: Glacial Indifference;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: initial;
}

.cart-cont {
  color: var(--Neutral-color, #212524);
    font-family: Glacial Indifference;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    width: 30px;
    padding: 6px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.cart-item {
  margin-top: -13px;
}

.cart-extra {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
  margin: auto;
}

.cart-extra:hover {
  color: #666666;
}

.sidebar-menu {
  position: fixed;
  right: -4px;
  top: -5px;
  z-index: 1100;
  width: 503px;
  height: 100%;
  overflow: auto;
  transition: all 0.5s ease 0s;
  transform: translateX(0);
  -webkit-transition: 0.5s ease all;
  background: #FFF;
  padding: 0rem;
}

/* .open {
  -webkit-transform: translateX(350px);
  transform: translateX(350px);
} */

.cart-upper-heading {
  display: inline-flex;
  height: 100px;
  padding: 52px 24px 16px 0px;
  /* padding: 52px 24px 16px 27px; */
  justify-content: center;
  align-items: flex-end;
  grid-gap: 285px;
  gap: 285px;
  flex-shrink: 0;
  width: 100%;
  border-bottom: 1px solid var(--neutral-neutral-100, #C0C1C3);
  background: #FFF;
}

.cart-heading {
  color: var(--Primary-color, #364D49);
  font-family: Playfair Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cart-cancel-btn {
  right: 20px !important;
  color: #413434;
  font-family: Glacial Indifference;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;

  }

.cart-order-list {
  margin-top: 1rem;
  margin-bottom: 1rem;
  /* max-height: 350px; */
  /* overflow: auto; */
}

.cart-coupon {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #2d5c48;
  margin: auto;
  font-family: "Playfair Display";
  display: flex;
  align-items: center;
}

.cart-coupon-apply {
  width: auto !important;
}

.form-inner {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 1rem;
  padding: 1.5rem;
  transition: all 0.3s;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  background-color: #fff;
  color: #666666;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
  margin: auto;
}

.form-inner:focus {
  border: 1px solid red !important;
}

.cart-apply-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  grid-gap: 4px;
  grid-gap: 4px;
  gap: 4px;
  width: 61px;
  height: 32px;
  background: #364d49;
  border-radius: 5px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 12.8px;
  line-height: 20px;
  color: white;
  border: none;
  position: relative;
  top: -40px;
  left: 292px;
  font-family: "Playfair Display";
}

.cart-subtotal {
  display: flex;
  justify-content: space-between;
  /* margin: 0.5rem; */
}

.cart-single-item-left {
  color: var(--Neutral-color, #212524);
  font-family: Playfair Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.cart-single-item-right {
  color: var(--Neutral-color, #364D49);
  font-family: Glacial Indifference;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cart-quantity-total {
  background-color: #818f71;
  width: 30px;
  border-radius: 100px;
  padding-top: 6px;
  color: #fff !important;
  height: 30px;
  margin-top: 3px !important;
  margin-left: 5px !important;
}
.cart-shopping {
  color: black !important;
}
.cart-checkout {
  color: white !important;
}
.product-modal-special {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1a1a1a;
  margin: 0px;
  margin-left: 1rem;
}

.product-modal-more-optional {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #666666;
}

.special-ins {
  font-style: normal;
  font-weight: 400;
  max-height: 100px !important;
  font-size: 15px;
  border-radius: 5px;
  line-height: 10px;
  color: #666666;
  width: 455px;
  height: 118px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid var(--grey-grey-100, #BABBBB);
  background: #FFF;
  line-height: normal;
  }

.cart-buttons {
  display: flex;
  width: 458px;
  padding: 16px 125px;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 14px;
  background: var(--Primary-color, #364D49);
  height: 54px;
  color: #FFF;
  font-family: Glacial Indifference;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.final-price {
  color: rgb(255, 255, 255) !important;
  margin-top: 10px !important;
  margin-right: 7px !important;
  font-size: 14px !important;
}

@media (min-width: 320px) and (max-width: 767px) {
  .cart-apply-btn {
    top: -38px;
    left: 251px;
  }
}
.cart-shopping {
  padding-top: 4px;

  height: 32px;
  margin-top: 5px;
  font-size: 16px;
  /* margin-bottom: -15px; */
  width: 71px !important;
  margin-left: -45px !important;
}
.cart-shopping svg {
  font-size: 20px;
  overflow: hidden;
  vertical-align: unset !important;
  color: white;
}
#mbl-cart-model-close-btn{
  position: absolute;
  top: 3px;
  right: 1px !important;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
}
@media (min-width: 320px) and (max-width: 480px) {
  .sidebar-menu {
    position: fixed;
    top: -5px;
    z-index: 1100;
    width: -webkit-fill-available;
    height: 100%;
    overflow: auto;
    transition: all 0.5s ease 0s;
    transform: translateX(0);
    -webkit-transition: 0.5s ease all;
    background: #f7f7f7;
    padding: 1.5rem;
  }
  .open {
    transform: translateX(0px);
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  /* .open {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  } */
}
@media (min-width: 768px) and (max-width: 1024px) {

}
/* @media (min-width: 1024px) and (max-width: 1280px) {
  .open {
    -webkit-transform: translateX(0px) !important;
    transform: translateX(0px) !important;
  }
} */

.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: 1.5rem;
}


.cart-image{
  display: flex;
  width: 64px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.cart-choice-qty{
  color: var(--Primary-color, #364D49);
  font-family: Glacial Indifference;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  left: 52px;
  top: 19px;
  white-space: nowrap; 
}



.product-price{
  color: var(--Primary-color, #364D49);
  font-family: Glacial Indifference;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: end;
  }

  .left-icon{
    display: flex;
    width: 29px;
    height: 31px;
    padding: 9px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
  }

.right-icon{
  margin-top: 0px;
  cursor: pointer;
  display: flex;
  width: 29px;
  height: 31px;
  padding: 9px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.od{
  color: var(--Primary-color, #364D49);
  font-family: Playfair Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.spi{
  color: var(--Neutral-color, #212524);
  font-family: Playfair Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 8px;
  grid-gap: 10px;
  gap: 10px;
}

.optional{
  color: var(--Neutral-color, #212524);
  font-family: Playfair Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
  right: 240px;
  top: 3px;
}

.opt-value{
  color: var(--Neutral-color, #212524);
  font-family: Playfair Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
 margin-top: 5px;
}

#asap{
  display: flex;
  width: 455px;
  height: 44px;
  padding: 1px 8px 1px 18px;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 257px;
  gap: 257px;
  flex-shrink: 0;
  border-radius: 6px;
border: 1px solid var(--grey-grey-100, #BABBBB);
  background: #FFF;
  font-family: Glacial Indifference;
}

#asap .select {
  padding-right: 20px;
}

.cusomt_Input{
  width: 455px;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(119, 119, 119);
  border: 1px solid var(--grey-grey-100, #BABBBB);
  padding: 0px 18px;
  position: relative;
  border-radius: 6px;
 
}

.custom_dropDown{
  position: absolute;
  background: white;
  width: 455px;
  z-index: 999999;
  height: 200px;
  overflow-y: auto;
  padding: 0px 18px;
  border-radius: 6px;
  border: 1px solid gainsboro;
  border-radius: 10px;
  font-family: Glacial Indifference;
  padding-top: 12px;

}

.custom_dropDown::-webkit-scrollbar {
  width: 8px; 
}

.custom_dropDown::-webkit-scrollbar-thumb {
  background-color: #364D49; 
  border-radius: 6px; 
}

.custom_dropDown::-webkit-scrollbar-track {
  background-color: #ffffff; 
}

.cusomt_Input span{
   font-family: Glacial Indifference;
}

.custom_dropDown div {
  cursor: pointer;
   font-family: Glacial Indifference;
}


.divider{
  height: 1px;
  background: var(--grey-grey-100, #BABBBB);
}
.chk-out-btn{
  bottom: 0px;
  display: flex;
  padding: 18px 22px;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  background: #FFF;
  box-shadow: 0px -6px 18px 0px rgba(147, 142, 142, 0.25);
  width: auto;
  position: relative;
  top: 0px;
}


.basket-icon{
  margin-top: 170px;
  position: relative;
  left: 0px;
  height: 133px;
}

.empty-cart{
  color: #2F2F2F;
  text-align: center;
  font-family: Playfair Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 90px;
}

.empty-cart-content{
  color: #000;
  text-align: center;
  font-family: Glacial Indifference;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.57;
  margin-top: 20px;
}

.empty-ct-items-div{
  font-family: 'Playfair Display';
    overflow: auto;
    padding: 13px;
    height: calc(100vh - 190px);
}

@media only screen and (min-width:1500px)
{
  .table_number{
    width: 455px !important;
    font-family: Glacial Indifference !important;
  }
  .optional{
right: 240px !important;
  }
}

.table-input{
  padding: 20px 10px !important;
}


@media only screen and (min-width:530px) and (max-width:576px)
{
  .optional{
    right: 55%;
  }

  .cart-icons{
    left: 7px;
  }
}

@media only screen and (max-width:500px)
{
  .optional{
    right: 68px;
      }

      .cart-icons{
        left: -39px;
      }
}

@media only screen and  (max-width:600px)
{
  .custom_inputs{
    width: 100% !important;
  }

  .cusomt_Input{
     width: 100% !important;
  }

  .custom_dropDown{
    width: 94% !important;
  }

  .cart-buttons{
    width: 100% !important;
  }

  #asap{
    width: 100%  !important;
    
  }
  /* .cart-icons{
    left: -24px;
  } */

  .ct-items-div{
    overflow-x: hidden !important;
    -webkit-overflow-scrolling: touch !important;
  }

  /* .optional{
right: 68px;
  } */

  .product-price{
font-size: 16px !important;
  }

  .od{
    font-size: 16px;
  }

  .cart-single-item-left{
    font-size: 14px;
  }

  .spi{
    font-size: 14px;
  }

  .cart-single-item-right{
    font-size: 14px;
  }
  .cart-checkout{
    font-size: 16px;
  }
  

}

@media only screen and (max-width:380px)
{
   .cart-icons{
    left: -39px;
  }
}

@media only screen and (max-with:350px){
  .optional{
    right: 148px !important;
  }
}


@media only screen and (max-width:300px)
{
  .od{
font-size: 12px !important;
  }

  .cart-single-item-left {
    font-size: 11px !important;
  }
  .spi{
    font-size: 12px;
  }

  .optional{
    right: 7px;
    font-size: 10px;
    top: 1px;
  }

  .cart-checkout{
    font-size: 12px;
  }

  .custom_dropDown{
    left: -1%;
    height: 160px;
  }
}


.text-cart-line{
  white-space: nowrap;
}

.start_time_text{
  font-weight: 800;
  font-family: Glacial Indifference Bold;
  display: block;
}

.border-custom{
  border: 1px solid  #436B64 !important;
  box-shadow: 0px 3px 12px 0px rgba(23, 158, 132, 0.13);
  background-color: white;
}

.done_button{
  display: flex;
height: 47px;
padding: 16px 125px;
justify-content: center;
align-items: center;
grid-gap: 10px;
gap: 10px;
border-radius: 14px;
border: 1px solid var(--Primary-color, #2F4E48);
background: var(--Primary-color, #2F4E48);
color: #FFF;

font-family: Glacial Indifference;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.sorry_text{
  color: #212524;
width: 70%;
text-align: center;
font-family: Playfair Display;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 140%;
}

.time-Selector{
  padding: 2px 10px;
  font-family: Glacial Indifference;
}

.time-Selector:hover{
  background-color: #e2e2e2;
}


@media only screen and (max-width:600px){

  .modal_container{
    height: 90vh;
    width: 100vw !important;
  }

  .customModal-mobile{
    height: 92vh !important;
    margin: 0px;
    padding: 10px 0px !important;
    overflow: hidden !important;
  }

  .ct-items-div{
   height: 75vh; 
   margin-top: 25px;
   margin-left: -10px;
   overflow-y: auto !important;
   overflow-x: hidden !important;
   scroll-behavior: smooth;
   -webkit-overflow-scrolling: touch !important;
  }

  .ct-items-div * {
    transform: translate3d(0, 0, 0);
  }
  .empty-ct-items-div{
    height: 80vh;
  }

  .chk-out-btn{
    padding: 18px 22px !important;
    width: 100%;
    position: fixed;
   top: 90%;
   height: 80px;
   left: 0px;
  }

  .cart-upper-heading{
    grid-gap: 202px !important;
    gap: 202px !important;
    display: flex !important;
    justify-content: space-around !important;
    height: 50px !important;
    width: 100vw !important;
    margin-left: -7px !important;
  }

  .sidebar-menu{
    padding: 0px 1.5rem !important;
    top: 0px !important;
    left: 0% !important;
    overflow: hidden !important;
    background-color: white;
    display: none !important;
  }

  .line_mobile_Cart{
    border-top: 5px solid gray;
    
  }
  .cart-heading{
    font-size: 18px;
  }
  .cart-icons{
    left: -16px !important;
  }

  #mbl-cart-model-close-btn{
    display: none !important;
  }

  .font_close_mobile{
    font-size: 25px;
    color: #000;
    margin-left: 8px;
    margin-top: 5px;
  }

  .header_mbl_Cart{
    align-items: center;
    /* display: none !important; */
    position: fixed;
    background-color: rgb(255, 255, 255);
    top: 0%;
    width: 100%;
    padding-bottom: 10px;
    height: 50px;
    z-index: 7800;
    box-shadow: 0px 2px 8px 0px rgba(192, 191, 191, 0.25);
  }

  .opt-value{
margin-top: 2px;
  }

  .cart-cancel-btn{
    font-size: 18px;
  }

}

@media only screen and (max-width:360px)
{
  .cart-upper-heading{
    grid-gap: 180px !important;
    gap: 180px !important;
    height: 50px !important;
    width: 100% !important;
    margin-left: 0px !important;
  }

  .cart-heading{
    font-size: 16px;
  }

  .cart-cancel-btn{
    font-size: 16px;
  }

  .ct-items-div{
    height: 70vh; 
    margin-top: 12px;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch !important;
   }
}

@media only screen and (max-width:380px){
  .optional{
    right: 148px !important;
  }
}
.mode-button {
    cursor: pointer;
    font-size: 14px;
    background: none;
    border-radius: 4px;
    padding: 5px 10px;
    border: 1px solid #364d49;
    color: var(--Neutral-color, #212524);
    font-family: Glacial Indifference;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.mode-button:disabled {
    font-weight: bold;
    background: #364d49;
    color: #fff;
    font-family: Glacial Indifference;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.md-language-bar {
    display: flex;
    text-align: left;
    align-items: center;
}
.lb-p {
    font-size: 14px;
    color: #364d49 !important;
}

.md-container {
    max-width: 149px;
    text-align: right;
    position: relative;
    top: -5px;
}

@media only screen and (max-width:600px){
    .mode-button:disabled{
        font-size: 14px;
      }

      .mode-button{
        font-size: 14px;
      }
}

@media only screen and (min-width:502px) and (max-width:600px)
{
    .md-container {
        right: 5%;
        } 
}

@media only screen and (min-width:385px) and (max-width:500px)
{
    .md-container {
right: 80%;
}
}


@media only screen and (min-width:385px) and (max-width:405px)
{
.md-container {
right: 97%;
}
} 


@media only screen and (min-width:320px) and (max-width:380px)
{
    .md-container {
right: 110%;
}
}

@media only screen and (max-width:300px)
{
    .md-container{
        right: 250% !important;
    }

    .mode-button{
        font-size: 10px !important;
    }
}

.container-sidebar{
z-index: 999999999;
}

.icon-container{
    background-color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: relative; */
}




.sideBar-container{
    background-color: white;
    width: 322px;
    height: 100vh;
    /* position: absolute;
    left: -340%;
    top: -60%; */
    z-index: 9999999999;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 16px 24px;
}

.top_bar{
    display: flex;
    justify-content: space-between;
    padding-top: 53px;
    padding-left: 20px;
    padding-right: 10px;
    padding-bottom: 15px;
}

.signIn-btn{

background-color: #364D49;
border: none;
padding: 8px 24px ;
border-radius: 10px;
color: white;
font-family: Glacial Indifference;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-left: 7px;
}

.signIn-btn:hover{
    color: #FFF;
}

.sign-btn{
    color: #364D49;
    font-family: Glacial Indifference;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background-color: transparent;
}

.sign-btn:hover{
    color: #364D49;
}

.close-sidebar{
    color: var(--Neutral-color, #212524);
    font-family: Glacial Indifference;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background-color: transparent;
    cursor: pointer;
}


.content_wrapper_link{
    padding: 10px 16px;
}

.content_left_text{
    color: var(--neutral-neutral-200, #A2A3A5);
    font-family: Glacial Indifference;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.content_text_right:hover{
    color: var(--neutral-neutral-200, #A2A3A5) !important;
}

.content_text_right{
    color: var(--neutral-neutral-200, #A2A3A5);
font-family: Glacial Indifference;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
-webkit-text-decoration-line: underline;
        text-decoration-line: underline;
}

.content-left-active{
    color: var(--Neutral-color, #212524);
font-family: Glacial Indifference;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.content_right_active{
    color: var(--Primary-color, #364D49);
    font-family: Glacial Indifference;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    cursor: pointer;
}

.content-right-disable{
    color: var(--Primary-color, white);
    font-family: Glacial Indifference;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    cursor: not-allowed;
    pointer-events: none;
}

.content-right-disable:hover{
    color: white;
}

.content_right_active:hover{
    color: var(--Primary-color, #364D49) !important;
}

.logout_text{
    color: #000;
cursor: pointer;
font-family: Glacial Indifference;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.logout_icon{
    color: #364D49;
    font-weight: 800;
    font-size: 20px;
}

.app-header{
    color: #000;

    font-family: Glacial Indifference Bold;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;  
}

.footer_sidebar{
    position: absolute;
    bottom: 0%;
    padding: 0px 15px;
}
.footer_sidebar span{
    color: #000;

font-family: Glacial Indifference;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.special-sidebar-image{
    width: 120px !important;
}



.disabled{
    pointer-events: none;
    cursor: not-allowed;
}
.profile_container{
    background-color: #364D49;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile_icons{
    color: white !important;
}

.user_name{
    color: var(--Primary-color, #364D49);
    font-family: Glacial Indifference;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.user_email{
    color: var(--grey-grey-200, #989B9A);
    font-family: Glacial Indifference;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.close-btn-modal{
    position: absolute;
    right: 18px;
    top: 5%;
    background-color: #364D49;
    color: white;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

.close-btn-modal-open-hours{
    position: absolute;
    right: 18px;
    top: 2%;
    background-color: #364D49;
    color: white;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}



.hour-title{
    color: #364D49;
    padding-top:10px;
    padding-bottom: 0px;
font-family: Playfair Display;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.custom-hours-padding{
    padding: 10px 0px;
}

.hr-line-modal{
    padding-bottom: 5px;
    margin-top: -10px;
    color: #c7c7c7;
    border-top: 1px solid rgb(202, 202, 202);
    width: 500px;
    margin-left: -13.5%;
}

.hr-line-modal-contact{
    padding-bottom: 5px;
    margin-top: -10px;
    color: #222121;
    border-top: 1px solid rgb(202, 202, 202);
    width: 560px;
    margin-left: -7%;
}

.hr-line-modal-contact-down{
    margin-top: 30px;
    color: #222121;
    border-top: 1px solid rgb(97, 96, 96);
    width: 504px;
    margin-bottom: 10px;
}

.cookeies-button{
    display: flex;
height: 36px;
padding: 8px 16px;
flex-direction: column;
justify-content: center;
align-items: center;
grid-gap: 4px;
gap: 4px;
flex: 1 0;
border-radius: 10px;
background: #364D49;
color: #FFF;
cursor: pointer;
box-shadow: none;
border: none;
font-family: Glacial Indifference;
font-size: 14px;
font-style: normal;
font-weight: 700;
letter-spacing: 1px;
line-height: 24px;
}

.cookeies-text{
    color: #605A5A;

font-family: Glacial Indifference;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}

.button_gap{
    grid-gap: 4px;
    gap: 4px;
    margin-left: -19px;
}

.buttonlogoutgap{
    margin-left: -8px;
    grid-gap: 20px;
    gap: 20px;
}

.lang-reset{
    display: flex;
    width: 150px;
    height: 44px;
    padding: 10px 80px;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    border-radius: 14px;
border: 1px solid var(--Neutral-color, #212524);
color: var(--Neutral-color, #212524);
font-family: Glacial Indifference;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
background-color: white;
}


.lang-save{
    display: flex;
    width: 150px;
    height: 44px;
    padding: 10px 80px;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    border-radius: 14px;
background: var(--primary-primary-500, #364D49);
color: #FFF;
font-family: Glacial Indifference;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
border: none;
}

.lang-text{
color: var(--Neutral-color, #212524);
font-family: Playfair Display;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.radio-lang{
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid black; /* Black border */
    background-color: white; /* White background */
    margin-right: 8px;
    vertical-align: middle;
    cursor: pointer;
}

.lang-subheading{
    color: var(--Neutral-color, #212524);
font-family: Playfair Display;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left: -17px;
}

.lang-heading{
    margin-left: -17px;
    color: var(--Neutral-color, #212524);
font-family: Playfair Display;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.logout-text{
    color: var(--Neutral-color, #212524);
text-align: center;
font-family: Playfair Display;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 140%;
}

.lang-reset-specail{
    display: flex;
width: 200px;
padding: 16px 90px;
justify-content: center;
align-items: center;
grid-gap: 10px;
gap: 10px;
border-radius: 14px;
border: 1px solid var(--Neutral-color, #212524);
color: var(--Neutral-color, #212524);
font-family: Glacial Indifference;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
height: 50px;
background-color: white;
}


.lang-save-special{
    height: 50px;
    display: flex;
width: 200px;
padding: 16px 90px;
justify-content: center;
align-items: center;
grid-gap: 10px;
gap: 10px;
border-radius: 14px;
background: var(--primary-primary-500, #364D49);
color: #FFF;
font-family: Glacial Indifference;
font-size: 18px;
font-style: normal;
font-weight: 700;
border: none;
line-height: normal;
}



@media only screen and (min-width:766px) and (max-width:991px)
{
    .sideBar-container{
        left: 0%;
    }

    .container-sidebar{

        /* margin-top: 0px !important */
        }
}

.hours-text-active-day{
    color: var(--Neutral-color, #212524);
font-family: Playfair Display;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.hours-text-active-hour{
    color: var(--Neutral-color, #212524);
    font-family: Glacial Indifference;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.hour-text-days{
    color: #000;
    font-family: Playfair Display;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.modal_scroll{
    overflow-y: scroll;
}

.hours-text-time{
    color: #000;

    font-family: Glacial Indifference;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media only screen and (max-width:600px)
{

    .lang-save-special{
        width: 130px;
    padding: 16px 63px;
    }

    .lang-reset-specail{
        width: 130px;
    padding: 16px 63px;
    }

    .sideBar-container{
        left: 0%;
        background-color: white;
        width:100vw;
        height: 100vh;
        position: absolute;
        z-index: 99999;
        top: 0%;
        right: 0%;
    }

    .content_wrapper_link{
        margin-bottom: -5px;
    }

    .footer_sidebar{
bottom: 1%;
    }

    .buttonlogoutgap{
        margin-left: -20px;
        grid-gap: 8px;
        gap: 8px;
    }

    .hr-line-modal{
        width: 350px;
        margin-left: -21%;
    }

    .hr-line-modal-contact{
        width: 357px;
        margin-left: -11%;
    }
    .hr-line-modal-contact-down{
        width: 300px;
    }
    .hour-title{
        font-size: 20px;
    }

    .special-sidebar-image{
        width: 100px !important;
    }

    .address_mobile{
        width: 55%;
    }
}


@media only screen and (max-width:300px)
{
    .hours-text-active-hour{
        font-size: 16px;
    }

    .hours-text-active-day{
        font-size: 16px;
    }

    .hour-text-days{
        font-size: 14px;
    }

    .hours-text-time{
        font-size: 14px;
    }

    .css-k8k6pr{
        width: 280px !important;
    }

    .css-s2gilp{
        width: 280px !important;
    }

    .ml-custom{
        margin-left: -4px !important;
    }
}
.header-close{
    background: #ECEEEE;
    margin-top: 0%;
    position: relative;
    width: 100%;
    padding: 5px 0px;
    height: 50px;
}


.header-close span{
    color: #364D49;
font-family: Glacial Indifference;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.digits-fonts{
    font-family: Glacial Indifference Bold !important;
  }

@media only screen and (min-width:766px) and (max-width:991px)
{
    .header-close span{
        font-size: 20px;
    }
}


@media only screen and (max-width:600px)
{
    .header-close span{
        font-size: 14px;
        padding: 10px;
    }
}
.widget-title {
    color: whitesmoke !important;
    text-transform: none !important;
    font-weight: 650 !important;
    font-size: 20px !important;
    padding-bottom: 20px;
    font-family: "Playfair Display";
    /* text-align: center !important;
    margin-left: 40% !important; */
}
.web-footer {
    /* position: fixed; */
    width: 100%;
    bottom: 0px;
}

.footer .social-icon:not(:hover):not(:active):not(:focus) {
    background-color: transparent;
    border-color: #dbdcdd;
    color: rgb(255, 255, 255);
}

.header-logo-styling {
    max-width: 8em;
    /* border-radius: 15px; */
    margin: auto;
    text-align: center;
    margin-left: 53px;
}

.footer .widget-newsletter p {
    margin-bottom: 1.3rem;
    line-height: 13.846;
}

.qouteline {
    padding-top: 29px;
    padding-left: 50px;
    text-align: unset !important;
}

.contact-info ul li {
    padding-bottom: 20px;
    font-size: 16px;
}

.container {
    width: 100%;
}

.social-icons {
    text-align: center;
}

.linkin-social {
    background-color: transparent;
    border-color: #313438;
    color: #000;
    font-family: "porto";
    font-style: normal;
    font-weight: normal;
    /* speak: none; */
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    margin-left: 1.5rem;
    border: 1px solid;
    width: 36px;
    border-radius: 24px;
    height: 36px;
    margin-left: -176px;
    padding: 9px;
}

.social-linkedin-in {
    margin-left: -180px;

}

.linkin-icon {
    margin-top: 20px;
    color: black;
}

.linkin-social a :active {
    color: white !important
}

.footer .linkin-social+.linkin-social {
    margin-left: 1.3rem;
}

.footer-content {
    padding: 0rem 0 0rem;
}

.text-h-footer{
    color: whitesmoke;
}


.footer{
    background-color: #364d49;
    /* position: relative; */
    z-index: 2;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

.contact-info i{
    background-color: #364D49;
    display: flex;
    -ms-flex-align: center;
    /* align-items: center; */
    justify-content: center;
    width: 4.3rem;
    height: 4.3rem;
    float: left;
    border-radius: 0;
    color: #fff;
    font-size: 1.6rem;
    position: relative;
    text-align: center;
    top: -10px;
}

.footer-row{
    position: relative;
    top: 45px;
    /* left:55px */
}
.mobile-footer {
    display: none;
}
.contact-row {
font-size: 14px;
color: #fff;
}
.contact-row ul li {
    padding: 5px;
}
.policy-row ul li {
    padding: 5px;
}
.policy-row {
    font-size: 14px;
    color: #fff;
}
.powered-by {
    font-size: 14px;
    color: #fff;
}

@media (max-width: 600px) {
    .web-footer {
        display: none;
    }
    .mobile-footer {
        display: block;
        background-color: #364d49;
        padding: 15px 3px;
    }
}

.hover_link:hover {
color: #fff !important;
}


@media only screen and (max-width:600px)
{
   
.transparent-white-icon {
  background: transparent;
  color: white;
  border: 1px solid white;
  width: 30px ;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-radius: 50%;
  margin: 0px 3px;
}

.footer_address span{
    color: white;
    margin-top: 15px !important ;
    font-family: Glacial Indifference;
    font-weight: 200;
}
.link_footer a{
    color: white;
    text-decoration: none;
font-family: Glacial Indifference;
}
.padding_custom_footer{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 8px;
    padding-right: 8px;
}
}

.web-footer{
    margin-top: auto;
}

@media only screen and (min-width:600px) and (max-width:991px)
{
    .main_footer {
    background-color: #2f4e49;
    width: 100%;
    padding: 10px;
    display: block;
}
}


.main_footer {
    background-color: #2f4e49;
    width: 100%;
    padding: 15px 10px;
}


.transparent-white-icon {
  background: transparent;
  color: white;
  border: 1px solid white;
  width: 30px ;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-radius: 50%;
  margin: 0px 10px;
}

.footer_address span{
    color: white;
    margin-top: 15px !important ;
    font-family: Glacial Indifference;
    font-weight: 200;
}
.link_footer a{
    color: white;
    text-decoration: none;
font-family: Glacial Indifference;
}
.padding_custom_footer{
    
    padding-bottom: 10px;
    padding-left: 8px;
    padding-right: 8px;
}


@media only screen and (max-width:300px)
{
    .logo_footer_custom{
        width: 120px !important;
    }
    .footer_logo img {
        width: 130px !important;
    }

    .transparent-white-icon{
        font-size: 12px;
        height: 20px;
        width: 20px;
    }
    .link_footer a{
        font-size: 10px;
    }
    .copy_right_font{
        font-size: 10px !important;
    }
    .padding_custom_footer{
        font-size: 10px;
    }
}
.subtitle{
    text-align: center;
    font-family: "Playfair Display";
}
.image{
    width: 100%;
    align-items: center;
    margin-top: 7px;
    border-radius: 24px;
    filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.08));
    /* margin-left: 10px; */
}
.center {
    display: flex;
    align-items: center;
    justify-content: center;};

                
.about-details{
    width: 50% !important;
    margin: auto;
    text-align: justify;
    margin-top: 3rem;
}
h5{
    font-size: 15px;
    padding-top: 14px
}
.custom-ul{
    padding-left: 50;
}
.p-3 {
    padding: 10px 16px;
}
h4 {
    display: flex;
    flex-direction: row;
  }
  
  .pp:before {
    content: "";
    border-bottom: 1px solid #E6E6E6;
    ;
    /* margin: auto; */
    margin-bottom: 9px;
  }
  
  .pp:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #E6E6E6;
    ;
    /* margin: auto; */
    margin-bottom: 9px;
  }
  .profilepic{
    border-radius: 51px !important;
    border: none !important;
    height: 57px !important;
    /* width: 60px !important; */
    background-color: white;
  }
  .img {
    display: block;
    height: auto;
}
.font-weight-light {
  font-family: Roboto Condensed;
  color: #666666;
  font-weight: 400 !important;
  font-size: 12.8px !important;
  cursor: pointer;
}

.h5{
  font-size: 20px;
  font-weight: 800!important;
}
#roundb {
  background-color: #345C59;
  width: 100%;
  border: none;
  border-radius: 700px;
  height: 5rem;
  cursor: pointer;
}
@media (max-width: 600px) {
  .profile-mobile-div {
    margin-top: 80px !important;
  }
  
.custom_profile_margin{
  margin-top: 4%;
  }
}

.signup-btn {
  width: 102%;
  margin-left: -10px !important;
  border-radius: 5px;
  margin-top: 2rem;
  margin-bottom: 1rem;
  background: #364d49 !important;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 9px;
  text-align: center;
  display: flex;
  justify-content: center;
  display: flex;
  align-items: center;
  text-transform: capitalize !important;
  color: white;
  font-family: "Playfair Display";
}
.form-footer .btn{
  margin-right: 0px !important;
}


@media only screen and (min-width:766px) and (max-width:991px)
{
  .custom_profile_margin{
    margin-top: 15% !important;
    }
}

.custom_profile_margin{
  margin-top: 12%;
  }

.input {
  border-radius: 23px !important;
  border-width: 0 0 2px;
  border-color: blue;
}

.forget-pass {
  transition: all 0.3s;
  text-decoration: underline;
  color: var(--Primary-color, #364D49);
  font-family: Playfair Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.forget-pass:hover
{
  color: #364D49;
}
.login-screen-parent-div {
  width: 40%;
  background: #fff;
  margin: auto;
}

.kep-login-facebook {
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  color: black;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  background-color: white;
  border: none;
  border-radius: 24px;
}

.kep-login-facebook.metro {
  width: 100% !important;
  height: 58px;
  border-radius: 5px !important;
  display: flex;

  text-transform: capitalize;
  background-color: #f5f5f5;
  color: black;
  border: none;
  font-weight: 700;
  /* padding: 10px 10px 10px 0px; */
}
.kep-login-facebook {
    font-family: unset !important;
    font-weight: 500 !important;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: calc(.27548vw + 12.71074px);
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .3s,border-color .3s;
    background-color: #4c69ba;
    border: calc(0.06887vw + 0.67769px) solid #4c69ba;
    padding: calc(0.34435vw + 13.38843px) calc(0.34435vw + 18.38843px);
}

#roundb {
  background-color: #345C59;
  width: 100%;
  border: none;
  border-radius: 700px;
  height: 5rem;
}

.p {
  display: flex !important;
  justify-content: center !important;
  color: #666666;
}

.hideicon {
  padding-left: 83% !important;
  margin-top: -0.2%;
  position: absolute !important;
  font-size: 24px !important;
}

h4 {
  display: flex;
  flex-direction: row;
  font-family: "Times New Roman";
}

.pp:before {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #e6e6e6;
  /* margin: auto; */
  margin-left: 66px;
  margin-bottom: 9px;
  margin-right: 11px;
}

.pp:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #e6e6e6;
  /* margin: auto; */
  margin-right: 63px;
  margin-bottom: 9px;
  margin-left: 11px;
}

.googlefbbtn {
  margin-left: 11px;
  width: 134px;
  border-radius: 24px;
  border: none;
  height: 57px !important;
}

/* .text-danger{
  color: red;
} */
.privacy {
  justify-content: center;
  color: red;
  display: flex;
  font-weight: 500;
  margin-top: -23px;
}

.sl-hds {
  font-size: 24px;
  text-transform: uppercase;
  color: #000;
  margin: 0 0 15px;
  font-family: "Trade Gothic LT Std Bold" !important;
  letter-spacing: normal;
}

.cutom-top-margin{
  padding: 7% 7% !important;
  
}

@media only screen and (min-width: 768px) {
  h2 {
    font-size: 24px;
    letter-spacing: -1px;
  }
}

.myButton {
  width: 100%;
}

.myButton:hover {
  background: linear-gradient(to bottom, #e4685d 5%, #fc8d83 100%);
  background-color: #e4685d;
}

.myButton:active {
  position: relative;
  top: 1px;
}

.lgor {
  font-family: "open_sansregular", Arial, Helvetica, sans-serif !important;
  text-align: center;
  font-size: 15px;
  margin: 0 0 20px;
  border-bottom: 1px dotted #c7c7c7;
  width: 80%;
  display: table;
  font-style: normal;
}

.kep-login-facebook.metro {
  /* width: 100% !important; */
  height: 58px;
  border-radius: 5px !important;
  font-size: 14px;
  text-transform: capitalize;
  background-color: #efefef !important;
  color: black;
  border: none;
  /* padding: 10px 10px 10px 0px; */
}



.login-btn {
  display: flex;
  height: 54px;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  flex: 1 0;
  border-radius: 8px;
  border: 1px solid var(--Primary-color, #364D49);
  background: var(--Primary-color, #364D49);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.lgn-signup-btn-1{
  color: var(--Primary-color, #364D49);
    font-family: Glacial Indifference;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    padding: 6px 12px;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    line-height: 140%;
    text-transform: capitalize;
    border-radius: 8px;
    background: var(--primary-primary-50, #EBEDED);
}

#roundb {
  border-radius: 10px;
}

.hellogoogle {
  width: 100%;
  height: 56px;
  color: black !important;
  border-radius: 5px !important;
  background-color: #efefef !important;
  align-items: center !important;
  border: none;
  padding: 9px !important;
  font-size: 14px !important;
  /* margin-left: 28px; */
  font-weight: 700 !important;
  font-family: unset !important;
}

.hellogoogle div {
  background-color:transparent !important;
}

button.hellogoogle{
  /* box-shadow: unset !important; */
  font-weight: 700 !important;
}

element.style {
  text-align: center;
}

.cart-button {
  background-color: #c41000 !important;
  color: #fff !important;
  border-color: #fff !important;
}

.react-tel-input .form-control {
  border: 1px solid gray !important;
  border-radius: 5px !important;
  width: 100% !important;
}

.react-tel-input .flag-dropdown {
  border: 1px solid gray !important;
  border-radius: 10px 0 0 10px !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus .react-tel-input {
  background-color: unset !important;
}

.input-2 {
  margin-bottom: 1rem;
}

.PalaceCafe-text {
  color: orange;
  margin-left: 10px;
}

.FS-15 {
  font-size: 15px;
}

.text-red {
  color: red;
}

.text-green {
  color: green;
}

.select-style {
  height: 4rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
  font-size: 15px;
}

.tr-style {
  border-color: #ddd;
  background-color: #f5f5f5;
}

.outer-facebook-div {
  /* display: flex; */
  justify-content: center;
}

@media only screen and (min-width: 320px) and (max-width: 524px) {

  .custom-top-margin{
    margin: 5% 31px !important;
  }

  .hideicon {
    padding-left: 70% !important;
    position: absolute !important;
    margin-top: -0.2%;
    font-size: 20px !important;
  }
  .login-screen-parent-div {
    margin-top: 80px;
    width: 100%;
    background: #fff;
  }

  .p {
    position: relative !important;
    margin-top: -25px !important;
    display: flex !important;
    justify-content: center !important;
    color: #666666;
  }

  .googlefbbtn {
    margin-left: 5px;
    width: 114px;
    border-radius: 24px;
    border: none;
    height: 57px !important;
  }
}

@media only screen and (min-width: 520px) and (max-width: 766px) {
  .hideicon {
    padding-left: 81% !important;
    position: absolute !important;
    margin-top: -1.2%;
    font-size: 24px !important;
  }

  .p {
    position: relative !important;
    margin-top: -25px !important;
    display: flex !important;
    justify-content: center !important;
    color: #666666;
  }
}

@media only screen and (min-width: 766px) and (max-width: 992px) {
  .hideicon {
    padding-left: 83% !important;
    position: absolute !important;
    font-size: 24px !important;
  }

  .p {
    position: relative !important;
    margin-top: -25px !important;
    display: flex !important;
    justify-content: center !important;
    color: #666666;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1194px) {
  .hideicon {
    padding-left: 78% !important;

    position: absolute !important;
    font-size: 24px !important;
  }
}

/* Default width */
/* .img-fluid {
  width: 490px;
} */

@media screen and (max-width: 991px){
  .img-fluid{
    width: 680px !important;
  }
}

@media screen and (max-width: 930px){
  .img-fluid{
    width: 650px !important;
  }
} 

@media screen and (max-width: 850px){
  .img-fluid{
    width: 600px !important;
  }
} 

@media screen and (max-width: 800px){
  .img-fluid{
    width: 550px !important;
  }
} 

@media screen and (max-width: 750px){
  .img-fluid{
    width: 500px !important;
  }
} 
@media screen and (max-width: 650px){
  .img-fluid{
    width: 475px !important;
  }

  .login-mobile{
    margin-top: 0px !important;
  }

  .margin-login-side{
    margin-left: -11px !important;
  }

  .cutom-top-margin{
padding: 20% 7% !important;
  }
} 

@media only screen  and (min-width:766px) and (max-width:991px)
{
  .padding-custom-tab{
    padding: 0px 9%;
  }
}
/* 540px wide on screens with width between 540px and 589px */
/* @media screen and (min-width: 540px) and (max-width: 589px) {
  .img-fluid {
    width: 411px !important;
  }
} */

/* 590px wide on screens with width between 590px and 639px */
/* @media screen and (min-width: 590px) and (max-width: 639px) {
  .img-fluid {
    width: 590px !important;
  }
} */

/* Continue adding media queries for each 50-pixel step */

.heading {
  color: var(--Neutral-color, #212524);
  font-family:'Playfair Display', serif;;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.login-heading{
  color: var(--Primary-color, #364D49);
  font-family: 'Playfair Display', serif !important;
  font-size: 23px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 32px; /* 133.333% */
  
}

.text-center{
  color: var(--Neutral-color, #212524);
  font-family: Playfair Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.link-position{
  position: relative;
  top: -5px;
}

.row-position{
  margin-top: 20px !important; 
}
.hideoverflow {
  overflow: hidden;
}

.close-button {
  cursor: pointer;
  margin-left: 530px;
  position: fixed;
  font-size: 15px;
  background-color: white;
  width: -webkit-min-content;
  width: min-content;
  border: none;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  overflow: hidden;
}

.modal-content {
  max-height: 90%;
  overflow-y: auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
}
.label {
  margin-left: 15px;
}
.checkbox {
  cursor: pointer;
}

.form-check {
  /* margin: 10px 25px 20px 20px; */
}

.button-container {
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #fff;
}

.accept-button {
  flex-grow: 1;
  width: 80px;
  border-radius: 5px;
  margin-top: 2rem;
  background: #364d49 !important;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: white;
  cursor: pointer;
  font-family: "Playfair Display";
}

.decline-button {
  width: 80px;
  flex-grow: 1;
  border-radius: 5px;
  margin-top: 2rem;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: white;
  cursor: pointer;
  font-family: "Playfair Display";
  background-color: #a30606;
  margin-left: 10px;
}

.form-check-input {
  margin-top: 1.5%;
}
.checkboxcntnt {
  color: #666666;
}
.privacypolicy {
  transition: all 0.3s;
  color: black;
  text-decoration: underline;
  font-weight: 400;
}
.signup-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  /* identical to box height, or 128% */

  letter-spacing: -0.02em;

  /* Neutral/90 */

  color: #1a1a1a;
  /* text-align: center; */
}
.signup-btn {
  width: 102%;
  margin-left: -10px !important;
  border-radius: 5px;
  margin-top: 2rem;
  margin-bottom: 1rem;
  background: #364d49 !important;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 9px;
  text-align: center;
  display: flex;
  justify-content: center;
  display: flex;
  align-items: center;
  text-transform: capitalize !important;
  color: white;
  font-family: "Playfair Display";
}
.form-control {
  font-size: 1.5rem;
}
p {
  font-family: "Playfair Display";
}


.login_btn{
  color: #364d49;
  background-color: #ebeded;
  padding: 5px 10px;
  border-radius: 5px;
}
.login_btn:hover{
  color: #364d49;
}


@media only screen and (max-width:600px) {
  .margin-confirm-password{
    margin-top: -18px !important;
  }

  .inner-padding-mobile{
    padding: 0px 16px !important;
    margin-top: 5% !important;
  }
}
.back-arrow {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.login-btn {
  width: 100%;
  margin-bottom: 1rem;
  background: #364D49 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  display: flex;
  align-items: center;
  color: white;
  font-family: Glacial Indifference;
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  height: 54px;
  padding: 12px 18px;
  grid-gap: 8px;
  gap: 8px;
  flex: 1 0;
  border-radius: 8px;
  border: 1px solid var(--Primary-color, #364D49);
  background: var(--Primary-color, #364D49);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  .lgn-signup-btn{
    width: 100%;
    border-radius: 5px;
    border: 1px solid #364D49 !important;
    margin-top: 2rem;
    margin-bottom: 0rem;
    background-color: white !important;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 12px;
    text-align: center;
    display: flex;
    justify-content: center;
    display: flex;
    align-items: center;
    color:  #364D49 !important;
    font-family: "Times New Roman";
    text-transform: capitalize;
    cursor: pointer;
  }
.back-arrow-circle {
    background: transparent;
    padding: 8px;
    height: 40px;
    border: none;
    text-decoration: underline;
}
.image-w8r {
  width: 8rem;
}
.atm-card{
    box-shadow: 1px 1px 3px 1px rgba(222,222,222,0.75);
    padding:70px 100px;
}
.customer-name {
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700 !important;
  font-family: "Playfair Display";
  line-height: 50px;
  color: #1a1a1a;
}

.information {
  font-size: 20px;
  padding-bottom: 25px;
}

.boxcntnt {
  margin-left: -10px;
  margin-top: 7px;
  color: #666666;
}

.address-btn {
  margin-left: 16rem;
  padding-top: unset !important;
  margin-top: -px !important;
  margin-bottom: 11px !important;
  border: none;
  background: unset;
  color: rgba(214, 0, 0, 1);
  cursor: pointer;
  height: 10px;
}

/* .content-border:hover {
    margin-top: 2px;
    border: 1px solid #6666;
    width: 100%;
    border-radius: 19px;
    background: #f7f7f7f7;
    height: 96px;
    margin-top: 0px;
    margin-bottom: -13px;

} */

.content-border {
  width: 100%;
  border-radius: 19px;
  /* background: #f7f7f7f7; */
  height: 96px;
  margin-top: 0px;
}

.confirm-address {
  font-size: 20px;
}

.checkout-progress-bar li.active:before,
.checkout-progress-bar li.active > span:before {
  background-color: #666;
}


.ps-main-flex-div {
    background-color: white;
    box-shadow:1px 1px 3px 1px rgba(222,222,222,0.75);
     padding:40px 0px;
     height:100%

}
.progresses {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-right: 12rem;
  margin-left: 120px;
}

.line {
  width: 222px;
  height: 3px;
  background: #e6e6e6;
}

.steps-left {
  display: flex;
  border: 3px solid;
  border-color: black;
  background-color: white;
  color: #fff;
  font-size: 14px;
  width: 42px;
  height: 43px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.steps-right {
  display: flex;
  border: 3px solid;
  border-color: #e6e6e6;
  background-color: white;
  color: #fff;
  font-size: 14px;
  width: 42px;
  height: 43px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.circle {
  font-size: 25px;
  /* width: 14px; */
  color: rgb(214, 0, 0);
  /* padding: 5px; */
  padding-top: 2.3px;
}

.listyle {
  list-style: none;
}

.payment-method {
  position: absolute;
  margin-left: 37rem;
  margin-top: 30px;
  font-size: 16px;
}

.informationn {
  position: absolute;
  margin-left: -9rem;
  margin-top: 30px;
  font-size: 16px;
}

.checkoutbtn {
  margin-left: 3rem;
  width: 134px;
  border-radius: 24px;
  border: none;
  height: 57px !important;
  margin-top: 10px;
}

.home-delbtn {
  margin-right: 34px;
  border: none;
  background: white;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  pointer-events: painted;
}
.heading {
    margin-bottom: 3rem;
    color: #222529;
}
.home-delbtn:hover {
  border: 3px solid;
  border-bottom-color: #fad20c;
  border-top-color: white;
  border-left-color: white;
  border-right-color: white;
  margin-right: 34px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  color: red;
}

.cancelbtn {
  border-radius: 5px;
  width: 84px;
  height: 40px;
  margin-top: 26px;
  border: none;
  background: white;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 4rem;
  font-family: "Playfair Display";
}

.nextbtn {
  border-radius: 5px;
  width: 90px;
  height: 40px;
  margin-top: 26px;
  border: none;
  background: #364d49;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 4rem;
  /* margin-left: 39rem; */
  margin-right: 26px;
  padding: 9px;
  color: white;
  font-family: "Playfair Display";
}

.order-detail {
  color: #364d49;
  font-size: 1.9rem;
  font-family: "Playfair Display";
  /* margin-left: 24rem; */
}

.location-order-detail {
  color: black;
  /* font-size: 1.9rem; */
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-family: Glacial Indifference;
}

.feild-outer {
  width: 75%;
  margin-left: 79px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 45px;
  margin-bottom: 32px;
}

.progress-bar-content {
  display: flex;
  justify-content: space-around;
  width: 108%;
}

.progress-bar-info {
  /* font-family: 'Roboto Condensed'; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
  margin-left: -102px;
}
.progress-bar-payment {
  /* font-family: 'Roboto Condensed'; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
}
@media (min-width:300px) and (max-width:350px) {
    .atm-card {
        padding: 70px 0px !important;
    }
}
@media (min-width:351px) and (max-width:400px) {
    .atm-card {
        padding: 70px 17px !important;
    }
}
 @media (min-width:401px) and (max-width:450px) {
    .atm-card {
        padding: 70px 42px !important;
    }
 }
 @media (min-width:451px) and (max-width:500px) {
    .atm-card {
        padding: 70px 64px !important;
    }
 }
 @media (min-width:501px) and (max-width:550px) {
    .atm-card {
        padding: 70px 90px !important;
    }
 }
 @media (min-width:551px) and (max-width:600px) {
    .atm-card {
        padding: 70px 110px !important;
    }
 }
@media (max-width:651px) {
    
    .pymnt{
        margin-top: 90px !important;
    }
    .ord-detail{
        margin-top: 20px;
    }
    .ps-main-flex-div {
         padding:20px 0px;
    }
    .progress-bar-content {
    display: flex;
    justify-content: space-between;
  }
  .progress-bar-payment {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    /* margin-right: 56px; */
    margin-right: 12%;
  }
  .progress-bar-info {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    margin-left: -23px;
  }
  .location-cart-container-1 {
    width: 100%;
    left: 0px !important;
    max-width: 100% !important;
  }
  .co-main-container {
    margin-top: 10rem;
  }
}


@media (min-width: 991px) and (max-width: 1051px) {
  .nextbtn {
    border-radius: 5px;
    width: 90px;
    height: 37px;
    margin-top: 0px;
    border: none;
    background: #364d49;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    margin-bottom: 4rem;
    margin-left: unset;
    margin-right: 77px !important;
    padding: 7px;
    margin-bottom: 8rem;
  }

.order-detail {
    color: #364D49;
    font-size: 1.9rem;
    /* margin-top: 6rem; */
    font-family: "Playfair Display";
    /* margin-left: 24rem; */
}

.feild-outer {
    /* width: 48%; */
    /* margin-left: 79px; */
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 45px;
    margin-bottom: 32px;


}
.checkout-login-button {
  transition: all 0.3s;
  text-transform: uppercase;
  padding: 0.85rem 3.2rem;
  border-radius: 0;
  font-size: 1.4rem;
  font-weight: 700;
  font-family: Poppins, sans-serif;
  line-height: 1.429;
  background: #364d49;
  color: white;
  border-radius: 5px;
}


@media (min-width:991px) and (max-width: 1051px) {

    .nextbtn {
        border-radius: 5px;
        width: 90px;
        height: 37px;
        margin-top: 0px;
        border: none;
        background: #364D49;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
        margin-bottom: 4rem;
        margin-left: unset;
        margin-right: 77px !important;
        padding: 7px;
        margin-bottom: 8rem;
    }

    .cancelbtn {

        border-radius: 5px;
        width: 90px;
        height: 40px;
        margin-top: 0px;
        border: none;
        background: white;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
        margin-bottom: 8rem !important;
        margin-left: 14px;
        font-family: "Playfair Display";

    }

}
.checkout-login-button{
    transition: all .3s;
    text-transform: uppercase;
    padding: 0.85rem 3.2rem;
    border-radius: 0;
    font-size: 1.4rem;
    font-weight: 700;
    font-family: Poppins,sans-serif;
    line-height: 1.429;
    background: #364D49;
    color: white;
    border-radius: 5px;
}

    }
@media (min-width: 652px) and (max-width:670px) {
  

    .progress-bar-content {
    display: flex;
    justify-content: space-between;
  }
  .progress-bar-payment {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    margin-right: 78px;
    /* margin-right: 14%; */
  }
  .progress-bar-info {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    margin-left: -23px;
  }
}
@media (min-width: 671px) and (max-width:699px) {
    .atm-card{
        padding:70px 20px;
    }

    .progress-bar-content {
    display: flex;
    justify-content: space-between;
  }
  .progress-bar-payment {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    margin-right: 14rem;
    /* margin-right: 14%; */
  }
  .progress-bar-info {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    margin-left: -23px;
  }
}

@media (min-width: 768px) and (max-width: 854px) {
  .progress-bar-payment {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    margin-right: -17px;
  }
}
@media (min-width: 856px) and (max-width: 870px) {
  .progress-bar-payment {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
  }
}
@media (min-width: 873px) and (max-width: 944px) {
  .progress-bar-payment {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    margin-right: 8%;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
  }
}
@media (min-width: 945px) and (max-width: 990px) {
  .progress-bar-payment {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    margin-right: 15%;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
  }
}
@media (width: 912px) {
  .progress-bar-payment {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    margin-left: 238px !important;
  }
  .progress-bar-info {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    margin-left: -24px;
  }
}

@media (min-width: 450px) and (max-width: 699px) {
  .progresses {
    display: flex;

    justify-content: start;

    align-items: center;

    margin-right: 3rem;

    margin-left: 12px;

    /* width: auto; */
  }
}
/* Payment css */
.lines {
  width: 222px;
  height: 3px;
  background: #121111;
}

.steps-rightt {
  display: flex;
  border: 3px solid;
  border-color: black;
  background-color: white;
  color: #fff;
  font-size: 14px;
  width: 42px;
  height: 43px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.steps-leftt {
  display: flex;
  border: 3px solid;
  color: rgb(214, 0, 0);
  font-size: 14px;
  width: 42px;
  height: 43px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.tick {
  font-size: 41px;
  color: rgb(214, 0, 0);
  padding-top: 2.3px;
}

.credit-cod {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
  margin-top: 7px;
}

.credit-border {
  /* border: 1px solid rgba(204, 204, 204, 1); */
  /* width: 38px; */
  height: 38px;
}

.credit-icon {
  padding-top: 3px;
  display: block;
  max-width: 100%;
  height: auto;
  padding-left: 7px;
}

.cash-icon {
  padding-top: 7px;
  display: block;
  max-width: 100%;
  height: auto;
  padding-left: 6px;
}

.credit-cod-content {
    /* padding-left: 20px; */
    padding-right: 28px !important;
}

.payment-text {
  font-size: 20px;
  margin-left: 1rem;
}

.card-border {
    /* border: 1px solid #6666; */
    width: 100%;
    border-radius: 4px;
    /* background: #f7f7f7f7; */
    height: 96px;
    margin-top: 0px;
    margin-bottom: -36px;
}
.add-card {
  color: rgba(214, 0, 0, 1);
}

.card-btn {
  padding-top: unset !important;
  margin-top: -61px !important;
  margin-bottom: 11px !important;
  border: none;
  background: unset;
  color: rgb(145, 123, 123);
  cursor: pointer;
  height: 10px;
  margin: auto;
  padding-right: 16px;
}

.button-cart-disable{
  opacity: 0.7;
  cursor: not-allowed;
  pointer-events: none;
}

.top_margin_main{
  margin-top: 11rem !important;
}

.cart-quantity {
  background-color: #fafafa;
  height: 39px;
  width: 39px;
  border-radius: 12px;
  padding-top: 10px;
  color: #364D49 !important;
}

.cart-checkout-text {
  margin-left: 49px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  font-family: Glacial Indifference;
}

.cart-final-price {
  /* font-family: 'Roboto Condensed'; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: right;
  color: white;
  margin-left: 33px;
  font-family: Glacial Indifference;
}

.cart-btn-outer
{
    /* position: relative;
    left: 70px; */
}


.place-order-btn{
    border-radius: 8px !important;
    background: #364D49 !important;
      color: white;
      border: none;
      box-shadow: none;
}
.cart-btn {
  border-radius: 100px;
  height: auto;
  margin: auto;
  background-color: #fed301;
  padding: 6px 14px;
color: #FFF;
font-family:Glacial Indifference !important;
text-transform: none;
font-size: 16px;
font-style: normal;
font-weight: 500;
position: -webkit-sticky;
position: sticky;
bottom: 0px;
z-index: 1;
 z-index: 1;
}
hr{
    margin: 0px;
}
.cart-product-name {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #2d5c48;
    /* margin: 0px !important; */
   font-family: 'Playfair Display', serif;
    margin-left: 10px;
    white-space: nowrap;
  }
input[type='radio'] {

    -webkit-appearance:none;

    width:19px;

    height:19px;

    border:1px solid rgba(204, 204, 204, 1);

    border-radius:50%;

    outline:none;

  width: 19px;

  height: 19px;

  border: 1px solid rgba(204, 204, 204, 1);

  border-radius: 50%;

  outline: none;

  /* box-shadow:0 0 5px 0px gray inset; */
}

input[type="radio"]:hover {
  box-shadow: 0 0 5px 0px orange inset;
}

input[type="radio"]:before {
  content: "";

  display: block;

  width: 60%;

  height: 60%;

  margin: 20% auto;

  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: black;
}

@media (min-width: 992px) and (max-width: 1534px) {
  .cart-btn-outer {
    width: auto !important;
    margin-left: 8rem !important;
    margin-right: 11rem !important;
  }
}

/* @media (min-width: 375px){
    .cart-btn-outer{
        width: auto !important;
    /* margin-left: 8rem !important; */
/* margin-right: 2rem !important; */
/* } */
/* }  */
/* cartDetail.css */
.order-detail {
  color: #364d49;
  font-size: 25px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;

  /* margin-left: 24rem; */
}
.location-order-detail {
  color: black;
  font-size: 27px;
  font-weight: 600;
  line-height: 10px;
  letter-spacing: -0.02em;
  text-align: left;

  /* margin-left: 24rem; */
}

.location-order-detail1 {
  color: #666666;
  font-size: 18px;
  font-weight: 400;
  /* line-height: 32px; */
  letter-spacing: -0.02em;
  font-family: "Playfair Display";

  /* text-align: left; */
}

.sub-details {
  /* font-family: 'Roboto Condensed'; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  color: #666666;
}

.location-cart-sub-details {
  font-family: Arial, Helvetica, sans-serif;
  /* font-style: normal; */
  font-weight: 400;
  font-size: 14px;
  position: relative;
  top: 8px;
  /* line-height: 24px; */
  /* identical to box height, or 150% */

  /* display: flex; */
  /* align-items: center; */

  color: black;
}
.ndsub-details {
  font-style: normal;
  font-weight: 400;
  font-size: 12.8px;
  line-height: 20px;
  /* identical to box height, or 156% */

  /* display: flex; */
  align-items: center;

  /* Neutral/50 */

  color: #808080;
}
.location-cart-product-name {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: black;
  font-family: Glacial Indifference;
}



.location-ndsub-details {
  /* font-style: normal; */
  list-style-type: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 8px;
  /* padding-left: 20px; */
  margin-left: 26px;
  /* identical to box height, or 156% */
  /* font-family: Arial, Helvetica, sans-serif; */
  /* display: flex; */
  align-items: center;

  /* Neutral/50 */

  color: #808080;
}

.location-ndsub-details li::before {
  content: "+"; /* Use the plus symbol as the content */
  /* display: inline-block; */
  width: 1em; /* Adjust the width to control spacing */
  margin-left: -1em; /* Offset to the left to make space for the plus symbol */
}

.sub-total {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  /* Neutral/90 */

  color: #1a1a1a;
}
.location-sub-total {
font-family: Glacial Indifference;
  font-weight: 200;
  font-size: 16px;
  margin: 5px 0px 5px 0px;
  letter-spacing: -0.02em;
  color: black;
}

.location-sub-total-left{
  font-family: "Playfair Display";
  font-weight: 200;
  font-size: 16px;
  margin: 5px 0px 5px 0px;
  letter-spacing: -0.02em;
  color: black;
}



.location-subtotal-price {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 200;
  font-size: 16px;
  color: #666666;
}

.Vat-price {
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 200;
  font-size: 16px;
  color: #666666;
  /* margin: 0.5rem; */
}
.location-total-price {
font-family: Glacial Indifference;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */
  letter-spacing: -0.02em;

  /* Neutral/90 */

  color: black;
}
.location-total {
  font-family: Glacial Indifference;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height, or 140% */
  letter-spacing: -0.02em;

  /* Neutral/90 */

  color: black;
}

.clock {
  width: 24px;
  height: 24px;
  left: 0px;
  font-size: 30px;
  color: black;
}

.body-content {
  width: 85%;
  background-color: rgb(247, 247, 247);
  box-shadow: rgb(0 0 0 / 8%) 0px 16px 24px;
  /* margin-top: -46px; */
  padding: 20px;
  margin-left: 10rem;
  height: 192vh;
}
@media (width: 991px) {
  .body-content {
    width: 75% !important;
    background-color: rgb(247, 247, 247);
    box-shadow: rgb(0 0 0 / 8%) 0px 16px 24px;
    margin-top: -45px;
    padding: 0px;
    margin-left: 77px !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}
.product-modal-special {
  /* font-family: 'Roboto Condensed'; */

  font-style: normal;

  font-weight: 700;

  font-size: 20px;

  line-height: 28px;

  display: flex;

  align-items: center;

  letter-spacing: -0.02em;

  color: #1a1a1a;

  margin: 0px;

  margin-left: 1rem;
}
.product-modal-more-optional {
  font-family: "Playfair Display";

  font-style: normal;

  font-weight: 400;

  font-size: 16px;

  line-height: 24px;

  display: flex;

  align-items: center;

  text-align: right;

  color: rgb(13, 37, 49);

  position: relative;

  left: 190px;
}
.product-modal-special-ins {
  /* 
        font-family: 'Roboto Condensed'; */

  font-style: normal;

  font-weight: 400;

  font-size: 16px;

  line-height: 24px;

  margin-left: 1rem;

  color: #666666;
}
.text-area-body {
  outline-color: red;
  margin-top: 41px;
  width: 100%;
  border-radius: 16px;
}
.text-area-body:focus {
  border-color: red;
  outline-style: none;
}

/* //order place modal */
.order-placed-modal {
  padding-top: 5%;
  padding-bottom: 2%;
}
.cart-button {
  background-color: #c41000 !important;
  color: #fff !important;
  border-color: #fff !important;
}
.cart-button:hover {
  background-color: #c00a27 !important;
}

.cart-container {
  position: relative;
  top: 10px;
  margin: 0px 0px 0px 100px;
  padding: 0px;
  /* margin-left: 150px; */
}

.location-cart-container-1 {
  /* background-color: white; */
  background-color: white;
  /* border-radius: 4px; */
  /* box-shadow: 1px 1px 3px 1px rgba(222, 222, 222, 0.75); */
  width: 100%;
  /* max-height: 80%; */

  /* width: 100%; */
  /* padding: 0px 80px 10px 80px; */
}

.location-cart-container {
  position: relative;
  /* left: -15px; */
  /* top: 30px; */
  overflow-y: auto;
  /* min-height: 80vh;
  max-height: 80vh; */
  /* margin: 0px 0px 0px 100px; */
  padding:15px 35px;
  /* height: calc(100% - 100px); */
  /* margin-left: 20px; */
}
.location-cart-extra {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
  margin: auto;
}

.instruction-container{
padding-top: 5px;
margin-top: -3px;
padding-bottom: 15px;
}

.instruction-para{
  color: #000;
  font-family: Glacial Indifference;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.insturction-special-heading{
  color: var(--Neutral-color, #212524);
font-family: Playfair Display;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-bottom: 10px;
}

.icon_back_menu{
  position: absolute;
  left: 26%;
}

.backmanutext{
  color: #4D5150;
font-family:Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 400;
}

.arrowBacktoMenu{
  color: #4D5150;
  font-size: 20px;
  margin-right: 10px;
}

@media only screen and (min-width:1200px){
  .cart-choice-qty{
    margin-left: 10%;
  }
}

.margin_custom_top_{
  margin-top: 5%;
}

.cart_button_disbale_{
opacity: 0.7;
pointer-events: none;
}

.paddingMobileCustom{
  padding: 40px 40px;
}

@media only screen and (min-width:766px) and (max-width:820px)
{
  .icon_back_menu{
    left: 19% !important;
  }
}

@media only screen and (max-width:600px) {
  .cart-product-name{
    font-size: 15px !important;
    white-space: nowrap;
  }

  .margin_custom_top_{
    margin-top: 30% !important;
  }
  .top_margin_main{
    margin-top: 1rem !important;
  }

  .cart-choice-qty{
    left: 85px;
  }

  .order-detail{
    font-size: 20px;
  }
  .font_size_custom{
    font-size: 18px !important;
  }

  .login-sidepart{
padding: 0px 24px;
  }

  .location-cart-container{
    padding: 15px 16px !important;
  }

  .menu-checkoutscreen{
    margin-left: -9px;
  }

  .icon_back_menu{
    left: 5% !important;
  }

  .place-order-btn{
    
  }
  .button_wrapper_box{
    display: flex;
width: 100%;
margin:auto;
padding: 16px 16px;
justify-content: center;
align-items: center;
border-radius: 10px 10px 0px 0px;
background: #FFF;
box-shadow: 0px -6px 18px 0px rgba(80, 79, 79, 0.25);
position: fixed;
bottom: 0%;
height: 80px;
left: 0%;
  }

  .cart-btn{
padding: 10px 14px;
  }

  .backmanutext{
    font-size: 16px;
  }

  .arrowBacktoMenu{
    font-size: 18px;
  }

  .paddingMobileCustom{
    padding: 20px 24px; 
    border: 1px solid rgb(209, 209, 209);
  }

  

}


@media only screen and (max-width:300px)
{
  .cart-product-name{
    font-size: 10px !important;
  }

  .product-price{
    font-size: 10px !important;
  }
}
.outer-menu-section {
  background: #f7f7f7 !important;
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.menu-selection-left {
  top: 64.22%;
  background: #d60000;
  border-radius: 24.5364px;
  display: flex;
  justify-content: space-between;
}
.menu-text {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 32px;

  align-items: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  padding-top: 76px;
  padding-left: 40px;
}
.menu-text p {
  margin-left: 4px;
}
.menu-btn {
  padding: 5px 11px;
  grid-gap: 8px;
  grid-gap: 8px;
  gap: 8px;
  width: auto;
  height: 45px;
  background: #fed301;
  border-radius: 100px;
  border: none;
  cursor: pointer;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
}

.menu-right {
  position: relative;
  top: 18px;
  right: 4%;
}

.menu-selection-right {
  border-radius: 24.5364px;
  display: flex;
  justify-content: space-between;
}
.right-text {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  color: #364D49;
  padding-top: 14%;
  padding-left: 6%;
  padding-bottom: 10.5%;
}
.left-image {
  margin: auto;
  padding-top: 4.1rem;
}
.menu-image {
  width:60%;
  margin-left:40px;
  margin-top:38px
}
.download-app {
  display: flex;
}

@media only screen and (min-width: 320px) and (max-width: 414px) {
  .menu-text {
    padding-top: 32px;
    font-size: 21px;
  }
  .menu-btn {
    height: 51px;
  }
  .right-text {
    font-size: 20px;
    padding-bottom: 2.6%;
  }
  .download-app {
    display: block;
  }
  .download-app img {
    padding: 1rem;
    margin-left: 0px !important;
  }
  .left-image img {
    height: 197px;
  }
  .menu-image {
    width: 100%;
    height: 197px;
    margin-left: 0px;
    margin-top: 36px;
}
    }
  .menu-selection-left {
    margin-bottom: 1rem;
  }

@media only screen and (min-width: 500px) {
  .menu-selection-left {
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .menu-text {
    padding-top: 32px;
    font-size: 23px;
  }
  .menu-btn {
    height: 51px;
  }
  .download-app img {
    width: 48%;
  }
}
@media only screen and (min-width: 540px) {
  .menu-selection-left {
    margin-bottom: 1rem;
  }
}

nav {
	width: 100%;
	position: -webkit-sticky;
	position: sticky;
	z-index: 3;
	top: 0;
	padding: 1.2em 0;
	color: white;
	/* background-color: antiquewhite; */
	max-height: 4em;
}

nav span {
	font-size: 18px;
	border-bottom: 1px solid transparent;
	transition: border-bottom 0.2s ease;
	/* transition-delay: 0.25s; */
	margin: 1em;
	/* padding-bottom: 1.3em; */
	position: relative;
	left: -15px;
	top: -35px;
}
.input-group-addon {
	color: black;
    padding: 10px;
    margin: 0px;
    background: rgb(189, 187, 187);
    font-size: 16px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
	top: 0px !important;
	left: 0px !important;
	border-bottom: none !important;
}
.input-group-addon-2 {
	color: black;
    padding: 10px 5px;
    margin: 0px;
	position: absolute;
	left: 95%;
    font-size: 16px;
	top: 0px !important;
	border-bottom: none !important;
}
.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control {
	width: 400px;
	border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

nav span:hover {
	cursor: pointer;
	border-bottom: 1px solid white;
}

nav span:hover i {
	color: gold;
}

nav span.activeClass i {
	color: gold;
}

nav span.activeClass {
	border-bottom-color: var(--lightAccent);
}
.btn-group-mod-toggler {
	position: relative;
	bottom: -20px !important;
}
@media (max-width: 600px) {
	.mt-web-view {
		display: none !important;
	}
	nav span {
		top: -15px !important
	}
	.search-column {
		justify-content: center !important;
	}
	.custom-mode-buttons {
		display: flex;
		justify-content: center;
		left: 0px !important;
	}
	.nav-web-search-field {
		display: none !important;
	}

	.custom_font_onmobile{
		font-size: 18px !important;
	}
}

@media only screen and (max-width:300px)
{
	.font_size_small{
		font-size: 14px !important;
	}
}
section {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  width: 100%;
  /* border-bottom: 1px solid lightgrey; */
}

section:nth-child(even) {
  flex-direction: row-reverse;
}
.product-image {
  width: 100px;
  height: 100px;
  margin-top: 6px;
  float: right;
  object-fit: cover;
}
.hm-featured-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  padding-top: 3rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  color: #2D5C48;
  font-family: 'Playfair Display';
  margin-bottom: 25px !important;
}


.hm-featured-heading-over{
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  padding-top: 8rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  color: #2D5C48;
  font-family: 'Playfair Display';
  margin-bottom: 25px !important;
}

/* section img,
section div {
	height: auto;
	width: 100%;
} */

/* section img {
	width: 50%;
} */

/* section div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 1em;
} */
.hm-card-custom {
  box-shadow: 1px 1px 3px 1px rgba(222,222,222,0.75);
  padding:4px 10px 0px 10px;
  border-radius: 4px;
  /* max-height: 150px !important; */
  height: 100%;
}
@media screen and (max-width: 900px) {
  section,
  section:nth-child(even) {
    flex-direction: column;
  }

 section img {
    height: auto;
    width: auto;
    max-width: 100vw;
  }
}
.cart-parent-class {
  padding: 25px 5px;
}

.cart-sticky {
  box-shadow: 1px 1px 3px 1px rgba(222,222,222,0.75);
  position: -webkit-sticky;
  position: sticky;
  top: 150px;
  height: calc(100vh - 150px);
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-radius: 4px;
}
.hm-produc-div {
  background: white;
  border-radius: 6px;
box-shadow: 1px 1px 1px 1px #8d928d;
}

@media (max-width: 600px) {
  .mbl-menu-cntr {
    margin-top: 120px !important;
  }

  .hm-featured-heading{
    font-size: 20px;
  }

  .hm-featured-heading-over{
    font-size: 20px;
  }
}

@media only screen and (max-width:300px)
{
  .hm-featured-heading{
    font-size: 20px;
  }
  .mbl-menu-cntr{
    margin-top: 88px !important;
  }

  .sticky-subheader-outer{
    top: 50px !important;
  }

  .hm-featured-heading-over{
    font-size: 18px;
  }
}

@media only screen and (min-width:766px) and (max-width:991px)
{
  .margin_left_custom{
    margin-left: 17px;
  }
}
.modalSetting-productModal {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 40%;
  display: flex;
  background-color: white;
  box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 13px 0px,
    rgba(0, 0, 0, 0.08) 0px 3px 6px 0px;
  flex-direction: column;
  border-radius: 4pt;

  /* height: 100vh; */
  /* max-height: calc(100vh - 25px); */
  position: relative;
  padding: 0px !important;
  overflow-y: auto; /* Enable vertical scrolling */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE 10+ */
  overflow-x: hidden;
  /* border-radius: 8px;
  width: 35%;
  height: 80vh;
  position: relative;
  overflow: hidden; */
}
/* .modalSetting::-webkit-scrollbar {
  display: none; 
} */
.modal-content-container {
  max-height: calc(100vh - 100px);
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  width: 100%;
  -webkit-overflow-scrolling: touch; /* Use 'touch' for smooth scrolling in Safari */
  scroll-behavior: smooth !important;

  /* Hide scrollbars for Chrome, Safari, and Edge */
  scrollbar-width: thin; /* Firefox */
  -webkit-scrollbar-width: thin; /* Chrome, Safari, and Edge */

  /* Hide scrollbars for IE, Edge, and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Add Safari specific styling for scrollbar */
.modal-content-container::-webkit-scrollbar {
  width: 5px;
}

.modal-content-container::-webkit-scrollbar-thumb {
  background-color: #888; /* or any color you prefer */
}

.react-responsive-modal-root {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: fixed;
  z-index: 1051;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.5);
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-overflow-scrolling: initial !important; 
  scroll-behavior: smooth !important;
}

.react-responsive-modal-container {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: fixed;
  z-index: 1051;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.5);
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-body {
  padding: 2.5px !important;
  /* max-height: 91vh; */
  /* overflow: auto; */
  /* overflow: auto !important; */
  -webkit-overflow-scrolling: touch !important;
}
.modal-content {
  border-radius: 10px;
}
.cross-svg {
  width: 65%;
  position: relative;
  bottom: 26px;
}
.productModal-image {
  width: 100%;
  height: 314px;
  min-height: 314px;
  /* You can adjust the value as needed for the maximum height */
  /* margin: auto; */
  object-fit: cover;
}
body.modal-open {
  /* height: 100vh;
  overflow-y: hidden; */
  position: fixed;
    top:    0;
    right:  0;
    bottom: 0;
    left:   0;
}
.modal {
  will-change: display;
}
.modal-dialog {
  will-change: transform;
}


.mdaalSetting .react-responsive-modal-closeButton {
  color: #fff;
  position: absolute;
  top: 14px;
  right: 45px !important;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
}
.productModal-detail {
  padding-left: 30px;
}

.productModal-name-font{
font-family: 'Playfair Display' !important;
}

.productModal-name {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  letter-spacing: -0.02em;
  margin-left: 23px;
  color: #2d5c48;
  margin-top: 30px !important;
  margin-bottom: 13px;
  position: relative;
  left: -35px;
font-family: Glacial Indifference;
}
.product-modal-price {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #2d5c48;
  margin-left: 3rem;
}

.productModal-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: rgb(13, 37, 49);
  margin-left: 14px;
  margin-right: 3rem;
  position: relative;
  left: -25px;
font-family: Glacial Indifference;
  margin-bottom: 0px;
}


.productModal-single-item {
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  /* line-height: 2px; */
  display: flex;
  align-items: center;
  /* color: #666666; */
  margin-left: 2.5rem;
  margin-top: 6.5%;
  font-family: Glacial Indifference;
  white-space: nowrap;
}
.productModal-allerges-list {
  display: flex;
  position: relative;
  left: -41px;
  margin-left: 3rem;
  margin-bottom: 5px !important;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}

.productModal-allerges-list > li {
  list-style: disc;
  margin-left: 0px;
  margin-right: 14px;
  font-family: Glacial Indifference;
  color: rgb(13, 37, 49);
}
.productModal-more {
  /* background-color: #f7f7f7; */
  /* border-radius: 24px; */
  /* margin-left: 20px; */
  position: relative;
  /* left: -35px; */
  padding-left: 10px;
  width: 100%;
}
.product-modal-more-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1a1a1a;
  margin-bottom: 0px;
}

.productModal-body1 {
  /* margin-top: 20px; */
  padding: 0 30px 10px;
  min-height: 99vh;
    height: auto;
    overflow: auto;
}

.disable-button{
  opacity: 0.7 !important;
  pointer-events: none !important;
  
}

.radio-modal:checked::before {
  /* background-color: green;
  border-color: green; */
  background: #2f4e49 !important;
}

.productModal-index {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #0f0f0f;
    right: -70%;
    position: absolute;
    color: #212524;
    font-family: Glacial Indifference;
}
.product-modal-special {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1a1a1a;
  margin: 0px;
  margin-left: 1rem;
}

.form-text {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 16px;
}

.productModal-cart-product-quantity {
  /* background-color: #2d5c48; */
  /* height: 20px; */
  width: 20px;
  /* border-radius: 100px; */
  /* padding-top: 2.5px; */
  /* padding-left: 6px; */
  color: #2d5c48 !important;
  position: relative;
  left: 18px;

  /* margin-top: 2px; */
}
.productModal-cart-product-choice-quantity {
  width: 20px;
  color: #2d5c48 !important;
  position: relative;
  left: 7px;

  /* margin-top: 2px; */
}
.product-ingrediant {
  margin-left: 27px;
}
/* .cart-outer-quantity{

    padding: 8px 16px;
    grid-gap: 16px;
    grid-gap: 16px;
    gap: 16px;
    width: 111px;
    height: 41px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 100px;

} */

.productModal-cart-product-icons {
  display: flex;
  float: right;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background: #ffffff;
  width: 65%;
  height: 75%;
  padding: 13px 16px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.productModal-cart-product-icons-add-to-cart {
  display: flex;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background: #ffffff;
  width: 100%;
  height: 75%;
  padding: 6px 7px;
  justify-content: space-between;
  align-items: center;
}
.productModal-cart-outer-quantitys {
  display: flex;
  flex-direction: row;
  /* padding: 16px; */
  border-style: none;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  background: rgb(243, 244, 239);
  position: relative;
  top: 5px;
  /* padding: 6px 16px;
  grid-gap: 16px;
  width: 90%;
  height: 39px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 10px;
  display: inline-block;
  position: relative;
  left: 25px; */
}
.crt-btn {
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  background-color: #f7f7f7;
}
.react-responsive-modal-overlay {
  position: absolute;
}
.productModal-cart-btn {
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  border-style: none;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  width: 103%;
  background: #2f4e49;
  cursor: pointer;
  font-family: Glacial Indifference;
  grid-gap: 5px;
  gap: 5px;
  /* display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 8px 20px;
  gap: 8px;
  border: none;
cursor: pointer;
  height: 40px;
  background: #2d5c48;
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 25px;
  color: #1a1a1a; */
}

.font_weight_custom{
  font-weight: 600;
}

.product-ingrediant-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.productModal-cart-outer-div {
  margin-top: 5px;
  width: 90%;
  background: #cccccc;
}

.product-modal-over{
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 5px 0px gray inset;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
input[type="radio"]:checked:before {
  background: black;
}
input[type="checkbox"] {
  /* margin: 0px;
  display: flex;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(139, 143, 161);
  box-sizing: border-box;
  border-radius: 4px;
  width: 24px;
  min-width: 24px;
  color: white;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 24px;
  margin-left: -20px;
  outline: none; */
  /* -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border: 1px solid darkgray;
  outline: none;
  padding-left: 2px;
  font-weight: bolder;
  box-shadow: 0 0 5px 0px grey inset;
  margin-top: 4px; */
}
input[type="checkbox"]:before {
  display: block;
  width: 60%;
}
input[type="checkbox"]:checked:after {
  /* content: "\2713";
  margin: 0px;
  display: flex;
  background: rgb(29, 78, 71);
  border: 1px solid rgb(29, 78, 71);
  box-sizing: border-box;
  border-radius: 4px;
  width: 24px;
  min-width: 24px;
  color: white;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 24px; */
}
@media (min-width: 320px) and (max-width: 1920px) {
  .modalSetting-productModal {
    width: 35%;
    /* height:95vh; */
  }
}
@media (min-width: 320px) and (max-width: 1366px) {
  .modalSetting-productModal {
    width: 45%;
    /* height:95vh; */
  }
}
@media screen and (min-width: 768px) {
  .modalSetting-productModal {
    width: 550px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .modalSetting-productModal {
    width: 95%;
    max-height: 88vh;
  }
  .modal-cart-btn {
    margin-left: 10px !important;
    font-size: 16px;
  }
  /* .cart-outer-quantitys {
    width: 50%;
  } */
  .modal-cart-outer-div {
    width: 94%;
  }
  .product-modal-body {
    margin-top: 20px;
    padding: 0px 10px 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .modal-cart-btn {
    /* margin-left: 10px !important; */
    font-size: 16px;
  }
  .modal-cart-outer-div {
    width: 90%;
  }
}
@media (min-width: 768px) and (max-width: 912px) {
  .cart-outer-quantitys {
    width: 100%;
  }
  .modal-cart-outer-div {
    width: 100%;
  }
  .modal-cart-btn {
    /* margin-left: 10px !important; */
    font-size: 16px;
  }
  .modalSetting-productModal {
    width: 95%;
    height: 95vh;
  }
}

@media (min-width: 320px) and (max-width: 500px) {

  .modal_btns{
height: 27px !important;
  }

  .productModal-cart-outer-quantitys {
    width: 140%;
    left: -8px;
  }
  .productModal-cart-outer-div {
    float: right;
    width: 100%;
  }
  .productModal-cart-product-icons {
    width: 100%;
    height: 65%;
  }
  .shopping-cart-icon {
    display: none !important;
  }
  .product-modal-more {
    padding: 30px !important;
  }
  .modal-cart-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: unset !important;
    grid-gap: 8px;
    grid-gap: 8px;
    gap: 8px;
    border: none;
    cursor: pointer;
    height: 40px;
    background: #2f4e49;
    border-radius: 100px;
    flex: none;
    order: 0;
    flex-grow: 1;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    /* margin-left: 25px; */
    color: #1a1a1a;
  }
}
.react-responsive-modal-closeButton {
  position: absolute;
  top: 14px;
  right: 45px !important;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
}
.colored-toast.swal2-icon-success {
  background-color: #a5dc86 !important;
}
.combo-details {
  /* height: 35px; */
  position: relative;
  left: -12px;
}


.orange-disk {
  width: 18px;
  height: 18px;
  background-color: #364D49;
  /* Adjust the color as needed */
  border-radius: 50%;
  /* Makes it a circle */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
}

.check-icon {
  color: white;
  /* Color of the check icon */
}

@media only screen and (max-width:600px)
{

  .productModal-more{
    height: 230px;
  }

  .margin-mobile{
    margin-left: 10px !important;
  }

  .productModal-cart-product-icons-add-to-cart{
    padding: 10px 7px !important;
  }

  .modal-bottom-buttons{
    margin-left: 10px !important;
    position: static;
  }

 
}


@media only screen and (max-width:300px)
{
  .modalSetting-productModal {
    width: 100%;
  }

  .productModal-name{
    font-size: 14px !important;
  }
  .productModal-cart-outer-quantitys{
    width: 181%;
    left: -21%;
  }

  .font_weight_custom{
    font-size: 10px;
    white-space: nowrap;
  }

  .modal_btns{
    height: 25px !important;
  }

  .productModal-cart-product-icons-add-to-cart{
    padding: 11px 5px !important;
  }
  
}


.closeIcon{
  width: 40px;
height: 40px;
background-color: #d3ddd9;
border-radius: 50%;
position: absolute;
display: flex;
justify-content: center;
align-items: center;
right: 2%;
top: 1%;
z-index: 999999999;
}

.closeIcon .icon_close{
  color: #364D49;
  font-size: 25px;
}
.main-outer-category {
  background-color: #f7f7f7;
  margin-top: -27px;
}

.sticky-subheader {
  display: none;
  align-items: center;
  justify-content: space-evenly;
  overflow-y: hidden;
  /* overflow-x: auto; */
  background: #f7f7f7;
  padding-left: 10%;
  padding-right: 10%;
  color: #000000;
  width: 90%;
  height: 50px;
  position: fixed;
  top: 0;
  z-index: 2;
}

.outer-category {
  width: 91%;
  height: 100px;
  overflow-x:hidden;
  scroll-behavior: smooth;
  /* justify-content: center; */
  top: -35px;
  position: relative;
  z-index: 1;
  left: 50px;
}

.inner-category {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 16px;
  gap: 16px;

  /* position: absolute; */
  width: 100%;
  height: 56px;
}

.single-category {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  height: 56px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.inner-single-category {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0px;
  grid-gap: 16px;
  gap: 16px;
  /* background-color: black; */
  /* height: 45px; */
  width: auto;
  /* background: #FED301;
    border: 1px solid #FED301; */
  border-radius: 100px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-size: medium;
  margin: 4px;
  margin-left: 400;
  font-family: "Playfair Display";
  /* margin-bottom: 200px; */
}

.inner-single-category :hover a {
  background-color: #fed301;
}

.active-item {
  background-color: #fed301 !important;
}

/* .inner-single-category :hover{
      background-color: #FED301 ;
    } */
.img-inner-category {
  width: 48px;
  height: 48px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.category-product-name {
  width: auto;
  height: 24px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 37px;
  display: flex;
  color: #fff;
}

.bg {
  background-color: #2d5c48;
}

.bg-white {
  background-color: white;
}

.inner-category {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  height: 56px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.category-left-next {
  left: -webkit-calc((93.3vw - 1160px)/2);
}

.category-left-arrow {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0px;
  left: 20px;
  background: #fff !important;
  border-radius: 50%;
  position: relative;
  top: 14px;
  transform: translateY(-50%);
  height: 34px;
  /* z-index: 2; */
}

.category-left-arrow .fa {
  color: #2d5c48;
  font-size: 30px;
}

/* .category-right-next{
    
        right: -webkit-calc((91vw - 1160px)/2);
      
      } */

.category-right-arrow .fa {
  color: white;
  font-size: 30px;
}

.category-right-arrow {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0px;
  background: #fff;
  font-weight: 500;
  position: relative;
  transform: translateY(-50%);
  z-index: 2;
  opacity: 1;
  right: -98%;
  /* left: 17px; */
  top: -120px;
}

.sticky-subheader-outer {
    width: 100%;
    height: 63px;
    background-color: #2f4e49;
    color: white;
    position: -webkit-sticky;
    position: sticky;
    position: relative;
    top: 80px;
    left: 0;
    border-radius: 6px;
    z-index: 1;

}
.sticky-subheader-outer-over{
  width: 100%;
  height: 63px;
  background-color: #2f4e49;
  color: white;
  position: -webkit-sticky;
  position: sticky;
  position: relative;
  top: 137px;
  left: 0;
  border-radius: 6px;
  z-index: 1;
}




.main-outer-category {
  overflow: auto;
  height: 100px;
  position: relative;
}


.CateScroll {
  position: fixed;
  top: 0;
  left: 0;
  /* background-color: #F7F7F7; */
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 5;
  margin-top: -24px;
  margin-bottom: -36px;
  /* padding-top:5px */
}

section {
  padding: 0px !important;
}


@media only screen and (min-width: 320px) and (max-width: 576px) {

  .sticky-subheader-outer-over{
    top: 71px;
  }

   .sticky-subheader-outer{
      left: 0%;
      right: 0%;
      top: 25px;
    }

  .outer-category {
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 10px;
    top: -6px;
    width: 100%;
    left: 0px;
    height: 46px;

       /* Custom scrollbar styling for WebKit browsers */
       scrollbar-width: thin;  /* For Firefox */
       scrollbar-color: #ffffff #f0f0f0;  /* For Firefox */
     
       /* For WebKit browsers (Chrome, Safari) */
       &::-webkit-scrollbar {
         width: 0px;
         display: none ;
       }
     
       &::-webkit-scrollbar-thumb {
         background-color: #fffefe;
         display: none;
       }
     
       &::-webkit-scrollbar-track {
         background-color: #f0f0f0;
         display: none;
       }
  }



  .category-right-arrow {
    top: -76px;
    right: -94%;
  }
  .outer-category{
    top: -40px;
    width: 85%;
    left: 25px;
  }

  .category-left-arrow {
    top: 3px;
    left: 10px;
    z-index: 89898;
   
  }
  .category-left-arrow .fa {
    font-size: 18px;
  }

  .category-right-arrow .fa{
    font-size: 18px;
  }

  .single-category
  {
    padding: 0px 12px 5px 5px;
  }
  .sticky-subheader-outer {
    border-radius: 0px !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 778px) {
  .outer-category {
    overflow-x: auto;
    margin-top: 1px;
    margin-left: -14px;
    margin-bottom: 44px;
       /* Custom scrollbar styling for WebKit browsers */
       scrollbar-width: thin;  /* For Firefox */
       scrollbar-color: #ffffff #f0f0f0;  /* For Firefox */
     
       /* For WebKit browsers (Chrome, Safari) */
       &::-webkit-scrollbar {
         width: 0px;
         display: none ;
       }
     
       &::-webkit-scrollbar-thumb {
         background-color: #fffefe;
         display: none;
       }
     
       &::-webkit-scrollbar-track {
         background-color: #f0f0f0;
         display: none;
       }
  }

   .sticky-subheader-outer{
      left: 0%;
      right: 0%;
      top: 68px;
    }

  .category-right-arrow  {
   top: -164px;
   right: -97%;
  }

  .category-left-arrow {
   left: 4px;
  }
}

@media only screen and (min-width: 780px) and (max-width: 982px) {
  .outer-category {
    overflow-x: auto;
    margin-top: 1px;
    margin-bottom: 44px;
    margin-left: -14px;
  
    /* Custom scrollbar styling for WebKit browsers */
    scrollbar-width: thin;  /* For Firefox */
    scrollbar-color: #ffffff #f0f0f0;  /* For Firefox */
  
    /* For WebKit browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
      width: 0px;
      display: none ;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #fffefe;
      display: none;
    }
  
    &::-webkit-scrollbar-track {
      background-color: #f0f0f0;
      display: none;
    }
  }

  .category-left-arrow {
    left: 4px;
  }

  .category-right-arrow{
  top: -164px;
right: -97%;
  }
  

}


@media only screen and (min-width:766px) and (max-width:991px){
  .sticky-subheader-outer{
    width: 93%;
    left:28px;
    top: 78px;
  }
  .sticky-subheader-outer-over{
    width: 95%;
    left:21px
  }
}


@media  (min-width: 1024px) and (max-width: 1219px) {
  .outer-feature {
    overflow-x: auto;
  }
  
  .category-left-arrow {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0px;
    left: 14px;
    background: #fff !important;
    border-radius: 50%;
    position: relative;
    top: 14px;
    transform: translateY(-50%);
    z-index: 2;
}
.category-right-arrow {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0px;
  background: #fff;
  font-weight: 500;
  position: relative;
  transform: translateY(-50%);
  z-index: 2;
  opacity: 1;
  /* right: 114%; */
  left: 97.5%;
  top: -119px;
}
.main-outer-category {
  background-color: #f7f7f7;
  margin-top: -27px;
}
.inner-category {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  height: 60px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  /* margin-top: 9px; */
}

.custom-mobile {
  /* background-color: beige; */
}
}
@media only screen and (min-width: 320px) and (max-width: 576px) {
  .custom-mobile {
    margin-left: 0px;
  }
  
  nav{
    max-height: 90px;
    top: 15px;
  }

  .sticky-subheader-outer-over{
border-radius: 0px;
  }
}


@media only screen and (max-width:300px)
{

  .sticky-subheader-outer-over {
    top: 89px;
  }

  .category-left-arrow{
   left: 5px;
  }

  .category-left-arrow .fa {
    font-size: 15px;
  }
  .category-right-arrow{
   right: -95%;
  }

  .category-right-arrow .fa {
    font-size: 15px;
  }

  .outer-category{
    overflow-x: auto;
    top: -54px;
    left: 30px;
   width: 80%;
    /* Custom scrollbar styling for WebKit browsers */
    scrollbar-width: thin;  /* For Firefox */
    scrollbar-color: #ffffff #f0f0f0;  /* For Firefox */
  
    /* For WebKit browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
      width: 0px;
      display: none ;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #fffefe;
      display: none;
    }
  
    &::-webkit-scrollbar-track {
      background-color: #f0f0f0;
      display: none;
    }
  }
}
.featured-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  padding-top: 4rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  color: #2d5c48;
  font-family: "Playfair Display";
}

.featured-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
}
.card-custom {
  /* padding: 15px; */
  padding: 1rem;
  /* border: 2px solid #f2f2f2; */
  border-radius: 5px;
  background: #fff;
  position: relative;
  z-index: 1;
  cursor: pointer;
  /* width: 450px; */
  height: 150px;
  overflow: hidden;
}
.featured-products-section {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.featured-products-static {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #f7f7f7;
}

.card-container {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  width: 343px;
  height: auto;
  padding: 2rem;
  border: 2px solid #f2f2f2;
  border-radius: 16px;
  justify-content: space-between;
}
.card-product-name {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 6px;
  margin: auto;
  align-items: center;
  font-family: "Playfair Display";
  white-space: nowrap;
  color: #2d5c48;
}
.card-product-price {
  font-family: Glacial Indifference Bold;
  /* color: #4d4d4d; */
  font-size: 16px;
  /* font-style: normal; */
  /* font-weight: 700; */
  line-height: 17.92px; /* 140% */
  font-style: normal;
  font-weight: 700;
  /* font-size: 16px; */
  /* line-height: 24px; */
  align-items: center;
  color: #364d49;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline-block;
}
.card-product-desc {
  color: #666;

  font-size: 12.8px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.92px; /* 140% */
  font-family: "Glacial Indifference";
  align-items: center;
  margin-bottom: 5px;
  min-height: 36px;
  max-height: 36px;
  overflow: hidden;
  margin-bottom: 0px;
}

/* .card-btn{
         background: #FFD400;
         border: none;
         font-size: 12.;
      
         font-style: normal;
         font-weight: 700;
         font-size: 12.8px;
         line-height: 20px;
      
         border-radius: 100px;
         height: 32px;
         width: 113px;
  } */
.card-fav {
  font-size: 26px;
}
.outer-heart {
  width: 42px;
  background: #f7f7f7;
  border-radius: 23px;
  /* margin: auto; */
  padding: -16px;
  display: flex;
  justify-content: center;
  padding: 8px;
}
/* .card-fav svg{
      font-size: 32px;
      color: black;
      padding: -15px;
      background: #F7F7F7;
      height: 22px;
      border-radius: 100px;
      margin-top: 6px;
  } */
.product-img {
  width: 100% !important;

  margin: auto;
}
.card-container:hover {
  /* box-shadow: -2px 5px 13px 0px rgba(214,0,0,0.59);
      -webkit-box-shadow: -2px 5px 13px 0px rgba(214,0,0,0.59);
      -moz-box-shadow: -2px 5px 13px 0px rgba(214,0,0,0.59); */
  cursor: pointer;
}
.featured-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  padding-top: 4rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  color: #2d5c48;
  font-family: "Playfair Display";
}
.outer-main-menu {
  background-color: #f7f7f7;
}
.outer-card {
  display: flex;
}
.combo-detail-heading {
  margin-left: 10px;
}
.combo-detail-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #2d5c48;
  margin-bottom: 9px;
  /* padding-top: 1rem; */
}

#fixedbutton {
  font-size: 24px;
  position: fixed;
  bottom: 25px;
  right: 25px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #364d49;
  border-radius: 50px;
  z-index: 1;
}
.p_sticky_view_cart {
  display: none;
  cursor: pointer;
  width: 100%;
  padding-right: 2rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
}
.p_sticky_view_cart_btn {
  padding: 0 20px;
  height: 45px;
  background-color: #364d49;
  color: #fff !important;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  align-items: center;
  display: none;
}
.view_basket_icon {
  position: relative;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 20px;
  line-height: 1.25rem;
}
.custom-default-green-button {
  background-color: black !important;
  right: -8px;
}
.badge-circle {
  position: absolute;
  top: -3px;
  width: 1.6rem;
  border-radius: 50%;
  color: #fff;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.6rem;
  font-family: "Open Sans", sans-serif;
  text-align: center;
}
.cart-hover {
  animation: shake 0.9s;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
@media only screen and (min-width: 320px) and (max-width: 559px) {
  #fixedbutton {
    display: none;
  }
  .p_sticky_view_cart {
    display: block;
    position: fixed !important;
    /* bottom: 0; */
    z-index: 2;
  }
}
.timeline-item {
  padding: 2rem;
  /* border: 2px solid #f2f2f2; */
  /* border-radius: 16px; */
  /* background: #fff; */
  position: relative;
  z-index: 1;
  cursor: pointer;
  width: 100%;
  min-height: 200px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 154px;
  position: relative;
  border-radius: 16px;
}

.background-masker {
  background: #fff;
  position: absolute;
}

/* Every thing below this is just positioning */

.background-masker.header-top,
.background-masker.header-bottom,
.background-masker.subheader-bottom {
  top: 0;
  /* left: 40px; */
  right: 0;
  height: -8px;
}

.background-masker.header-left,
.background-masker.subheader-left,
.background-masker.header-right,
.background-masker.subheader-right {
  top: 10px;
  left: 40px;
  height: -1px;
  width: 10px;
}

.background-masker.header-bottom {
  top: 18px;
  height: -4px;
}

.background-masker.subheader-left,
.background-masker.subheader-right {
  top: 24px;
  height: 0px;
}

.background-masker.header-right,
.background-masker.subheader-right {
  width: auto;
  left: 300px;
  right: 0;
  height: -13px;
}

.background-masker.subheader-right {
  left: 230px;
}

.background-masker.subheader-bottom {
  top: 30px;
  height: 10px;
}

.background-masker.content-top,
.background-masker.content-second-line,
.background-masker.content-third-line,
.background-masker.content-second-end,
.background-masker.content-third-end,
.background-masker.content-first-end {
  top: 40px;
  left: 0;
  right: 0;
  height: 0px;
}

.background-masker.content-top {
  height: 0px;
}

.background-masker.content-first-end,
.background-masker.content-second-end,
.background-masker.content-third-end {
  width: auto;
  left: 380px;
  right: 0;
  top: 60px;
  height: 8px;
}

.background-masker.content-second-line {
  top: 68px;
}

.background-masker.content-second-end {
  left: 420px;
  top: 74px;
}

.background-masker.content-third-line {
  top: 82px;
}

.background-masker.content-third-end {
  /* left: 300px; */
  top: 88px;
}

.font_custom_cart {
  font-family: Glacial Indifference;
}

@media only screen and (max-width: 576px) {
  .cart_bar {
    background-color: #364d49;
    z-index: 999;
    position: fixed;
    bottom: 6%;
    width: 95%;
    padding: 15px 15px;
    margin: 0px 6px;
    color: white;
    border-radius: 10px;
    transition: opacity 0.3s ease;
  }

  .cart_bar_new {
    background-color: #030303;
    z-index: 999;
    position: fixed;
    bottom: 6%;
    width: 95%;
    padding: 15px 15px;
    margin: 0px 6px;
    color: white;
    border-radius: 10px;
    transition: opacity 0.3s ease;
  }
  .margin_left_tab{
    margin-left: -3% !important;
  }
  .row-width-tablet{
    width: auto;
  }

  .card-product-name{
    font-size: 16px;
  }
}



.margin_left_tab{
  margin-left: -2.6%;
}

@media only screen and (min-width:1000px){
  .row-width-tablet{
    width: 100%;
  }
}

@media only screen and (min-width: 766px) and (max-width: 991px) {
  .card-product-name {
    font-size: 16px;
  }
  .card-product-desc {
    font-size: 12px;
  }

  .margin_left_tab {
    margin-left: 44%;
  }
}

@media only screen and (min-width: 800px) and (max-width: 850px) {
  .margin_left_tab {
    margin-left: 44%;
  }
  .row-width-tablet {
    max-width: 1000px !important;
    width: 760px !important;
  }
}

@media only screen and (min-width: 766px) and (max-width: 799px) {
  .margin_left_tab {
    margin-left: 42%;
  }
  .row-width-tablet {
    max-width: 1000px !important;
    width: 720px !important;
  }
}

@media only screen and (min-width: 866px) and (max-width: 990px) {
  .row-width-tablet {
    max-width: 1200px !important;
    width: 857px !important;
  }

  .margin_left_tab {
    margin-left: -4%;
    margin-right: -48%;
  }
}

@media only screen and (max-width: 300px) {
  .card-product-name {
    font-size: 12px;
  }
  .card-product-desc {
    font-size: 10px;
  }

  .card-product-price {
    font-size: 12px;
  }
}

.margin_top_mobile_category{
    margin-top: 12%;
    margin-bottom: 5%;
}

@media only screen and (max-width:300px)
{ 

  .margin_top_mobile_category{
    margin-top: 36%;
    margin-bottom: 5%;
}

}
.product_image {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  width:100%;
  height: 221px;
  object-fit: cover;
  position: center;
}

.product_name {
  color: #364d49;
  font-family: "Playfair Display";
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
}

.product_price {
  color: #364d49;
  font-family: Glacial Indifference Bold !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.modal_content {
  display: flex;
  flex-direction: column;
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
    /* Hide scrollbars for Chrome, Safari, and Edge */
    -webkit-scrollbar-width: 0px;
    -moz-scrollbar-width: 0px;
  
    /* Hide scrollbars for IE, Edge, and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.prodcut_short_desc {
  color: #272323;
  font-family: "Glacial Indifference";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.algenre_items {
  display: flex;
  flex-direction: column;
}

.algenre_heading {
  color: #364d49;
  font-family: "Playfair Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.subItem_container{
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
    flex-wrap: wrap;
}
.subItem_wrapper{
    display: flex;
    flex-direction: row;
    grid-gap: 5px;
    gap: 5px;
}

.icon_filled{
    font-size: 20px;
    color: #364D49;
  border-radius: 50%;
}

.subitem_heading{
    color: #272323;
font-family: "Glacial Indifference";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.extra_heading{
    color: #364D49;
font-family: "Playfair Display";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}


.extra_container{
    display: flex;
    flex-direction: column;
}
.extra_item_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.checkbox_container{
    display: flex;
    align-items: center;
grid-gap: 8px;
gap: 8px;
}

.checkbox_container .icon_empty{
    color: #aaaaaa;
    font-size: 25px;
    cursor: pointer;
}

.extra_item_heading{
    color: #364D49;
font-family: "Glacial Indifference";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.extra_item_price{
    color: #364D49;
    font-family: Glacial Indifference Bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.cart_wrapper{
    border-radius: 0px 0px 20px 20px;
background: #FFF;
box-shadow: 0px -6px 18px 0px rgba(176, 176, 176, 0.25);
display: flex;
flex-direction: row;
justify-content: space-between;
grid-gap: 10px;
gap: 10px;
padding: 10px 8px;
}

.button_wrapper{
    display: flex;
    flex-direction: row;
    grid-gap: 18px;
    gap: 18px;
    align-items: center;
}

.increment{
    width: 35px;
    height: 35px;
}

.cart_title{
    color: #2C2121;
font-family: "Glacial Indifference";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.button_cart{
display: flex;
flex-direction: row;
width: 220px;
padding: 16px 40px;
justify-content: center;
align-items: center;
grid-gap: 10px;
gap: 10px;
border-radius: 14px;
background: #364D49;
color: #FFF;
font-family: "Glacial Indifference";
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
white-space: nowrap;
border: none;
}

.closeIcon{
    width: 40px;
height: 40px;
background-color: #d3ddd9;
border-radius: 50%;
position: absolute;
display: flex;
justify-content: center;
align-items: center;
right: 3%;
top: 0%;
}

.closeIcon .icon_close{
    color: #364D49;
    font-size: 25px;
}


@media only screen and (min-width:766px) and (max-width:991px){
    .closeIcon{
        right: 3%;
        top: 1%;
    }
}


@media only screen and (max-width:600px){
    .closeIcon{
        right: 2.5%;
        top: 2%;
    }

    .algenre_heading{
        font-size: 18px;
    }
    .extra_heading{
        font-size: 18px;
    }

    .increment{
        width: 30px;
        height: 30px;
    }

    .button_cart {
        width: 195px;
        padding: 13px 40px;
    }

    .product_image{
        border-radius: none;
    }
}
.slider-main-section {
    background-color: #2f4e49 !important;
    padding-top: 2rem !important;
    padding-bottom: 3rem !important;
  }
  .owl-carousel .owl-item img {
    display: block;
    width: 80% !important;
    margin: auto !important;
    /* border-radius: 37px !important; */
  }
  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1.2 !important;
    display: inline;
  }
  .owl-theme .owl-dots .owl-dot span:before {
    background-color: #d60000 !important;
  }
  .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    border-color: #d60000;
    background: transparent;
  }
  
  @media only screen and (max-width: 375px) {
    .slider-inner-div {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  @media only screen and (max-width: 414px) {
    .slider-inner-div {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  @media only screen and (max-width: 540px) {
    .slider-inner-div {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .upper-slider-button {
    border-color: #2f4e49;
    border-radius: 5px;
    height: 43px;
    display: flex;
    flex-direction: row;
    width: auto;
  
    /* height: ; */
  }
  
  .upper-slider-button1 {
    border-color: #2f4e49;
    border-radius: 6px;
    height: 43px;
    display: flex;
    flex-direction: row;
    width: auto;
  
    /* height: ; */
  }
  .upper-slider-button:hover {
    background-color: #edf8f6 !important;
  }
  .button-content {
    position: relative;
    left: -0px;
    top: -6px;
  }
  .mode-button-body {
    font-family: "Playfair Display";
    text-transform: capitalize;
  }
  
  .custom-mode-buttons {
    position: relative;
    left: -15px;
  }
  .hero-slider {
    position: relative;
    max-width: 1800px;
    border-radius: 20px;
    height: 400px;
    /* background: #e8e8e8; */
    overflow: hidden;
    border: none;
    box-shadow: none;
  }
  .hero-content{
    margin-top: 62px;
  }
 
  .accept-button-homeSlider {
    display: flex;
    height: 40px;
    padding: 11.142px 35.826px;
    align-items: flex-start;
    grid-gap: 8.142px;
    gap: 8.142px;
    border-radius: 4.071px;
    background: #364d49;
    color: #FFF;
    text-align: center;
    font-family: Times New Roman;
    font-size: 17.384px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.384px;
    text-transform: capitalize;
    border: none;
    
  }
  .slider-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-blend-mode: multiply;
    object-fit: cover;
  }



  .custom-content{
    margin-top: 8%;
    margin-left: 5%;
  }

  .margin_small_device{
    margin-top: 6%;
    margin-left: 5%;
  }
  
  .slider-item.active {
    opacity: 1;
  }
  .banner_title {
    width: 80%;
    height: 46.849px;
    color: #ffffff;
    font-family: 'Playfair Display', serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.721px;
    text-transform: capitalize;
    margin-bottom: 0px;
  }
  .banner_title_custom {
    width: 90%;
    height: 46.849px;
    color: #ffffff;
    font-family: 'Playfair Display', serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.721px;
    text-transform: capitalize;
    margin-bottom: 60px;
  }
  
  
  
  .banner_description {
    width:60%;
    margin-top: 24px;
    color: #ffffff;
    font-family: Glacial Indifference;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
  
    /* line-height: 19.864px; */
  }
  .banner-image {
    width: 179.052px;
    height: 179.052px;
    position: relative;
    left: 20px;
    /* height: 204px; */
    /* top: -15px; */
    /* width: 204px; */
    border-radius: 4.389px;
    /* background: url(<path-to-image>), lightgray 0px 0px / 100% 100% no-repeat; */
  }
  
  .hero-slider-stamp {
    position: relative;
    width: 590px;
    height: 254px;
    left: 0px;
    top: calc(50% - 254px / 2);
    border-radius: 0px;
    overflow: hidden;
    background: #364d49;
  }
  
  .banner_description_stamp {
    width: 265px;
    height: 48px;
    left: 34px;
    top: 111px;
    font-family: "Times New Roman";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
    /* or 111% */
    display: flex;
    align-items: center;
    letter-spacing: -0.164117px;
    color: #ffffff;
  }
  .slider-item-stamp.active {
    opacity: 1;
    margin-top: 18px;
  }
  .banner-button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 25px;
    position: absolute;
    /* height: 37px; */
    left: 10.5px;
    right: 407.5px;
    width: 142px;
    /* top: 174px; */
    background: #ffffff;
    border-radius: 6px;
    font-family: "Times New Roman";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-transform: capitalize;
    cursor: pointer;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    top: 110px;
  }
  .stamp-image {
    /* width: 255.06px; */
    /* height: 256.31px; */
    left: 320px;
    margin-left: 10px;
    border-radius: 10px;
    /* top: calc(50% - 256.31px/2 + 18.16px); */
  }
  .banner-row {
    display: contents;
  }
  .slider-container {
    display: flex;
    position: relative;
    width: 1200px;
    margin: auto;
    border: none;
    box-shadow: none;
  }
  
  .slider-container-b {
    display: flex;
    position: relative;
    width: 1200px;
    margin: auto;
  }
  

  .dots-container{
    width: 20%;
  }

  .order_button{
    display: flex;
    padding: 10px 32px;
justify-content: center;
align-items: center;
border-radius: 8px;
border: none;
background: #FFF;
color: #364d49;
font-family: Glacial Indifference Bold;
font-size: 16px;
position: absolute;
font-weight: 800;
top: 65%;
left: 8%;
  }

  .mt_cusotm_button{
    top: 76%;
  }

  
  @media only screen and (min-width:766px) and (max-width:991px)
  {
    .banner_title_custom{
      font-size: 32px;
    }
  }
  
  @media (max-width: 593px) {
    .banner-row {
      display: flex;
    }
    
  
    .dot {
      background-color: rgb(185, 185, 185) !important;
    }
    .dot.active {
      background-color: #364d49 !important;
    }
    .custom-banner-mobile {
      margin-left: 0px !important;
      padding-left: 0px !important;
    }
    .slider-container {
      display: block;
      padding: 0px 0px !important;
      /* margin-top: 25% !important; */
      margin: 0%;
     
    }
  
    .slider-container-b{
      display: block;
    }
  
  
    .web-mobile-app-install {
      display: none;
    }
    .banner-button {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 8px 35px;
      position: absolute;
      /* height: 37px; */
      left: 8.5px;
      right: 407.5px;
      /* width: 194px; */
      width: 141px;
      top: 105px;
      background: #ffffff;
      border-radius: 6px;
      font-family: "Times New Roman";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      text-transform: capitalize;
    }
    .banner_title {
      font-size: 30px;
    }
    .banner-image {
      height: 190px !important;
      width: 190px !important;
      margin-right: 10px !important;
      /* left: 0px !important;
      top: -10px !important;*/
    } 
    .banner_description {
      /* width: 160px;
      text-align: left; */
    }
    .banner_description_stamp {
      width: 190px;
      text-align: left;
      height: 48px;
      left: 34px;
      top: 111px;
      font-family: "Times New Roman";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: -0.164117px;
      color: #ffffff;
    }
    .hero-slider-stamp {
      position: relative;
      width: 100%;
      height: 200px;
      left: 0px;
      top: 0px;
      border-radius: 10.1508px;
      overflow: hidden;
      background: #364d49;
    }
    .hero-slider {
      position: relative;
      width: 100%;
      height: 185px;
      left: 0px;
      background: #e8e8e8;
  
      overflow: hidden;
      top: -5px;
    }
    .container {
      left: 0px !important;
    }
    .top-left {
      position: absolute;
      top: 8px;
      left: 35px;
      font-size: large;
    }
    .mode-slider-button1 {
      border-color: #2f4e49;
      border-radius: 5px 0px 0px 5px;
      height: 43px;
      display: flex;
      flex-direction: row;
      width: 180px;
    }
    .mode-slider-button2 {
      border-color: #2f4e49;
      border-radius: 0px 5px 5px 0px;
      height: 43px;
      display: flex;
      flex-direction: row;
      width: 190px;
    }
    .dinein-body {
      margin-left: 35px;
    }
    .cart-sticky {
      display: none;
    }
    body {
      /* overflow: hidden !important; */
    }
    .banner-row {
      margin: 0px !important;
    }
    .custom-mode-buttons {
      left: 10px;
    }
    /* .dots-container {
      left: 35% !important;
      top: 0px !important;
    } */
  }
  
  
  @media only screen and (min-width:530px) and (max-width:590px)
  {
    .slider-container-b{
      margin-top: 25% !important;
    }
  
  
  
  }
  
  @media (min-width: 993px) and (max-width: 1200px) {
  
    .hero-slider-stamp {
      width: 450px;
    }
    .banner_description_stamp {
      width: auto;
    }

    .banner_description {
      width: 80%;
    } 

    .order_button{
left: 11%;
    }

    .slider-container{
      width: 960px;
     
    }

    .slider-container-b{
      width: 960px;
    }

    .margin_small_device{
      margin-top: 8%;
    }

    .custom-content{
      margin-top: 8%;
    }

    .banner_title {
      font-size: 40px;
      width: 100%;
    }
    .banner_title_custom{
        font-size: 40px;
    }
    .banner-image {
      left: 0px;
    }

    .dots-container {
      left: 42% !important;
    }
  
  }

  @media only screen and (min-width:901px) and (max-width:950px)
  {
    .slider-container{
width: 850px;
margin-top: 5.5rem !important;
    }

    .banner_title_custom{
      font-size: 38px;
    }

    .banner_title{
      font-size: 38px;
      width: 100%;
    }

    .banner_description{
      width: 90%;
      margin-top: 1rem;
    }

    .slider-container-b{
      width: 850px;
      margin-top: 8.5rem !important;
    }

    .margin_small_device{
      margin-top: 12%;
    }

    .custom-content{
      margin-top:12%;
    }

    .order_button{
left: 12% !important;
    }
  }

  @media (min-width: 601px) and (max-width: 900px) {
    .mobile-header {
      display: none;
    }
    .mobile-app-install-banner {
      display: none;
    }
    .banner-row {
      display: block;
    }
    .hero-slider {
      width: 100%;
      left: 0px !important;
      right: 0px;
      height: 300px;
    }
    .hero-slider-stamp {
      width: auto;
    }
    .slider-container {
      position: initial;
      display: block;
      width: 700px;
    }
    .custom-content{
        margin-top: 10%;
        margin-left: 5%;
    }

    .margin_small_device{
        margin-top: 4%;
        margin-left: 5%;
      }

    

    
  .banner_description{
    width: 100%;
  }
    .slider-container-b{
      width: 700px;
    }
    .banner_title{
      width: 90%;
      font-size: 32px !important;
      margin-bottom: -10px;

    }
  .banner_title_custom {
    width: 86%;
    margin-bottom: 30px;
  }
    
  }

  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    margin: 5px;
    cursor: pointer;
  }
  
  .dot.active {
    background: #364d49;
  }
  
  .dot.unactive{
    background-color: #d7dbda;
  }
  
  .slider-navigation {
    /* position: relative; */
    z-index: 2;
    cursor: pointer;
    /* z-index: 1; */
  }
 
  .fa-angle-right {
    font-size: 20px;
    width: 130%;
  }
  .fa-angle-left {
    font-size: 20px;
    width: 130%;
  }
  .card-custom-banner {
    /* padding: 15px; */
    padding: 1rem;
    /* border: 2px solid #f2f2f2; */
    border-radius: 5px;
    background: #fff;
    position: relative;
    z-index: 1;
    cursor: pointer;
    /* width: 450px; */
    height: 124px;
  }
  /* ... */
  
  
  .icon_class{
      width: 40px;
      height: 95px !important;
      background-color:rgba(46, 44, 44, 0.40);
      color: #ffffff;
      /* border-radius: 50%; */
      margin: 0px 10px;
  }
  
  
  
  @media only screen and (max-width:1200px) and (min-width:991px)
  {
    .slide-left{
  left: -4% !important;
    }

    .slide-right{
        right: -1% !important;
          }

          .custom-content{
            margin-top: 13%;
          }
  
  }
  
  @media only screen and (max-width:840px) and (min-width:700px)
  {

    .order_button{
        left: 13%;
      }

    .slide-left{
  left: -4.5% !important;
  top: 33% !important;
    }
.slide-right{
    right: -1% !important;
    top: 33% !important;
}

  
  }
  
  @media only screen and (min-width:500px) and (max-width:600px)
  {
    .slide-left{
        left: 18% !important;
        display: none !important;
          }
          .slide-right{
        right: 15% !important;
        display: none !important;
          }

          .margin_small_device{
            margin-top: 5%;
          }
      
          .custom-content{
              margin-top: 7% !important;
          }
      
          
        
          .banner_title{
            margin-bottom: -35px !important;
            font-size: 18px !important;
            width: 100% !important;
            margin-left: 0px !important;
            white-space: nowrap;
            padding-left: 16px !important;
          }
        
          .banner_title_custom {
           margin-bottom: -10px !important;
            font-size: 18px !important;
            width: 80% !important;
            margin-left: 0px !important;
            padding-left: 16px !important;
        
        }
        
          .banner_description{
            padding-bottom: 0% !important;
             margin-left: 0px !important;
             width: 100%;
             font-size: 12px !important;
             padding-left: 16px !important;
          }
        
          .dots-container{
            top: 112% !important;
            width: 26% !important;
            left: 35.5% !important;
          }
      
          .slider-container{
              margin-top: 8rem !important;
              width: 100%;
          }

          .dots-container{
          
        }

        .order_button{
            left: 9%;
            padding: 8px 24px;
            font-size: 14px;
            top: 100%;
          }
  }
  
  @media only screen  and  (min-width:380px) and (max-width:500px)   {



    .custom-content{
        margin-top: 10% !important;
        margin-left: -2%;
    }

    .margin_small_device{
        margin-top: 6% !important;
        margin-left: -2%;
    }

  
  
    .banner_title{
      margin-bottom: -32px !important;
      font-size: 24px !important;
      width: 100% !important;
      margin-left: 0px !important;
      white-space: nowrap;
      padding-left: 16px !important;
    }
  
    .banner_title_custom {
     margin-bottom: -5px !important;
      font-size: 24px !important;
      width: 90% !important;
      margin-left: 0px !important;
      padding-left: 16px !important;
  
  }
  
    .banner_description{
      padding-bottom: 0% !important;
       margin-left: 0px !important;
       width: 100%;
       font-size: 16px !important;
       padding-left: 16px !important;
    }
  
    .dots-container{
      top: 85% !important;
      width: 26% !important;
      left: 35.5% !important;
    }

    .slider-container{
        margin-top: 7.2rem !important;
        width: 100%;
        margin-bottom: -3.5rem !important;
    }

    .slider-container-b{
      margin-top: 13.0rem !important;
      width: 100%;
      margin-bottom: -8.5rem !important;
    }


    .slide-left{
      display: none !important;
    }

    .slide-right{
      display: none !important;
    }

    .hero-slider{
      border-radius: 0px;
      height: 220px !important;
    }

    .order_button{
        left: 9%;
        padding: 6px 15px;
        font-size: 14px;
        top: 100%;
        letter-spacing: 0.3px;
      }
      .mt_cusotm_button{
        top: 100%;
      }
  }
  
  
  @media only screen and (min-width:320px) and (max-width:378px)
  {
    .custom-content{
      margin-top: 10% !important;
      margin-left: -2%;
  }

  .margin_small_device{
      margin-top: 6% !important;
      margin-left: -2%;
  }



  .banner_title{
    margin-bottom: -32px !important;
    font-size: 24px !important;
    width: 100% !important;
    margin-left: 0px !important;
    white-space: nowrap;
    padding-left: 16px !important;
  }

  .banner_title_custom {
   margin-bottom: -5px !important;
    font-size: 24px !important;
    width: 100% !important;
    margin-left: 0px !important;
    padding-left: 16px !important;

}

  .banner_description{
    padding-bottom: 0% !important;
     margin-left: 0px !important;
     width: 100%;
     font-size: 16px !important;
     padding-left: 16px !important;
  }

  .dots-container{
    top: 85% !important;
    width: 26% !important;
    left: 35.5% !important;
  }

  .slider-container{
      margin-top: 7.5rem !important;
      width: 100%;
      margin-bottom: -3.5rem !important;
  }

  .slider-container-b{
    margin-top: 13.0rem !important;
    width: 100%;
    margin-bottom: -8.5rem !important;
  }

  .slide-left{
    display: none !important;
  }

  .slide-right{
    display: none !important;
  }

  .hero-slider{
    border-radius: 0px;
    height: 220px !important;
  }

  .order_button{
      left: 9%;
      padding: 6px 15px;
      font-size: 14px;
      top: 100%;
      letter-spacing: 0.3px;
    }
    .mt_cusotm_button{
      top: 100%;
    }
  }
  
  
  @media only screen and (max-width:300px)
  {

    .dots-container{
        display: none !important;
    }
    .slide-left{
      left: 10% !important;
      display: none !important;
        }
        .slide-right{
      right: 1% !important;
      display: none !important;
        }
      
        .banner_title{
          margin-bottom: -35px !important;
          font-size: 16px !important;
          width: 100% !important;
          margin-left: 0px !important;
          white-space: nowrap;
          padding-left: 16px !important;
        }
      
        .banner_title_custom {
         margin-bottom: 5px !important;
          font-size: 16px !important;
          width: 90% !important;
          margin-left: 0px !important;
          padding-left: 16px !important;
      
      }
      
        .banner_description{
          padding-bottom: 0% !important;
           margin-left: 0px !important;
           width: 100%;
           font-size: 10px !important;
           padding-left: 16px !important;
        }
      
        .dots-container{
          top: 111.8% !important;
          width: 34%;
          left: 34.5% !important;
        }
  
        .banner-image{
          height: 150px;
          width: 200px;
          object-fit: cover !important;
        }
  
        .slider-container-b{
          margin-top: 46% !important;
        }
        .hero-slider{
          height: 145px;
        }
        .banner_description{
          padding-bottom: 38% !important;
        }
  }
.notFoundContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.customizeBtn{
    padding: 0.7em 3em !important;
    border-radius: 25px !important;
    text-transform: capitalize;
    font-weight: inherit;
    font-size: 15px !important;
}
.paragraphDiv{
    text-align: center;
    width: min(69%, 100%);
    font-size: 15px !important;
}

.form-gap {
    padding-top: 70px;
  }
  
  .login-form-1 {
    max-width: 300px;
    border-radius: 5px;
    display: inline-block;
  }
  
.order-heading {
    text-align: center;
    /* padding: 2rem; */
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 32px;


    letter-spacing: -0.02em;
    color: #1A1A1A;

}

.clock-icon {
    width: 24px;
    height: 24px;
    left: 0px;
    font-size: 23px;
    color: black;
}

.outer-order-type {
    display: flex;
    justify-content: center;
}

.order-type-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    z-index: 1;
    display: flex;
    align-items: center;
    text-align: center;
    color: #1A1A1A;
    padding: 14px 16px;
    cursor: pointer;
}

.order-type-text:hover {
    border-bottom: 3px solid #2d5c48;
}

.act {
    border-bottom: 3px solid #2d5c48;
}

.main-order-details {
    background-color: #fff;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    padding: 2rem
}

.order-number {
    display: flex;
    justify-content: space-between;
}

.order-text {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 32px;

    text-align: center;
    letter-spacing: -0.02em;
    color: #1A1A1A;

}

.order-number-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #666666;
}

.outer-delivery-image {
    display: flex;
    justify-content: center;
}

.delivery-image-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding-top: 1rem;
    color: #666666;
}

.delivery-time {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #1A1A1A;
}

.custom-width {
    width: 50%;
    margin: auto;
    margin-bottom: 22px;
    border-radius: 14px;
}

.sub-details {

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 150% */
    display: flex;
    align-items: center;
    color: #666666;
}

.sub-total {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #1A1A1A;
    font-family: "Playfair Display";
}

.current-order-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #666666;
}

.order-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 9px;
    grid-gap: 24px;
    gap: 24px;
    width: auto;
    height: 122px;
    background: #FFFFFF;
    /* border: 2px solid #FED301; */
    border-radius: 16px;
    padding-bottom: 23px;
    padding-top: 75px;
}

.order-card:hover {
    /* padding-bottom: 23px; */
    cursor: pointer;
    /* display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 9px;
gap: 24px;
width: auto;
height: 190px;
background: #FFFFFF; */
    border: 2px solid #2d5c48;
    /* border-radius: 16px; */
}

.order-card-left-side {

    padding: 0px;
    grid-gap: 20px;
    gap: 20px;

    width: 131px;
    height: 110px;

}

.order-card-price {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 0px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1A1A1A;
    padding-bottom: 1px
}

.order-card-time {
    font-style: normal;
    font-weight: 400;
    font-size: 12.8px;
    /* line-height: 20px; */
    color: #999999;


}

.order-card-total-price {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;


    color: #666666;

}

.card-ovel {
    width: 55px;
    height: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #D60000;
}

.past-orders {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;


    color: #666666;

}

.reorder {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #D60000;
}
[data-toggle="collapse"].acordeon-toggle:after{
    content: '/`\\';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #1A1A1A;
    transition: all 0.5s;
}


[data-toggle="collapse"].collapsed.acordeon-toggle:after{
    
    
    color:#1A1A1A;
    content: " \\./";
    padding-bottom: 4px;
    padding-top: 4px;
    border-radius: 50%;
}
.scrollModify{
    max-height: calc(calc(80vh - (24px * 2) - (73px + 73px)) - 50px);

overflow-y:auto;
}
@media (max-width: 600px) {
    .mobile-order-div {
        margin-top: 80px !important;
    }
}
.main-order {
  height: 249px;
  width: 589px;
  margin-top: 3%;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  background: #fff;
  box-shadow: 0px 6px 18px 0px rgba(190, 189, 189, 0.25);
  padding-left: 0px;
  padding-top: 4px;
  padding-bottom: 10px;
}

.order_card_warpper{
  height: 50vh;
  overflow-y: auto;
}

.order_card_warpper::-webkit-scrollbar {
  width: 12px; 
}

.order_card_warpper::-webkit-scrollbar-thumb {
  background-color: #364D49; 
  border-radius: 6px; 
}

.order_card_warpper::-webkit-scrollbar-track {
  background-color: #ffffff; 
}


.main-section {
  border-radius: 10px;
  border: 1px solid #a5a1a1;
  width: 996px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
padding-top: 2%;
padding-bottom: 70px;

}

.newOrder {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heading-tp {
  color: var(--Primary-color, #364d49);
  font-family: Playfair Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center !important;
}

.right-text {
  color: var(--grey-grey-300, #6a6d6c);
  font-family: Playfair Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.left-text {
  color: var(--Neutral-Color, #33363b);
  font-family: Glacial Indifference;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.section_padding {
  padding: 10px 15px;
}

.header-section {
  padding: 10px 15px;
}

.detail-btn {
  display: flex;
  width: 250px;
  height: 35px;
  padding: 12px 125px;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  flex-shrink: 0;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #364d49;
  color: #33363b;
  white-space: nowrap;
  font-family: Glacial Indifference;
}

.order-btn {
  display: flex;
  width: 250px;
  height: 35px;
  padding: 12px 125px;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #364d49;
  color: #fff;
  white-space: nowrap;
   font-family: Glacial Indifference;
   border: none;
}

.order-btn:hover{
    color: white;
    background-color: #364d49;
}

.scroll-class {
  overflow-y: auto;
}

.modal-body {
  max-height: 689px;
  overflow-y: auto;
}

.custom-modal-body {
  max-height: 550px; /* Adjust as needed */
  overflow-y: auto;
  scrollbar-width: none;
  padding: 0px 16px;
}
.custom-modal-body::-webkit-scrollbar {
  display: none; /* Hide the scrollbar in WebKit browsers (Chrome, Safari, etc.) */
}

.hr-class {
  padding: 4px 0px !important;
  margin-top: 10px;
}



.text-left {
  color: var(--grey-grey-300, #6a6d6c);
  font-family: Playfair Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-right {
  color: var(--Neutral-color, #212524);
  font-family: Glacial Indifference;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px;
}

.close-s {
  top: 0.5% !important;
  left: 89%;
  z-index: 999;
  cursor: pointer;
}

.text-place-right {
  grid-gap: 40px;
  gap: 40px;
}

.special-text {
  color: #1e950b !important;
}

.price {
  font-weight: 700 !important;
}

.text-items-left {
  color: var(--grey-grey-200, #989b9a);
  font-family: Glacial Indifference;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 4%;
}
.text-items-right {
  color: var(--grey-grey-200, #989b9a);
  font-family: Glacial Indifference;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sec_title {
  color: var(--Primary-color, #364d49);
  font-family: Playfair Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.section_custom {
  padding: 8px 0px;
}

.back-btn {
  align-self: self-start;
  margin-left: 8%;
margin-top: 7%;
}

.detial-modal-padding{
  padding: 0px 16px !important;
}


.close-modal-detail{
  background-color: #364D49;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 2%;
  border-radius: 50%;
  color: white;
  font-size: 25px;
  right: 2%;
}

.instuction_checkout{
  color: var(--Neutral-Color, #33363b);
  font-family: Glacial Indifference;
  font-size: 18px;
  font-style: no1rmal;
  font-weight: 400;
  line-height: normal;
}

.empty_title{
  color: #364D49;
text-align: center;
font-family: "Playfair Display";
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
width: 70%;
}

.empty_button{
  display: flex;
width: 285px;
padding: 16px 60px;
justify-content: center;
align-items: center;
grid-gap: 10px;
gap: 10px;
border-radius: 14px;
background: var(--Primary-color, #2F4E48);
color: #FFF;
font-family:Glacial Indifference;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.empty_button:hover{
  color: #FFF;
}

@media only screen and (min-width:766px) and (max-width:991px){
  .main-section{
    width: 657px;
  }

  .order_card_warpper{
    height: 60vh;
    overflow-y: scroll;
  }
}


@media only screen and (max-width: 600px) {
  .custom-modal-body {
    max-height: 420px;
    padding: 0px 6px;
  }

  .detial-modal-padding{
    padding: 0px 4px !important;
  }

  .newOrder {
    justify-content: start;
  }

  .back-btn {
    margin-left: 0%;
    margin-top: 12%;
  }

  .heading-tp {
    text-align: start !important;
    font-size: 20px;
    padding-left: 5px;
  }

  .main-order {
    width: 358px;
    height: 257px;
  }

  .section_padding {
    padding: 15px 15px;
  }

  .detail-btn {
    display: flex;
    width: 156px;
    height: 35px;
    padding: 12px 80px;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    flex-shrink: 0;
  }

  .order-btn {
    display: flex;
    width: 156px;
    height: 35px;
    padding: 12px 80px;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    flex-shrink: 0;
  }

  .right-text {
    font-size: 14px;
  }
  .left-text {
    font-size: 14px;
  }

  .mobile-order {
    justify-content: space-between;
  }

  .text-right {
    font-size: 14px;
    white-space: nowrap;
  }

  .text-left {
    font-size: 14px;
    white-space: nowrap;
  }

  .react-responsive-modal-modal {
    padding: 10px 16px;
  }
  .react-responsive-modal-closeButton {
    top: 3px;
    right: 38px !important;
  }

  .close-s {
    top: 0.5% !important;
  }

  .main-section {
  border-radius: 10px;
  border: 0px solid #a5a1a1 !important;
  width: 365px;
  padding-top: 0%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
  flex-shrink: 0;

}

.order_card_warpper{
  height: 70vh;
  overflow-y: scroll;
}

.instuction_checkout{
  color: var(--Neutral-Color, #33363b);
  font-family: Glacial Indifference;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.empty_title{
font-size: 16px;
}

.empty_button{
  font-size: 16px;
  padding: 12px 40px;
}

}


@media only screen and (min-width:385px) and (max-width:410px)
{
  .main-section {
    border-radius: 10px;
    border: 0px solid #a5a1a1 !important;
    width: 365px;
    height: 75vh;
    padding-top: 0%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;
    flex-shrink: 0;
   
  }

  .order_card_warpper{
    height: 60vh;
    overflow-y: scroll;
  }
  .instuction_checkout{
    color: var(--Neutral-Color, #33363b);
    font-family: Glacial Indifference;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}





@media only screen and (max-width:380px){
  .main-section {
    border-radius: 10px;
    border: 0px solid #a5a1a1 !important;
    width: 365px;
    padding-top: 10%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;
    flex-shrink: 0;
    margin-left: 10px;
  }
  .instuction_checkout{
    color: var(--Neutral-Color, #33363b);
    font-family: Glacial Indifference;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .order_card_warpper{
    height: 60vh;
    overflow-y: scroll;
  }

  .heading-tp{
    padding-left: 15px;
  }

  .main-order{
    width: 91vw;
    margin-left: 5px;
  }

  .detail-btn{
    width: 115px;
    padding: 10px 62px;
  }

  .order-btn{
    width: 115px;
    padding: 10px 62px;
  }
  
}


@media only screen and (max-width:300px)
{
  .main-section{
    width: 293px;
  }

  .order-btn {
    width: 72px;
    padding: 10px 48px;
}

.detail-btn {
  width: 72px;
  padding: 10px 48px;
}
.css-k8k6pr{
  padding-left: 13px !important;
  padding-right: 22px !important;
}

.css-1llsor2{
  width: 309px !important;
}
}
.condition-box-custom{
    margin-top: 6%;
}

.custom-width-box{
    max-width: 920px;
    margin: auto;
}

.heading-condition{
    font-size: 20px;
    color: rgb(20, 20, 20);
}

.number-condition{
    font-family: Glacial Indifference;
}

.para-condition{
    font-family: Glacial Indifference;
    color: rgb(53, 53, 53);
}

.condition-title{
    font-size: 24px;
}

.condition-box-custom{
    margin-top: 6%;
}

.custom-width-box{
    max-width: 920px;
    margin: auto;
}

.heading-condition{
    font-size: 20px;
    color: rgb(20, 20, 20);
}

.number-condition{
    font-family: Glacial Indifference;
}

.para-condition{
    font-family: Glacial Indifference;
    color: rgb(53, 53, 53);
}

.condition-title{
    font-size: 24px;
}
.mt-tp{
    margin-top: 10%;
    width: 850px;
    margin-bottom: 10%;
 }



 .custom-font{
    color: var(--Neutral-color, #212524);
    font-family: 'Playfair Display', serif;
    font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 140%;
 }

.back-menu-btn{
color: var(--grey-grey-400, #4D5150);
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;

}

.container-track{
    width: 834px;
    height: 790px;
   
    background: #FFF;
   border-radius: 10px;
border: 1px solid var(--grey-grey-100, #BABBBB);
}

.header-container{
    padding: 30px 25px;
}

.request-box{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
background-color: #F5F5F5;
color: #4F8279;
margin-left: 10px;
}

.bell-box{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 4px;
background-color: #F5F5F5;
margin-right: 10px;
position: relative;
}

.notify-bell{
    background-color: #4F8279;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    top: -10%;
    right: 0%;
}

.request-box span {
    color: #4F8279;
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.invoice-box{
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    justify-content: space-around;
    align-items: center;
    border-radius: 8px;
border: 1px solid var(--grey-grey-100, #BABBBB);
background: #FFF;
padding: 6px 15px;
position: relative;
}

.invoice-dropdown{
    position: absolute;
    border-radius: 8px 8px 0px 0px;
padding: 5px;
border-radius: 10px;
width: 130px;
    top: 140%;
    background: #FFF;

box-shadow: 0px 6px 18px 0px rgba(190, 189, 189, 0.25);
}

.font-dropdown{
 color: var(--Neutral-color, #000000);
font-family: Glacial Indifference;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
margin-left: 6px;
}

.text-box{
    padding: 5px 5px;
}

.dropdown-icon{
     color: var(--Neutral-color, #050505);
}

.invoice-box span{
    color: var(--neutral-neutral-400, #5D5E63);
font-family: Glacial Indifference;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 20px;
}

.order_detail{
    color: var(--grey-grey-300, #6A6D6C);
font-family: 'Playfair Display', serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
grid-gap: 5px; 
gap: 5px;
}

.order_detail span{
    color: var(--neutral-neutral-900, #161719);
font-family: Glacial Indifference;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;

}


.order-detail-header{
  padding: 0px 0px;
  margin-top: 20px;
  grid-gap: 20px;
  gap: 20px;
}

.payment-head{
    color:  #364D49;
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 140%; 
}

.payment-desc{
    color: var(--grey-grey-300, #6A6D6C);
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 28px */
}

.loading-bar{
    color: #364D49;
    margin-top: 5px;
    margin-bottom: 30px;
}

.progress-bar{
   background-color: #364D49 !important;
   border-radius: 10px !important;
}


.detail-card-container{
    padding: 10px 25px;
    margin-top: 10px;
}


.card-image{
    border-radius: 15px;
    width: 60px;
    height: 60px;
    object-fit: cover;
}

.card-main{
    grid-gap: 6px;
    gap: 6px;
    margin-left: 10px;
}

.detail-title{
    color: var(--Primary-color, #364D49);
font-family: 'Playfair Display', serif;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 32px;
}

.detail-card{
color: var(--grey-grey-300, #6A6D6C);
font-family: Glacial Indifference;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 200% */
}

.detail-item-price{
    color: var(--grey-grey-300, #6A6D6C);
text-align: right;
font-family: Glacial Indifference;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}

.detail-price{
    color: var(--Primary-color, #364D49);
text-align: right;
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 30px; /* 150% */
}


.hr-line{
    height: 3px !important;
    margin-top: 30px;
    background-color: #D9D9D9;
}

.payment-detail{
    padding: 10px 25px;
  
}

.payment-heading{
    color: var(--Primary-color, #364D49);
font-family: 'Playfair Display', serif;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.right-details{
    color: var(--Neutral-color, #212524);
font-family: 'Playfair Display', serif;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.left-details{
    color: var(--Neutral-color, #212524);
font-family: Glacial Indifference;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.right-bold{
color: var(--Primary-color, #364D49);
font-family: 'Playfair Display', serif;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.left-bold{
color: var(--Primary-color, #364D49);
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.sub-text{
    font-size: 13px;
    color: var(--Neutral-color, #212524);
font-family: 'Playfair Display', serif;
}


.timer-heading{
    color: var(--neutral-neutral-300, #77787C);
text-align: center;
font-family: Glacial Indifference;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 140%; 
width:13%;
}

.timer-active-heading{
    color: #4F8279 !important;
    font-weight: 700 !important;
}

.dot-single{
    width: 22px;
    height: 22px;
    border-radius: 50%;
background: var(--natural-300, #D0D5DD);
color:#D0D5DD ;
}

.dots{
    position: absolute;
    width: 804px;
    top: -200%;
    padding: 0px 35px;
    align-items: center;
}

.line-bar{
    width: 720px;
    margin-left: 35px;
    height: 4px;
    background-color:var(--natural-300, #D0D5DD)  ;
    position: relative;
    transition: width 0.5s ease;
}

.fade-in-out {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fade-in-out-hidden {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none; 
}

.activedot{
    background: var(--natural-300, #4F8279);
color:#4F8279 ;
}

.modal-header {
position: relative;
}

.modal-box{
grid-gap: 15px;
gap: 15px;
}

.modal-box-p{
color: #000;

font-family: Glacial Indifference;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.modal-box-p1{
color: #000;

font-family: Glacial Indifference;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.main-modal{
    height: 50vh;
}


.h-1{
    height: 5px !important;
    width: 720px;
    margin-left: 35px;
}


.links-row{
    grid-gap: 15px;
    gap: 15px;
    margin-top: -15px;
}

.links{
    color: var(--grey-grey-300, #6A6D6C);
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 28px */
}

.contact-link{
    text-decoration: underline;
}


.close-btn{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #364D49;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -15%;
    right: 0%;
}

.close-btn i {
 font-weight: 800;
}

.left-text-contact{
    color: var(--Neutral-color, #212524);
font-family: Playfair Display;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.right-text-contact{
    color: var(--Neutral-color, #212524);
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.contact-section-padding{
    padding: 15px 8px;
}
.contact-modal{
    overflow: hidden;
}

.btn-contact{
    background-color: transparent;
    border: none;
}

.order-complete-heading
{
    color: #000;

text-align: center;
font-family: Playfair Display;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 33.6px */
}

.order-complete-btn{
    display: flex;
width: 250px;
height: 41px;
padding: 12px 125px;
justify-content: center;
align-items: center;
grid-gap: 10px;
gap: 10px;
flex-shrink: 0;
border-radius: 10px;
background: #364D49;
color: #FFF;

font-family: Glacial Indifference;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-decoration: none;
white-space: nowrap;
}

.order-complete-btn:hover{
    color: white;
}

.order-card-section{
    height: 250px !important;
    overflow-y: scroll;
}
.order-card-section::-webkit-scrollbar {
    width: 12px; 
}

.order-card-section::-webkit-scrollbar-thumb {
    background-color: #364D49; 
    border-radius: 6px; 
}

.order-card-section::-webkit-scrollbar-track {
    background-color: #ffffff; 
}

@media only screen and (min-width:768px) and (max-width:991px){
    .container-track{
        width: 720px;
    }

    .mt-tp{
           width: 756px;
           margin-top: 20%;
    }

    .dots{
        width: 680px;
    }

    .h-1{
        width: 600px;
    }
}


@media only screen and (min-width:400px) and (max-width:600px) {

    .completed-container{
        margin-bottom: 2px;
    }

    .payment-heading{
        font-size: 16px;
    }

        .container-track{
        width: 390px;
        border: none;
 height: 820px;
    }

    .container{
        margin-left: 0px !important;
    }

    .mt-tp{
           width: 357px;
           margin-top: 30%;
    }

    .custom-font{
        font-size: 14px;
    }

    .request-box span{
        font-size: 12px;
        white-space: nowrap;
    }
    .header-container{
            background: #FFF;
   border-radius: 2px;
border: 1px solid var(--grey-grey-100, #BABBBB);
    }

    .payment-desc{
        font-size: 14px;
    }


    .detail-card-container{
        padding: 10px 5px;
    }

    .w-100{
        width: 115% !important;
    }

    .hr-line{
        height: 1px !important;
    }

    .payment-detail{
         padding: 10px 5px;
    }

    .dots{
width: 390px;
padding: 0px 15px;
    }

    .h-1{
        width: 400px;
        margin-left: -7px;
    }

    .timer-heading{
        font-size: 1px;
        width: 70%;
    }

    .timer-container{
        padding: 0px !important;
        grid-gap: 25px !important;
        gap: 25px !important;
        width: 390px;
    }

    .timer-main-container{
        margin-left: -4%;
    }

    .header-container{
padding: 15px 14px;
    }

    .bell-box{
        margin-left: 5%;
    }

    .wrap-back{
        margin-left: 5%;
    }

    
    .links{
        font-size: 16px;
    }

     .order-detail-mobile{
        grid-gap: 12px;
        gap: 12px;
    }

  .modal-box-p{
        font-size: 14px;
    }

    .left-details{
font-size: 14px;
    }

    .order-complete-btn {
        display: flex;
        width: 223px;
        height: 35px;
        padding: 12px 59px;
        justify-content: center;
        align-items: center;
        grid-gap: 10px;
        grid-gap: 10px;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #364D49;
        color: #FFF;
        font-family: Glacial Indifference;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-decoration: none;
        white-space: nowrap;
    }

    .order-complete-heading{
font-size: 20px;
    }
}

.font_timer{
    color: #4F8279;
font-family: Glacial Indifference;
font-size: 24px !important;
font-style: normal;
font-weight: 700;
line-height: normal;
}



@media only screen and (min-width:320px) and (max-width:399px){

    .order-complete-btn {
        display: flex;
        width: 223px;
        height: 35px;
        padding: 12px 59px;
        justify-content: center;
        align-items: center;
        grid-gap: 10px;
        grid-gap: 10px;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #364D49;
        color: #FFF;
        font-family: Glacial Indifference;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-decoration: none;
        white-space: nowrap;
    }

    .order-complete-heading{
font-size: 20px;
    }

    .completed-container{
        margin-bottom: 2px;
    }

    .modal-box-p{
        font-size: 14px;
    }

    .left-details{
font-size: 14px;
    }

      .payment-heading{
        font-size: 16px;
    }

    .link_id{
        margin-bottom: -10px;
    }

    .order-detail-mobile{
        grid-gap: 12px;
        gap: 12px;
    }

    .links{
        font-size: 16px;
    }

        .container-track{
        width: 360px;
        border: none;
 height: 850px;
    }

    .container{
        margin-left: 0px !important;
    }

    .mt-tp{
           width: 350px;
           margin-top: 30%;
    }

    .custom-font{
        font-size: 14px;
        /* white-space: nowrap; */
    }

    .request-box span{
        font-size: 12px;
        white-space: nowrap;
    }
    .header-container{
            background: #FFF;
   border-radius: 2px;
border: 1px solid var(--grey-grey-100, #BABBBB);
    }

    .payment-desc{
        font-size: 14px;
        white-space: nowrap;
    }


    .detail-card-container{
        padding: 10px 5px;
    }

    .w-100{
        width: 115% !important;
    }

    .hr-line{
        height: 1px !important;
    }

    .payment-detail{
         padding: 10px 5px;

    }

    .dots{
width: 350px;
padding: 0px 15px;
    }

    .h-1{
        width: 365px;
        margin-left: -7px;
    }

    .timer-heading{
        font-size: 11px;
        width: 70%;
    }

    .timer-container{
        padding: 0px !important;
        grid-gap: 25px !important;
        gap: 25px !important;
        width: 356px;
    }

    .timer-main-container{
        margin-left: -4%;
    }

    .header-container{
padding: 15px 14px;
    }

    .bell-box{
        margin-left: 5%;
    }

    .wrap-back{
        margin-left: 5%;
    }


}
.header-order{
  margin-top: 6%;
  background: #ECEEEE;
  padding: 11px 0px;
  grid-gap: 30px;
  gap: 30px;
}

.header-order span{
  color: #364D49;

font-family: Playfair Display;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.header-order button{
  display: flex;
padding: 12px 25px;
justify-content: center;
align-items: center;
grid-gap: 10px;
gap: 10px;
border-radius: 10px;
background: #364D49;
color: #FFF;
border: none;

font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;

}

.container-outer{
  width: 670px !important;
  height: 900px;
  margin-top: 20px;

}

.back-menu-btn{
  color: var(--grey-grey-400, #4D5150);
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;

}

.order-box{
  width: 638px;
max-height: auto;
border-radius: 10px;
border: 1px solid #B9B8B8;
background: #FFF;
box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
display: flex;
flex-direction: column;
}

.order-heading
{
  color: var(--Primary-color, #364D49);
font-family: Playfair Display;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding-top: 20px;
padding-bottom: 10px;
}

.section_detail_order{
  padding-top: 20px;
  padding-bottom:10px;
  padding-left: 51px;
  padding-right: 51px;
}

.extra_detail_card{
  padding: 5px 51px;
  grid-gap: 70px;
  gap: 70px;
  width: 60%;
}

.product_name{
  color: var(--Primary-color, #364D49);
  font-family: Playfair Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.product_price{
  color: var(--Primary-color, #364D49);
  font-family: Glacial Indifference;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.product_extra_title{
  color: var(--grey-grey-200, #989B9A);
  font-family: Playfair Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

}

.product_extra-price{
  color: var(--grey-grey-200, #989B9A);
font-family: Glacial Indifference;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.custom-line-hr{
  margin-top: 20px;
}


.order-detail-heading{
  color: var(--Primary-color, #364D49);
font-family: Playfair Display;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding-top: 15px;
padding-left: 51px;
padding-bottom: 0px;
margin-bottom: 8px;
}

.custom_detail_card{
  padding: 8px 51px;
}

.order_detail_left{
  color: var(--Neutral-color, #212524);
  font-family: Playfair Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.order_detail_right{
  color: var(--Neutral-color, #212524);
  font-family: Glacial Indifference;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.font_subelement{
  color: var(--Neutral-color, #212524);
font-family: Glacial Indifference;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.price-title-heading{
  color: var(--Primary-color, #364D49);
font-family: Playfair Display;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.price-total_right{
  color: var(--Primary-color, #364D49);
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.modal-heading{
  color: var(--Primary-color, #364D49);
font-family: Playfair Display;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.close-btn-order{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #364D49;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  top: 3%;
}
.close-btn-order i {
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.qr-code-image{
  width: 150px !important;
}

.qr-code-text{
  color: #000;

font-family: Glacial Indifference;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.instruction-red{
  .instruction-red {
    width: 600px;
    display: block; /* or display: inline-block; depending on your layout needs */
    overflow: hidden; /* to prevent content from overflowing */
    white-space: nowrap; /* to prevent line breaks inside the span */
    text-overflow: ellipsis; /* optional: add ellipsis for overflowed content */
  }
}

.custom-hr-line-accept{
  /* color: #000; */
  border-top: 1px solid rgb(226, 224, 224);
}

.order-detail-height{
  max-height: 300px;
  overflow-y: scroll;
}


.order-detail-height::-webkit-scrollbar {
  width: 8px; 
}

.order-detail-height::-webkit-scrollbar-thumb {
  background-color: #364D49; 
  border-radius: 6px; 
}

.order-detail-height::-webkit-scrollbar-track {
  background-color: #ffffff; 
}

@media only screen and (min-width:1800px) and (max-width:2400px)
{
  .header-order{
      margin-top: 5%;
  }
}


@media only screen and (min-width:1400px) and (max-width:1700px)
{
  .header-order{
    margin-top: 6.5% !important;
  }
}

@media only screen and (min-width:1799px) and (max-width:1000px)
{
  .header-order{
    margin-top: 7%;
  }
}


@media only screen and (min-width:766px) and (max-width:991px)
{
.header-order{
  margin-top: 12%;
}
}


@media only screen and (max-width:600px)
{


  .instruction-red{
    width: 78vw;
  }

  .order-box{
      width: 91vw;
      max-height: auto;
  }

  .header-order{
      margin-top: 17%;
      padding: 11px 20px;
  }
  .header-order button{
      font-size: 16px;
      padding: 10px 16px;
  }

  .header-order span{
      font-size: 17px;
  }

  .order-heading{
      font-size: 20px;
  }

  .product_name{
     font-size: 14px; 
  }

  .product_price{
      font-size: 16px;
  }

  .product_extra_title{
      font-size: 12px;
  }
  .product_extra-price{
      font-size: 12px;
      white-space: nowrap;
  }

  .order-detail-heading{
      font-size: 16px;
      padding-left: 27px;
  }
  .order_detail_left{
   font-size: 14px;   
  }

  .order_detail_right{
      font-size: 14px;
  }

  .price-title-heading{
      font-size: 16px;
  }

  .price-total_right{
      font-size: 16px;
  }

  .section_detail_order{
      padding-left: 27px;
      padding-right: 27px;
  }

  .extra_detail_card {
      padding: 5px 26px;
      grid-gap: 53px;
      gap: 53px; 
      width: 70%;
  }

  .custom_detail_card{
      padding-left: 27px;
      padding-right: 27px;
  }

  .container-outer{
      height: 730px;
  }

  .qr-image-sec{
      display: none !important;
  }
  .sec-button-modal{
      align-items: center;
      grid-gap: 10px;
      gap: 10px;
  }

  .qr-code-image:nth-child(1){
      margin-bottom: 30px;
  }

  .modal-heading{
      font-size: 20px;
  }
}
.header-order{
    margin-top: 6%;
    background: #ECEEEE;
    padding: 11px 0px;
    grid-gap: 30px;
    gap: 30px;
}

.header-order span{
    color: #364D49;

font-family: Playfair Display;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.header-order button{
    display: flex;
padding: 12px 25px;
justify-content: center;
align-items: center;
grid-gap: 10px;
gap: 10px;
border-radius: 10px;
background: #364D49;
color: #FFF;
border: none;

font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;

}

.container-outer{
    width: 670px !important;
    height: 900px;
    margin-top: 20px;

}

.back-menu-btn{
    color: var(--grey-grey-400, #4D5150);
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;

}

.order-box{
    width: 638px;
max-height: auto;
border-radius: 10px;
border: 1px solid #B9B8B8;
background: #FFF;
box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
display: flex;
flex-direction: column;
padding-bottom: 10px;
}

.order-heading
{
    color: var(--Primary-color, #364D49);
font-family: Playfair Display;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding-top: 20px;
padding-bottom: 10px;
}

.section_detail_order{
    padding-top: 20px;
    padding-bottom:10px;
    padding-left: 51px;
    padding-right: 51px;
}

.extra_detail_card{
    padding: 5px 51px;
    grid-gap: 53px;
    gap: 53px;
    width: 60%;
}

.product_name{
    color: var(--Primary-color, #364D49);
    font-family: Playfair Display;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.product_price{
    color: var(--Primary-color, #364D49);
    font-family: Glacial Indifference;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.product_extra_title{
    color: var(--grey-grey-200, #989B9A);
    font-family: Playfair Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}

.product_extra-price{
    color: var(--grey-grey-200, #989B9A);
font-family: Glacial Indifference;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.custom-line-hr{
    margin-top: 20px;
}


.order-detail-heading{
    color: var(--Primary-color, #364D49);
font-family: Playfair Display;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding-top: 15px;
padding-left: 51px;
padding-bottom: 0px;
margin-bottom: 8px;
}

.custom_detail_card{
    padding: 8px 51px;
}

.order_detail_left{
    color: var(--Neutral-color, #212524);
    font-family: Playfair Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.order_detail_right{
    color: var(--Neutral-color, #212524);
    font-family: Glacial Indifference;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.font_subelement{
    color: var(--Neutral-color, #212524);
font-family: Glacial Indifference;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.price-title-heading{
    color: var(--Primary-color, #364D49);
font-family: Playfair Display;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.price-total_right{
    color: var(--Primary-color, #364D49);
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.modal-heading{
    color: var(--Primary-color, #364D49);
font-family: Playfair Display;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.close-btn-order{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #364D49;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    right: 20px;
    top: 3%;
}
.close-btn-order i {
    font-size: 20px;
    font-weight: 600;
    color: white;
}

.qr-code-image{
    width: 150px !important;
}

.qr-code-text{
    color: #000;

font-family: Glacial Indifference;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

@media only screen and (min-width:1800px) and (max-width:2400px)
{
    .header-order{
        margin-top: 5%;
    }
}



@media only screen and (min-width:766px) and (max-width:991px)
{
.header-order{
    margin-top: 12%;
}
}


@media only screen and (max-width:600px)
{
    .order-box{
        width: 91vw;
        max-height: auto;
    }

    .header-order{
        margin-top: 17%;
        padding: 11px 20px;
    }
    .header-order button{
        font-size: 16px;
        padding: 10px 16px;
    }

    .header-order span{
        font-size: 17px;
    }

    .order-heading{
        font-size: 20px;
    }

    .product_name{
       font-size: 14px; 
    }

    .product_price{
        font-size: 16px;
    }

    .product_extra_title{
        font-size: 12px;
        white-space: nowrap;
    }
    .product_extra-price{
        font-size: 12px;
    }

    .order-detail-heading{
        font-size: 16px;
        padding-left: 27px;
    }
    .order_detail_left{
     font-size: 14px;   
    }

    .order_detail_right{
        font-size: 14px;
    }

    .price-title-heading{
        font-size: 16px;
    }

    .price-total_right{
        font-size: 16px;
    }

    .section_detail_order{
        padding-left: 27px;
        padding-right: 27px;
    }

    .extra_detail_card {
        padding: 5px 26px;
        grid-gap: 53px;
        gap: 53px; 
       width: 70%;
    }

    .custom_detail_card{
        padding-left: 27px;
        padding-right: 27px;
    }

    .container-outer{
        height: 730px;
    }

    .qr-image-sec{
        display: none !important;
    }
    .sec-button-modal{
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
    }

    .qr-code-image:nth-child(1){
        margin-bottom: 30px;
    }

    .modal-heading{
        font-size: 20px;
    }
}
.top_class{
    margin-top: 16rem;
    width: 100%;
    height: 100vh;
}

.testheading{
    color: black;
    font-size: 24px;
}
