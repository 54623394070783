section {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  width: 100%;
  /* border-bottom: 1px solid lightgrey; */
}

section:nth-child(even) {
  flex-direction: row-reverse;
}
.product-image {
  width: 100px;
  height: 100px;
  margin-top: 6px;
  float: right;
  object-fit: cover;
}
.hm-featured-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  padding-top: 3rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  color: #2D5C48;
  font-family: 'Playfair Display';
  margin-bottom: 25px !important;
}


.hm-featured-heading-over{
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  padding-top: 8rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  color: #2D5C48;
  font-family: 'Playfair Display';
  margin-bottom: 25px !important;
}

/* section img,
section div {
	height: auto;
	width: 100%;
} */

/* section img {
	width: 50%;
} */

/* section div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 1em;
} */
.hm-card-custom {
  box-shadow: 1px 1px 3px 1px rgba(222,222,222,0.75);
  padding:4px 10px 0px 10px;
  border-radius: 4px;
  /* max-height: 150px !important; */
  height: 100%;
}
@media screen and (max-width: 900px) {
  section,
  section:nth-child(even) {
    flex-direction: column;
  }

 section img {
    height: auto;
    width: auto;
    max-width: 100vw;
  }
}
.cart-parent-class {
  padding: 25px 5px;
}

.cart-sticky {
  box-shadow: 1px 1px 3px 1px rgba(222,222,222,0.75);
  position: sticky;
  top: 150px;
  height: calc(100vh - 150px);
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-radius: 4px;
}
.hm-produc-div {
  background: white;
  border-radius: 6px;
box-shadow: 1px 1px 1px 1px #8d928d;
}

@media (max-width: 600px) {
  .mbl-menu-cntr {
    margin-top: 120px !important;
  }

  .hm-featured-heading{
    font-size: 20px;
  }

  .hm-featured-heading-over{
    font-size: 20px;
  }
}

@media only screen and (max-width:300px)
{
  .hm-featured-heading{
    font-size: 20px;
  }
  .mbl-menu-cntr{
    margin-top: 88px !important;
  }

  .sticky-subheader-outer{
    top: 50px !important;
  }

  .hm-featured-heading-over{
    font-size: 18px;
  }
}

@media only screen and (min-width:766px) and (max-width:991px)
{
  .margin_left_custom{
    margin-left: 17px;
  }
}