
@media only screen and (min-width:600px) and (max-width:991px)
{
    .main_footer {
    background-color: #2f4e49;
    width: 100%;
    padding: 10px;
    display: block;
}
}


.main_footer {
    background-color: #2f4e49;
    width: 100%;
    padding: 15px 10px;
}


.transparent-white-icon {
  background: transparent;
  color: white;
  border: 1px solid white;
  width: 30px ;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-radius: 50%;
  margin: 0px 10px;
}

.footer_address span{
    color: white;
    margin-top: 15px !important ;
    font-family: Glacial Indifference;
    font-weight: 200;
}
.link_footer a{
    color: white;
    text-decoration: none;
font-family: Glacial Indifference;
}
.padding_custom_footer{
    
    padding-bottom: 10px;
    padding-left: 8px;
    padding-right: 8px;
}


@media only screen and (max-width:300px)
{
    .logo_footer_custom{
        width: 120px !important;
    }
    .footer_logo img {
        width: 130px !important;
    }

    .transparent-white-icon{
        font-size: 12px;
        height: 20px;
        width: 20px;
    }
    .link_footer a{
        font-size: 10px;
    }
    .copy_right_font{
        font-size: 10px !important;
    }
    .padding_custom_footer{
        font-size: 10px;
    }
}