.condition-box-custom{
    margin-top: 6%;
}

.custom-width-box{
    max-width: 920px;
    margin: auto;
}

.heading-condition{
    font-size: 20px;
    color: rgb(20, 20, 20);
}

.number-condition{
    font-family: Glacial Indifference;
}

.para-condition{
    font-family: Glacial Indifference;
    color: rgb(53, 53, 53);
}

.condition-title{
    font-size: 24px;
}