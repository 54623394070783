
#round {
    border-radius: 5px;
    border-color: gray;
    height: 10px;
    font-family: 'playfair display' !important;
  }
  #round1 {
    border-radius: 10px;
    border-color: gray;
    height: 42px;
    width: 430px;
  }
  #round2 {
    border-radius: 10px;
    border-color: gray;
    height: 150px;
    width: 100%;
    resize: none;
  }
  
  textarea {
    padding: 6px 8px;
    line-height: 1.5;
    resize: none;
  }
  #txt {
    color: rgb(100, 99, 99);
    font-family: 'playfair display' !important;
  }
  
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media (max-width: 600px) {
  .mobile-contact-div {
    margin-top: 90px !important;
  }
}
