.form-check-input {
  margin-top: 1.5%;
}
.checkboxcntnt {
  color: #666666;
}
.privacypolicy {
  transition: all 0.3s;
  color: black;
  text-decoration: underline;
  font-weight: 400;
}
.signup-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  /* identical to box height, or 128% */

  letter-spacing: -0.02em;

  /* Neutral/90 */

  color: #1a1a1a;
  /* text-align: center; */
}
.signup-btn {
  width: 102%;
  margin-left: -10px !important;
  border-radius: 5px;
  margin-top: 2rem;
  margin-bottom: 1rem;
  background: #364d49 !important;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 9px;
  text-align: center;
  display: flex;
  justify-content: center;
  display: flex;
  align-items: center;
  text-transform: capitalize !important;
  color: white;
  font-family: "Playfair Display";
}
.form-control {
  font-size: 1.5rem;
}
p {
  font-family: "Playfair Display";
}


.login_btn{
  color: #364d49;
  background-color: #ebeded;
  padding: 5px 10px;
  border-radius: 5px;
}
.login_btn:hover{
  color: #364d49;
}


@media only screen and (max-width:600px) {
  .margin-confirm-password{
    margin-top: -18px !important;
  }

  .inner-padding-mobile{
    padding: 0px 16px !important;
    margin-top: 5% !important;
  }
}