.cart {
  position: absolute;
  width: 27px;
  height: 41px;
  left: 1268px;
  top: 27px;
}

.disabled-btn-checkout{
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.7;
}

.time-cart-close{
  font-family:Glacial Indifference ;
}

.cart-summary {
  border-radius: 5px !important;
}
.myCart-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  color: #1a1a1a;
  position: relative;
  bottom: 22px;
}

.cart-product-name {
  color: var(--Primary-color, #364D49);
  font-family: Playfair Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  position: relative;
  left: 5px;
  height:25px;
  width: 180px;

}
.ct-items-div{
    font-family:'Playfair Display';
    overflow: auto;
    padding: 13px;
    height: calc(100vh - 190px);
    -webkit-overflow-scrolling: touch !important;
}
.cart-product-price {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  margin-right: 47px;
  color: #4d4d4d;
}

.cart-icons {
  display: flex;
  width: 88px;
  height: 31px;
  align-items: flex-start;
  position: relative;
  top: 10px;
  left: 5px;
}

.cart-product-main {
  display: flex;
  justify-content: space-between;
}

.cart-outer-quantity {
  position: relative;
  left: -10px;
  /* top: -1px; */
  padding: 2px;
  grid-gap: 16px;
  grid-gap: 16px;
  /* gap: 16px; */
  width: 111px !important;
  height: 31px;
  background: #ffffff;
  /* border: 1px solid #cccccc; */
  border-radius: 8px;
}
.cart-checkout {
  color: #FFF;
font-family: Glacial Indifference;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: initial;
}

.cart-cont {
  color: var(--Neutral-color, #212524);
    font-family: Glacial Indifference;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    width: 30px;
    padding: 6px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.cart-item {
  margin-top: -13px;
}

.cart-extra {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
  margin: auto;
}

.cart-extra:hover {
  color: #666666;
}

.sidebar-menu {
  position: fixed;
  right: -4px;
  top: -5px;
  z-index: 1100;
  width: 503px;
  height: 100%;
  overflow: auto;
  transition: all 0.5s ease 0s;
  transform: translateX(0);
  -webkit-transition: 0.5s ease all;
  background: #FFF;
  padding: 0rem;
}

/* .open {
  -webkit-transform: translateX(350px);
  transform: translateX(350px);
} */

.cart-upper-heading {
  display: inline-flex;
  height: 100px;
  padding: 52px 24px 16px 0px;
  /* padding: 52px 24px 16px 27px; */
  justify-content: center;
  align-items: flex-end;
  gap: 285px;
  flex-shrink: 0;
  width: 100%;
  border-bottom: 1px solid var(--neutral-neutral-100, #C0C1C3);
  background: #FFF;
}

.cart-heading {
  color: var(--Primary-color, #364D49);
  font-family: Playfair Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cart-cancel-btn {
  right: 20px !important;
  color: #413434;
  font-family: Glacial Indifference;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;

  }

.cart-order-list {
  margin-top: 1rem;
  margin-bottom: 1rem;
  /* max-height: 350px; */
  /* overflow: auto; */
}

.cart-coupon {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #2d5c48;
  margin: auto;
  font-family: "Playfair Display";
  display: flex;
  align-items: center;
}

.cart-coupon-apply {
  width: auto !important;
}

.form-inner {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 1rem;
  padding: 1.5rem;
  transition: all 0.3s;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  background-color: #fff;
  color: #666666;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
  margin: auto;
}

.form-inner:focus {
  border: 1px solid red !important;
}

.cart-apply-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  grid-gap: 4px;
  gap: 4px;
  width: 61px;
  height: 32px;
  background: #364d49;
  border-radius: 5px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 12.8px;
  line-height: 20px;
  color: white;
  border: none;
  position: relative;
  top: -40px;
  left: 292px;
  font-family: "Playfair Display";
}

.cart-subtotal {
  display: flex;
  justify-content: space-between;
  /* margin: 0.5rem; */
}

.cart-single-item-left {
  color: var(--Neutral-color, #212524);
  font-family: Playfair Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.cart-single-item-right {
  color: var(--Neutral-color, #364D49);
  font-family: Glacial Indifference;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cart-quantity-total {
  background-color: #818f71;
  width: 30px;
  border-radius: 100px;
  padding-top: 6px;
  color: #fff !important;
  height: 30px;
  margin-top: 3px !important;
  margin-left: 5px !important;
}
.cart-shopping {
  color: black !important;
}
.cart-checkout {
  color: white !important;
}
.product-modal-special {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1a1a1a;
  margin: 0px;
  margin-left: 1rem;
}

.product-modal-more-optional {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #666666;
}

.special-ins {
  font-style: normal;
  font-weight: 400;
  max-height: 100px !important;
  font-size: 15px;
  border-radius: 5px;
  line-height: 10px;
  color: #666666;
  width: 455px;
  height: 118px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid var(--grey-grey-100, #BABBBB);
  background: #FFF;
  line-height: normal;
  }

.cart-buttons {
  display: flex;
  width: 458px;
  padding: 16px 125px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 14px;
  background: var(--Primary-color, #364D49);
  height: 54px;
  color: #FFF;
  font-family: Glacial Indifference;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.final-price {
  color: rgb(255, 255, 255) !important;
  margin-top: 10px !important;
  margin-right: 7px !important;
  font-size: 14px !important;
}

@media (min-width: 320px) and (max-width: 767px) {
  .cart-apply-btn {
    top: -38px;
    left: 251px;
  }
}
.cart-shopping {
  padding-top: 4px;

  height: 32px;
  margin-top: 5px;
  font-size: 16px;
  /* margin-bottom: -15px; */
  width: 71px !important;
  margin-left: -45px !important;
}
.cart-shopping svg {
  font-size: 20px;
  overflow: hidden;
  vertical-align: unset !important;
  color: white;
}
#mbl-cart-model-close-btn{
  position: absolute;
  top: 3px;
  right: 1px !important;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
}
@media (min-width: 320px) and (max-width: 480px) {
  .sidebar-menu {
    position: fixed;
    top: -5px;
    z-index: 1100;
    width: -webkit-fill-available;
    height: 100%;
    overflow: auto;
    transition: all 0.5s ease 0s;
    transform: translateX(0);
    -webkit-transition: 0.5s ease all;
    background: #f7f7f7;
    padding: 1.5rem;
  }
  .open {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  /* .open {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  } */
}
@media (min-width: 768px) and (max-width: 1024px) {

}
/* @media (min-width: 1024px) and (max-width: 1280px) {
  .open {
    -webkit-transform: translateX(0px) !important;
    transform: translateX(0px) !important;
  }
} */

.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: 1.5rem;
}


.cart-image{
  display: flex;
  width: 64px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.cart-choice-qty{
  color: var(--Primary-color, #364D49);
  font-family: Glacial Indifference;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  left: 52px;
  top: 19px;
  white-space: nowrap; 
}



.product-price{
  color: var(--Primary-color, #364D49);
  font-family: Glacial Indifference;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: end;
  }

  .left-icon{
    display: flex;
    width: 29px;
    height: 31px;
    padding: 9px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
  }

.right-icon{
  margin-top: 0px;
  cursor: pointer;
  display: flex;
  width: 29px;
  height: 31px;
  padding: 9px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.od{
  color: var(--Primary-color, #364D49);
  font-family: Playfair Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.spi{
  color: var(--Neutral-color, #212524);
  font-family: Playfair Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 8px;
  gap: 10px;
}

.optional{
  color: var(--Neutral-color, #212524);
  font-family: Playfair Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
  right: 240px;
  top: 3px;
}

.opt-value{
  color: var(--Neutral-color, #212524);
  font-family: Playfair Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
 margin-top: 5px;
}

#asap{
  display: flex;
  width: 455px;
  height: 44px;
  padding: 1px 8px 1px 18px;
  justify-content: flex-end;
  align-items: center;
  gap: 257px;
  flex-shrink: 0;
  border-radius: 6px;
border: 1px solid var(--grey-grey-100, #BABBBB);
  background: #FFF;
  font-family: Glacial Indifference;
}

#asap .select {
  padding-right: 20px;
}

.cusomt_Input{
  width: 455px;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(119, 119, 119);
  border: 1px solid var(--grey-grey-100, #BABBBB);
  padding: 0px 18px;
  position: relative;
  border-radius: 6px;
 
}

.custom_dropDown{
  position: absolute;
  background: white;
  width: 455px;
  z-index: 999999;
  height: 200px;
  overflow-y: auto;
  padding: 0px 18px;
  border-radius: 6px;
  border: 1px solid gainsboro;
  border-radius: 10px;
  font-family: Glacial Indifference;
  padding-top: 12px;

}

.custom_dropDown::-webkit-scrollbar {
  width: 8px; 
}

.custom_dropDown::-webkit-scrollbar-thumb {
  background-color: #364D49; 
  border-radius: 6px; 
}

.custom_dropDown::-webkit-scrollbar-track {
  background-color: #ffffff; 
}

.cusomt_Input span{
   font-family: Glacial Indifference;
}

.custom_dropDown div {
  cursor: pointer;
   font-family: Glacial Indifference;
}


.divider{
  height: 1px;
  background: var(--grey-grey-100, #BABBBB);
}
.chk-out-btn{
  bottom: 0px;
  display: flex;
  padding: 18px 22px;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  background: #FFF;
  box-shadow: 0px -6px 18px 0px rgba(147, 142, 142, 0.25);
  width: auto;
  position: relative;
  top: 0px;
}


.basket-icon{
  margin-top: 170px;
  position: relative;
  left: 0px;
  height: 133px;
}

.empty-cart{
  color: #2F2F2F;
  text-align: center;
  font-family: Playfair Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 90px;
}

.empty-cart-content{
  color: #000;
  text-align: center;
  font-family: Glacial Indifference;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.57;
  margin-top: 20px;
}

.empty-ct-items-div{
  font-family: 'Playfair Display';
    overflow: auto;
    padding: 13px;
    height: calc(100vh - 190px);
}

@media only screen and (min-width:1500px)
{
  .table_number{
    width: 455px !important;
    font-family: Glacial Indifference !important;
  }
  .optional{
right: 240px !important;
  }
}

.table-input{
  padding: 20px 10px !important;
}


@media only screen and (min-width:530px) and (max-width:576px)
{
  .optional{
    right: 55%;
  }

  .cart-icons{
    left: 7px;
  }
}

@media only screen and (max-width:500px)
{
  .optional{
    right: 68px;
      }

      .cart-icons{
        left: -39px;
      }
}

@media only screen and  (max-width:600px)
{
  .custom_inputs{
    width: 100% !important;
  }

  .cusomt_Input{
     width: 100% !important;
  }

  .custom_dropDown{
    width: 94% !important;
  }

  .cart-buttons{
    width: 100% !important;
  }

  #asap{
    width: 100%  !important;
    
  }
  /* .cart-icons{
    left: -24px;
  } */

  .ct-items-div{
    overflow-x: hidden !important;
    -webkit-overflow-scrolling: touch !important;
  }

  /* .optional{
right: 68px;
  } */

  .product-price{
font-size: 16px !important;
  }

  .od{
    font-size: 16px;
  }

  .cart-single-item-left{
    font-size: 14px;
  }

  .spi{
    font-size: 14px;
  }

  .cart-single-item-right{
    font-size: 14px;
  }
  .cart-checkout{
    font-size: 16px;
  }
  

}

@media only screen and (max-width:380px)
{
   .cart-icons{
    left: -39px;
  }
}

@media only screen and (max-with:350px){
  .optional{
    right: 148px !important;
  }
}


@media only screen and (max-width:300px)
{
  .od{
font-size: 12px !important;
  }

  .cart-single-item-left {
    font-size: 11px !important;
  }
  .spi{
    font-size: 12px;
  }

  .optional{
    right: 7px;
    font-size: 10px;
    top: 1px;
  }

  .cart-checkout{
    font-size: 12px;
  }

  .custom_dropDown{
    left: -1%;
    height: 160px;
  }
}


.text-cart-line{
  white-space: nowrap;
}

.start_time_text{
  font-weight: 800;
  font-family: Glacial Indifference Bold;
  display: block;
}

.border-custom{
  border: 1px solid  #436B64 !important;
  box-shadow: 0px 3px 12px 0px rgba(23, 158, 132, 0.13);
  background-color: white;
}

.done_button{
  display: flex;
height: 47px;
padding: 16px 125px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 14px;
border: 1px solid var(--Primary-color, #2F4E48);
background: var(--Primary-color, #2F4E48);
color: #FFF;

font-family: Glacial Indifference;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.sorry_text{
  color: #212524;
width: 70%;
text-align: center;
font-family: Playfair Display;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 140%;
}

.time-Selector{
  padding: 2px 10px;
  font-family: Glacial Indifference;
}

.time-Selector:hover{
  background-color: #e2e2e2;
}


@media only screen and (max-width:600px){

  .modal_container{
    height: 90vh;
    width: 100vw !important;
  }

  .customModal-mobile{
    height: 92vh !important;
    margin: 0px;
    padding: 10px 0px !important;
    overflow: hidden !important;
  }

  .ct-items-div{
   height: 75vh; 
   margin-top: 25px;
   margin-left: -10px;
   overflow-y: auto !important;
   overflow-x: hidden !important;
   scroll-behavior: smooth;
   -webkit-overflow-scrolling: touch !important;
  }

  .ct-items-div * {
    transform: translate3d(0, 0, 0);
  }
  .empty-ct-items-div{
    height: 80vh;
  }

  .chk-out-btn{
    padding: 18px 22px !important;
    width: 100%;
    position: fixed;
   top: 90%;
   height: 80px;
   left: 0px;
  }

  .cart-upper-heading{
    gap: 202px !important;
    display: flex !important;
    justify-content: space-around !important;
    height: 50px !important;
    width: 100vw !important;
    margin-left: -7px !important;
  }

  .sidebar-menu{
    padding: 0px 1.5rem !important;
    top: 0px !important;
    left: 0% !important;
    overflow: hidden !important;
    background-color: white;
    display: none !important;
  }

  .line_mobile_Cart{
    border-top: 5px solid gray;
    
  }
  .cart-heading{
    font-size: 18px;
  }
  .cart-icons{
    left: -16px !important;
  }

  #mbl-cart-model-close-btn{
    display: none !important;
  }

  .font_close_mobile{
    font-size: 25px;
    color: #000;
    margin-left: 8px;
    margin-top: 5px;
  }

  .header_mbl_Cart{
    align-items: center;
    /* display: none !important; */
    position: fixed;
    background-color: rgb(255, 255, 255);
    top: 0%;
    width: 100%;
    padding-bottom: 10px;
    height: 50px;
    z-index: 7800;
    box-shadow: 0px 2px 8px 0px rgba(192, 191, 191, 0.25);
  }

  .opt-value{
margin-top: 2px;
  }

  .cart-cancel-btn{
    font-size: 18px;
  }

}

@media only screen and (max-width:360px)
{
  .cart-upper-heading{
    gap: 180px !important;
    height: 50px !important;
    width: 100% !important;
    margin-left: 0px !important;
  }

  .cart-heading{
    font-size: 16px;
  }

  .cart-cancel-btn{
    font-size: 16px;
  }

  .ct-items-div{
    height: 70vh; 
    margin-top: 12px;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch !important;
   }
}

@media only screen and (max-width:380px){
  .optional{
    right: 148px !important;
  }
}