.widget-title {
    color: whitesmoke !important;
    text-transform: none !important;
    font-weight: 650 !important;
    font-size: 20px !important;
    padding-bottom: 20px;
    font-family: "Playfair Display";
    /* text-align: center !important;
    margin-left: 40% !important; */
}
.web-footer {
    /* position: fixed; */
    width: 100%;
    bottom: 0px;
}

.footer .social-icon:not(:hover):not(:active):not(:focus) {
    background-color: transparent;
    border-color: #dbdcdd;
    color: rgb(255, 255, 255);
}

.header-logo-styling {
    max-width: 8em;
    /* border-radius: 15px; */
    margin: auto;
    text-align: center;
    margin-left: 53px;
}

.footer .widget-newsletter p {
    margin-bottom: 1.3rem;
    line-height: 13.846;
}

.qouteline {
    padding-top: 29px;
    padding-left: 50px;
    text-align: unset !important;
}

.contact-info ul li {
    padding-bottom: 20px;
    font-size: 16px;
}

.container {
    width: 100%;
}

.social-icons {
    text-align: center;
}

.linkin-social {
    background-color: transparent;
    border-color: #313438;
    color: #000;
    font-family: "porto";
    font-style: normal;
    font-weight: normal;
    /* speak: none; */
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    margin-left: 1.5rem;
    border: 1px solid;
    width: 36px;
    border-radius: 24px;
    height: 36px;
    margin-left: -176px;
    padding: 9px;
}

.social-linkedin-in {
    margin-left: -180px;

}

.linkin-icon {
    margin-top: 20px;
    color: black;
}

.linkin-social a :active {
    color: white !important
}

.footer .linkin-social+.linkin-social {
    margin-left: 1.3rem;
}

.footer-content {
    padding: 0rem 0 0rem;
}

.text-h-footer{
    color: whitesmoke;
}


.footer{
    background-color: #364d49;
    /* position: relative; */
    z-index: 2;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

.contact-info i{
    background-color: #364D49;
    display: flex;
    -ms-flex-align: center;
    /* align-items: center; */
    -ms-flex-pack: center;
    justify-content: center;
    width: 4.3rem;
    height: 4.3rem;
    float: left;
    border-radius: 0;
    color: #fff;
    font-size: 1.6rem;
    position: relative;
    text-align: center;
    top: -10px;
}

.footer-row{
    position: relative;
    top: 45px;
    /* left:55px */
}
.mobile-footer {
    display: none;
}
.contact-row {
font-size: 14px;
color: #fff;
}
.contact-row ul li {
    padding: 5px;
}
.policy-row ul li {
    padding: 5px;
}
.policy-row {
    font-size: 14px;
    color: #fff;
}
.powered-by {
    font-size: 14px;
    color: #fff;
}

@media (max-width: 600px) {
    .web-footer {
        display: none;
    }
    .mobile-footer {
        display: block;
        background-color: #364d49;
        padding: 15px 3px;
    }
}

.hover_link:hover {
color: #fff !important;
}


@media only screen and (max-width:600px)
{
   
.transparent-white-icon {
  background: transparent;
  color: white;
  border: 1px solid white;
  width: 30px ;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-radius: 50%;
  margin: 0px 3px;
}

.footer_address span{
    color: white;
    margin-top: 15px !important ;
    font-family: Glacial Indifference;
    font-weight: 200;
}
.link_footer a{
    color: white;
    text-decoration: none;
font-family: Glacial Indifference;
}
.padding_custom_footer{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 8px;
    padding-right: 8px;
}
}

.web-footer{
    margin-top: auto;
}