.header-close{
    background: #ECEEEE;
    margin-top: 0%;
    position: relative;
    width: 100%;
    padding: 5px 0px;
    height: 50px;
}


.header-close span{
    color: #364D49;
font-family: Glacial Indifference;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.digits-fonts{
    font-family: Glacial Indifference Bold !important;
  }

@media only screen and (min-width:766px) and (max-width:991px)
{
    .header-close span{
        font-size: 20px;
    }
}


@media only screen and (max-width:600px)
{
    .header-close span{
        font-size: 14px;
        padding: 10px;
    }
}