.header-order{
    margin-top: 6%;
    background: #ECEEEE;
    padding: 11px 0px;
    gap: 30px;
}

.header-order span{
    color: #364D49;

font-family: Playfair Display;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.header-order button{
    display: flex;
padding: 12px 25px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 10px;
background: #364D49;
color: #FFF;
border: none;

font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;

}

.container-outer{
    width: 670px !important;
    height: 900px;
    margin-top: 20px;

}

.back-menu-btn{
    color: var(--grey-grey-400, #4D5150);
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;

}

.order-box{
    width: 638px;
max-height: auto;
border-radius: 10px;
border: 1px solid #B9B8B8;
background: #FFF;
box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
display: flex;
flex-direction: column;
padding-bottom: 10px;
}

.order-heading
{
    color: var(--Primary-color, #364D49);
font-family: Playfair Display;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding-top: 20px;
padding-bottom: 10px;
}

.section_detail_order{
    padding-top: 20px;
    padding-bottom:10px;
    padding-left: 51px;
    padding-right: 51px;
}

.extra_detail_card{
    padding: 5px 51px;
    gap: 53px;
    width: 60%;
}

.product_name{
    color: var(--Primary-color, #364D49);
    font-family: Playfair Display;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.product_price{
    color: var(--Primary-color, #364D49);
    font-family: Glacial Indifference;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.product_extra_title{
    color: var(--grey-grey-200, #989B9A);
    font-family: Playfair Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}

.product_extra-price{
    color: var(--grey-grey-200, #989B9A);
font-family: Glacial Indifference;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.custom-line-hr{
    margin-top: 20px;
}


.order-detail-heading{
    color: var(--Primary-color, #364D49);
font-family: Playfair Display;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding-top: 15px;
padding-left: 51px;
padding-bottom: 0px;
margin-bottom: 8px;
}

.custom_detail_card{
    padding: 8px 51px;
}

.order_detail_left{
    color: var(--Neutral-color, #212524);
    font-family: Playfair Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.order_detail_right{
    color: var(--Neutral-color, #212524);
    font-family: Glacial Indifference;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.font_subelement{
    color: var(--Neutral-color, #212524);
font-family: Glacial Indifference;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.price-title-heading{
    color: var(--Primary-color, #364D49);
font-family: Playfair Display;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.price-total_right{
    color: var(--Primary-color, #364D49);
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.modal-heading{
    color: var(--Primary-color, #364D49);
font-family: Playfair Display;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.close-btn-order{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #364D49;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    right: 20px;
    top: 3%;
}
.close-btn-order i {
    font-size: 20px;
    font-weight: 600;
    color: white;
}

.qr-code-image{
    width: 150px !important;
}

.qr-code-text{
    color: #000;

font-family: Glacial Indifference;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

@media only screen and (min-width:1800px) and (max-width:2400px)
{
    .header-order{
        margin-top: 5%;
    }
}



@media only screen and (min-width:766px) and (max-width:991px)
{
.header-order{
    margin-top: 12%;
}
}


@media only screen and (max-width:600px)
{
    .order-box{
        width: 91vw;
        max-height: auto;
    }

    .header-order{
        margin-top: 17%;
        padding: 11px 20px;
    }
    .header-order button{
        font-size: 16px;
        padding: 10px 16px;
    }

    .header-order span{
        font-size: 17px;
    }

    .order-heading{
        font-size: 20px;
    }

    .product_name{
       font-size: 14px; 
    }

    .product_price{
        font-size: 16px;
    }

    .product_extra_title{
        font-size: 12px;
        white-space: nowrap;
    }
    .product_extra-price{
        font-size: 12px;
    }

    .order-detail-heading{
        font-size: 16px;
        padding-left: 27px;
    }
    .order_detail_left{
     font-size: 14px;   
    }

    .order_detail_right{
        font-size: 14px;
    }

    .price-title-heading{
        font-size: 16px;
    }

    .price-total_right{
        font-size: 16px;
    }

    .section_detail_order{
        padding-left: 27px;
        padding-right: 27px;
    }

    .extra_detail_card {
        padding: 5px 26px;
        gap: 53px; 
       width: 70%;
    }

    .custom_detail_card{
        padding-left: 27px;
        padding-right: 27px;
    }

    .container-outer{
        height: 730px;
    }

    .qr-image-sec{
        display: none !important;
    }
    .sec-button-modal{
        align-items: center;
        gap: 10px;
    }

    .qr-code-image:nth-child(1){
        margin-bottom: 30px;
    }

    .modal-heading{
        font-size: 20px;
    }
}