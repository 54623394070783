.margin_top_mobile_category{
    margin-top: 12%;
    margin-bottom: 5%;
}

@media only screen and (max-width:300px)
{ 

  .margin_top_mobile_category{
    margin-top: 36%;
    margin-bottom: 5%;
}

}