.mt-tp{
    margin-top: 10%;
    width: 850px;
    margin-bottom: 10%;
 }



 .custom-font{
    color: var(--Neutral-color, #212524);
    font-family: 'Playfair Display', serif;
    font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 140%;
 }

.back-menu-btn{
color: var(--grey-grey-400, #4D5150);
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;

}

.container-track{
    width: 834px;
    height: 790px;
   
    background: #FFF;
   border-radius: 10px;
border: 1px solid var(--grey-grey-100, #BABBBB);
}

.header-container{
    padding: 30px 25px;
}

.request-box{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
background-color: #F5F5F5;
color: #4F8279;
margin-left: 10px;
}

.bell-box{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 4px;
background-color: #F5F5F5;
margin-right: 10px;
position: relative;
}

.notify-bell{
    background-color: #4F8279;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    top: -10%;
    right: 0%;
}

.request-box span {
    color: #4F8279;
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.invoice-box{
    display: flex;
    gap: 8px;
    justify-content: space-around;
    align-items: center;
    border-radius: 8px;
border: 1px solid var(--grey-grey-100, #BABBBB);
background: #FFF;
padding: 6px 15px;
position: relative;
}

.invoice-dropdown{
    position: absolute;
    border-radius: 8px 8px 0px 0px;
padding: 5px;
border-radius: 10px;
width: 130px;
    top: 140%;
    background: #FFF;

box-shadow: 0px 6px 18px 0px rgba(190, 189, 189, 0.25);
}

.font-dropdown{
 color: var(--Neutral-color, #000000);
font-family: Glacial Indifference;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
margin-left: 6px;
}

.text-box{
    padding: 5px 5px;
}

.dropdown-icon{
     color: var(--Neutral-color, #050505);
}

.invoice-box span{
    color: var(--neutral-neutral-400, #5D5E63);
font-family: Glacial Indifference;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 20px;
}

.order_detail{
    color: var(--grey-grey-300, #6A6D6C);
font-family: 'Playfair Display', serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
gap: 5px;
}

.order_detail span{
    color: var(--neutral-neutral-900, #161719);
font-family: Glacial Indifference;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;

}


.order-detail-header{
  padding: 0px 0px;
  margin-top: 20px;
  gap: 20px;
}

.payment-head{
    color:  #364D49;
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 140%; 
}

.payment-desc{
    color: var(--grey-grey-300, #6A6D6C);
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 28px */
}

.loading-bar{
    color: #364D49;
    margin-top: 5px;
    margin-bottom: 30px;
}

.progress-bar{
   background-color: #364D49 !important;
   border-radius: 10px !important;
}


.detail-card-container{
    padding: 10px 25px;
    margin-top: 10px;
}


.card-image{
    border-radius: 15px;
    width: 60px;
    height: 60px;
    object-fit: cover;
}

.card-main{
    gap: 6px;
    margin-left: 10px;
}

.detail-title{
    color: var(--Primary-color, #364D49);
font-family: 'Playfair Display', serif;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 32px;
}

.detail-card{
color: var(--grey-grey-300, #6A6D6C);
font-family: Glacial Indifference;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 200% */
}

.detail-item-price{
    color: var(--grey-grey-300, #6A6D6C);
text-align: right;
font-family: Glacial Indifference;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}

.detail-price{
    color: var(--Primary-color, #364D49);
text-align: right;
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 30px; /* 150% */
}


.hr-line{
    height: 3px !important;
    margin-top: 30px;
    background-color: #D9D9D9;
}

.payment-detail{
    padding: 10px 25px;
  
}

.payment-heading{
    color: var(--Primary-color, #364D49);
font-family: 'Playfair Display', serif;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.right-details{
    color: var(--Neutral-color, #212524);
font-family: 'Playfair Display', serif;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.left-details{
    color: var(--Neutral-color, #212524);
font-family: Glacial Indifference;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.right-bold{
color: var(--Primary-color, #364D49);
font-family: 'Playfair Display', serif;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.left-bold{
color: var(--Primary-color, #364D49);
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.sub-text{
    font-size: 13px;
    color: var(--Neutral-color, #212524);
font-family: 'Playfair Display', serif;
}


.timer-heading{
    color: var(--neutral-neutral-300, #77787C);
text-align: center;
font-family: Glacial Indifference;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 140%; 
width:13%;
}

.timer-active-heading{
    color: #4F8279 !important;
    font-weight: 700 !important;
}

.dot-single{
    width: 22px;
    height: 22px;
    border-radius: 50%;
background: var(--natural-300, #D0D5DD);
color:#D0D5DD ;
}

.dots{
    position: absolute;
    width: 804px;
    top: -200%;
    padding: 0px 35px;
    align-items: center;
}

.line-bar{
    width: 720px;
    margin-left: 35px;
    height: 4px;
    background-color:var(--natural-300, #D0D5DD)  ;
    position: relative;
    transition: width 0.5s ease;
}

.fade-in-out {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fade-in-out-hidden {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none; 
}

.activedot{
    background: var(--natural-300, #4F8279);
color:#4F8279 ;
}

.modal-header {
position: relative;
}

.modal-box{
gap: 15px;
}

.modal-box-p{
color: #000;

font-family: Glacial Indifference;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.modal-box-p1{
color: #000;

font-family: Glacial Indifference;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.main-modal{
    height: 50vh;
}


.h-1{
    height: 5px !important;
    width: 720px;
    margin-left: 35px;
}


.links-row{
    gap: 15px;
    margin-top: -15px;
}

.links{
    color: var(--grey-grey-300, #6A6D6C);
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 28px */
}

.contact-link{
    text-decoration: underline;
}


.close-btn{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #364D49;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -15%;
    right: 0%;
}

.close-btn i {
 font-weight: 800;
}

.left-text-contact{
    color: var(--Neutral-color, #212524);
font-family: Playfair Display;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.right-text-contact{
    color: var(--Neutral-color, #212524);
font-family: Glacial Indifference;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.contact-section-padding{
    padding: 15px 8px;
}
.contact-modal{
    overflow: hidden;
}

.btn-contact{
    background-color: transparent;
    border: none;
}

.order-complete-heading
{
    color: #000;

text-align: center;
font-family: Playfair Display;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 33.6px */
}

.order-complete-btn{
    display: flex;
width: 250px;
height: 41px;
padding: 12px 125px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 10px;
background: #364D49;
color: #FFF;

font-family: Glacial Indifference;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-decoration: none;
white-space: nowrap;
}

.order-complete-btn:hover{
    color: white;
}

.order-card-section{
    height: 250px !important;
    overflow-y: scroll;
}
.order-card-section::-webkit-scrollbar {
    width: 12px; 
}

.order-card-section::-webkit-scrollbar-thumb {
    background-color: #364D49; 
    border-radius: 6px; 
}

.order-card-section::-webkit-scrollbar-track {
    background-color: #ffffff; 
}

@media only screen and (min-width:768px) and (max-width:991px){
    .container-track{
        width: 720px;
    }

    .mt-tp{
           width: 756px;
           margin-top: 20%;
    }

    .dots{
        width: 680px;
    }

    .h-1{
        width: 600px;
    }
}


@media only screen and (min-width:400px) and (max-width:600px) {

    .completed-container{
        margin-bottom: 2px;
    }

    .payment-heading{
        font-size: 16px;
    }

        .container-track{
        width: 390px;
        border: none;
 height: 820px;
    }

    .container{
        margin-left: 0px !important;
    }

    .mt-tp{
           width: 357px;
           margin-top: 30%;
    }

    .custom-font{
        font-size: 14px;
    }

    .request-box span{
        font-size: 12px;
        white-space: nowrap;
    }
    .header-container{
            background: #FFF;
   border-radius: 2px;
border: 1px solid var(--grey-grey-100, #BABBBB);
    }

    .payment-desc{
        font-size: 14px;
    }


    .detail-card-container{
        padding: 10px 5px;
    }

    .w-100{
        width: 115% !important;
    }

    .hr-line{
        height: 1px !important;
    }

    .payment-detail{
         padding: 10px 5px;
    }

    .dots{
width: 390px;
padding: 0px 15px;
    }

    .h-1{
        width: 400px;
        margin-left: -7px;
    }

    .timer-heading{
        font-size: 1px;
        width: 70%;
    }

    .timer-container{
        padding: 0px !important;
        gap: 25px !important;
        width: 390px;
    }

    .timer-main-container{
        margin-left: -4%;
    }

    .header-container{
padding: 15px 14px;
    }

    .bell-box{
        margin-left: 5%;
    }

    .wrap-back{
        margin-left: 5%;
    }

    
    .links{
        font-size: 16px;
    }

     .order-detail-mobile{
        gap: 12px;
    }

  .modal-box-p{
        font-size: 14px;
    }

    .left-details{
font-size: 14px;
    }

    .order-complete-btn {
        display: flex;
        width: 223px;
        height: 35px;
        padding: 12px 59px;
        justify-content: center;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #364D49;
        color: #FFF;
        font-family: Glacial Indifference;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-decoration: none;
        white-space: nowrap;
    }

    .order-complete-heading{
font-size: 20px;
    }
}

.font_timer{
    color: #4F8279;
font-family: Glacial Indifference;
font-size: 24px !important;
font-style: normal;
font-weight: 700;
line-height: normal;
}



@media only screen and (min-width:320px) and (max-width:399px){

    .order-complete-btn {
        display: flex;
        width: 223px;
        height: 35px;
        padding: 12px 59px;
        justify-content: center;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #364D49;
        color: #FFF;
        font-family: Glacial Indifference;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-decoration: none;
        white-space: nowrap;
    }

    .order-complete-heading{
font-size: 20px;
    }

    .completed-container{
        margin-bottom: 2px;
    }

    .modal-box-p{
        font-size: 14px;
    }

    .left-details{
font-size: 14px;
    }

      .payment-heading{
        font-size: 16px;
    }

    .link_id{
        margin-bottom: -10px;
    }

    .order-detail-mobile{
        gap: 12px;
    }

    .links{
        font-size: 16px;
    }

        .container-track{
        width: 360px;
        border: none;
 height: 850px;
    }

    .container{
        margin-left: 0px !important;
    }

    .mt-tp{
           width: 350px;
           margin-top: 30%;
    }

    .custom-font{
        font-size: 14px;
        /* white-space: nowrap; */
    }

    .request-box span{
        font-size: 12px;
        white-space: nowrap;
    }
    .header-container{
            background: #FFF;
   border-radius: 2px;
border: 1px solid var(--grey-grey-100, #BABBBB);
    }

    .payment-desc{
        font-size: 14px;
        white-space: nowrap;
    }


    .detail-card-container{
        padding: 10px 5px;
    }

    .w-100{
        width: 115% !important;
    }

    .hr-line{
        height: 1px !important;
    }

    .payment-detail{
         padding: 10px 5px;

    }

    .dots{
width: 350px;
padding: 0px 15px;
    }

    .h-1{
        width: 365px;
        margin-left: -7px;
    }

    .timer-heading{
        font-size: 11px;
        width: 70%;
    }

    .timer-container{
        padding: 0px !important;
        gap: 25px !important;
        width: 356px;
    }

    .timer-main-container{
        margin-left: -4%;
    }

    .header-container{
padding: 15px 14px;
    }

    .bell-box{
        margin-left: 5%;
    }

    .wrap-back{
        margin-left: 5%;
    }


}